import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { rendTable, rendModal, loading, api, submitForm, getCookie, wulan } from '../modul';

function App() {
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "DocDate",
                by: "DESC"
            };
        }

        main() {
            rendTable({
                url: "target_browse_web",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi:
                        [
                            { type: "edit", event: (e) => rendForm(e) },
                            { type: "delete", event: (e) => this.modalDelete(e) }
                        ],
                    id: "tbodyData"
                }
            });
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }


        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );

            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });

            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "target_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Target</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                        </div>
                    </div>
                    <nav id="paggination"></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                DocDate: wulan(),
                IDLocation: "",
                TargetJob: "0",
                Location: [],
                setGlobal: true,
                globalTarget: 0
            }
        }

        async componentDidMount() {
            let lok = await api("location_browse", { search: "", searchcount: 100, index: 1 });
            let ArrLok = [];
            if (this.state.id !== "") {
                let sql = await api("target_detail", { iddata: this.state.id });
                for (let ll of sql.detail) ArrLok.push({ NameLocation: ll.NameLocation, IDLocation: ll.IDLocation, TargetJob: ll.TargetJob });

                this.setState({
                    DocDate: sql.data.DocDate2,
                    Location: ArrLok
                });
            } else {
                if (lok.status == "true") for (let ll of lok.data) ArrLok.push({ NameLocation: ll.NameLocation, IDLocation: ll.ID, TargetJob: 0 });
                this.setState({ Location: ArrLok });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleCheckbox(e, ssts) {
            this.setState({ isFranchise: e.target.checked === true ? true : false });
        }

        handleChangeTarget(e, i) {
            let data = this.state.Location;
            data[i].TargetJob = e.target.value;
            this.setState({ Location: data });
        }

        handleGlobalTarget(val) {
            let data = this.state.Location;
            for (let dd of data) dd.TargetJob = val;
            this.setState({ Location: data, globalTarget: val });
        }

        render() {
            return (
                <Fragment>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <div class="form-group">
                        <label>Bulan</label>
                        <input type="month" name="DocDate" class="form-control" id="edtDocDate" value={this.state.DocDate} onChange={(e) => this.handleChange(e, "DocDate")} required />
                        <div class="invalid-feedback">Silahkan Pilih Bulan</div>
                    </div>
                    <div className='form-group'>
                        <label>target Global</label>
                        <input type="number" className='form-control' value={this.state.globalTarget} onChange={(e) => this.handleGlobalTarget(e.target.value)} readOnly={this.state.setGlobal == true ? false : true} />
                    </div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={this.state.setGlobal} onChange={(e) => this.setState({ setGlobal: e.target.checked })} />
                        <label className="form-check-label" for="flexSwitchCheckDefault">Global Target</label>
                    </div>
                    <p></p>
                    <div className='table-responsive'>
                        <table className='table table-stripped'>
                            <thead>
                                <tr>
                                    <th width="70%">Cabang</th>
                                    <th width="30%">Target</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.Location.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <input type="hidden" name="IDLocation" value={item.IDLocation} />
                                                    <input type="text" className='form-control' name="LocationName" value={item.NameLocation} readOnly />
                                                </td>
                                                <td>
                                                    <input type="number" className='form-control' name="TargetJob" value={item.TargetJob} onChange={(e) => this.handleChangeTarget(e, i)} onFocus={(e) => e.target.select()} readOnly={this.state.setGlobal} required />
                                                    <div className='invalid-feedback'>Silahkan masukan target</div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </Fragment>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "target_edit" : "target_insert",
        title = id ? "Edit Target" : "Tambah Target";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;