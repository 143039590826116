import React from 'react';
import ReactDOM from 'react-dom';
import { rendTable, rendModal, loading, api, submitForm, getCookie } from '../modul';

function App() {
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameLocation",
                by: "ASC"
            };
        }

        main() {
            rendTable({
                url: "location_browse_web",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi:
                        [{ type: "edit", event: (e) => rendForm(e) }, { type: "delete", event: (e) => this.modalDelete(e) }],
                    id: "tbodyData"
                }
            });
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }


        modalDelete(ini) {
            let dom = (
                <>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "location_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Cabang</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                        </div>
                    </div>
                    <nav id="paggination"></nav>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                code: "",
                name: "",
                address: "",
                telp: "",
                TargetJob: "0",
                isFranchise: false
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("location_detail", { iddata: this.state.id });
                this.setState({
                    code: sql.data[0].CodeLocation,
                    name: sql.data[0].NameLocation,
                    address: sql.data[0].Address,
                    telp: sql.data[0].Telp,
                    TargetJob: sql.data[0].TargetJob,
                    isFranchise: sql.data[0].isFranchise == 0 ? false : true
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleCheckbox(e, ssts) {
            this.setState({ isFranchise: e.target.checked === true ? true : false });
        }

        render() {
            return (
                <>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <div class="form-group">
                        <label>Kode Lokasi</label>
                        <input type="text" name="code" class="form-control" id="edtKode" value={this.state.code} onChange={(e) => this.handleChange(e, "code")} required />
                        <div class="invalid-feedback">Silahkan isi kode lokasi</div>
                    </div>
                    <div class="form-group">
                        <label>Nama Lokasi</label>
                        <input type="text" name="name" class="form-control" id="edtNama" value={this.state.name} onChange={(e) => this.handleChange(e, "name")} required />
                        <div class="invalid-feedback">Silahkan isi kode lokasi</div>
                    </div>
                    <div class="form-group">
                        <label>Nomor Telp</label>
                        <input type="number" name="telp" class="form-control" id="edtTelp" value={this.state.telp} onChange={(e) => this.handleChange(e, "telp")} />
                        <div class="invalid-feedback">Silahkan isi nomor telp</div>
                    </div>
                    <div class="form-group">
                        <label>Alamat</label>
                        <textarea name="address" class="form-control" id="edtAlamat" value={this.state.address} onChange={(e) => this.handleChange(e, "address")} required></textarea>
                        <div class="invalid-feedback">Silahkan isi alamat loaksi</div>
                    </div>
                    <p></p>
                    <div class="form-check form-switch">
                        <input class="form-check-input" name="isFranchise" value="1" type="checkbox" id="chkFrencise" checked={this.state.isFranchise} onChange={(e) => this.handleCheckbox(e)} />
                        <label class="form-check-label" for="chkFrencise">Franchise</label>
                    </div>
                </>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "location_edit" : "location_insert",
        title = id ? "Edit Master Cabang" : "Tambah Master Cabang";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;