import React from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, loading, rendModal, rendTable, getCookie } from "../modul";

function App() {
    document.getElementById("lblHeader").innerHTML = "Aktifitas Cabang";
    document.title = "Aktifitas Cabang";

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "ID",
                by: "DESC",
                datestart: saiki(),
                dateend: saiki(),
                idlocation: localStorage.getItem("location"),
                pilihAll: true,
                dataOperator: []
            };
        }

        async main() {
            let param = {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                idadmin: getCookie("ID"),
                filteridbarberman: "",
                idlocation: this.state.idlocation,
                sort: this.state.sort,
                by: this.state.by
            }
            let sql = await api("transaction_booking_browse_list_web", param);
            let dtBooking = sql;
            let arrBooking = [];
            for (let td of sql.booking) if (td.isStatus == "Belum diproses") arrBooking.push(td);
            dtBooking.data = arrBooking;
            rendTable({
                dataset: dtBooking,
                div: "divBooking",
                table: { cls: "table table-striped" },
                thead: { cls: "thead-dark" },
                tbody: {
                    id: "tbodyDataBooking"
                }
            });
            let dtAntri = sql;
            let arrAntri = [];
            for (let td of sql.antri) if (td.isProcessed == "0") arrAntri.push(td);
            dtAntri.data = arrAntri;
            rendTable({
                dataset: dtAntri,
                div: "divAntri",
                table: { cls: "table table-striped" },
                thead: { cls: "thead-dark" },
                tbody: {
                    id: "tbodyDataAntri"
                }
            });
            let dtPotong = sql;
            let arrPotong = [];
            for (let td of sql.antri) if (td.isProcessed == "1") arrPotong.push(td);
            dtPotong.data = arrPotong;
            dtPotong.field = sql.fieldPotong;
            rendTable({
                dataset: dtPotong,
                div: "divPotong",
                table: { cls: "table table-striped" },
                thead: { cls: "thead-dark" },
                tbody: {
                    id: "tbodyDataPotong"
                }
            });
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        async handleClickBtn() {
            for (let opt of this.state.dataOperator) if (opt.Loc == "opr-cabang") await api("operatorschedule_insert", { id: opt.ID, lokasi: this.state.idlocation });
            this.main();
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4 mb-2">
                                <div className="card shadow rounded">
                                    <div className="card-header">
                                        <h5>Total Booking</h5>
                                    </div>
                                    <div id="divBooking" style={{ minHeight: "50px;" }} className="card-body table-responsive"></div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="card shadow rounded">
                                    <div className="card-header">
                                        <h5>Total Antrian</h5>
                                    </div>
                                    <div id="divAntri" style={{ minHeight: "50px;" }} className="card-body table-responsive"></div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="card shadow rounded">
                                    <div className="card-header">
                                        <h5>Total Potong</h5>
                                    </div>
                                    <div id="divPotong" style={{ minHeight: "50px;" }} className="card-body table-responsive"></div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;