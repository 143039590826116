import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { rendTable, rendModal, loading, api, submitForm, getCookie, wulan } from '../modul';

function App() {
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                DocDate: wulan(),
                IDLocation: '',
                Location: [],
                searchcount: 50,
                index: 1,
                data: [],
                sort: "DocDate",
                by: "DESC"
            };
        }

        async main() {
            let sql = await api("target_barberman_browse_web", { DocDate: this.state.DocDate, IDLocation: this.state.IDLocation });
            if (sql.status == "true") this.setState({ data: sql.data })
        }

        async componentDidMount() {
            let sql = await api("location_browse", { searchcount: 100, index: 1, search: "" });
            if (sql.status == "true") {
                this.setState({ Location: sql.data });
                setTimeout(() => {
                    this.main();
                    loading();
                }, 200);
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12" style={{ textAlign: "right" }}>
                                <div className="input-group gap-2">
                                    <input className='form-control' type="month" value={this.state.DocDate} onChange={(e) => this.handleChange(e, 'DocDate')} />
                                    <select className='form-select' value={this.state.IDLocation} onChange={(e) => this.handleChange(e, 'IDLocation')} >
                                        {
                                            this.state.Location.map((item, index) => {
                                                return (<option key={index} value={item.ID}>{item.NameLocation}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <th>Operator</th>
                                        <th>Target</th>
                                        <th>Job</th>
                                        <th>Progres</th>
                                        <th>%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.data.map((item, index) => {
                                            return(
                                                <tr>
                                                    <td>{item.NameOperator}</td>
                                                    <td>{item.TargetJob}</td>
                                                    <td>{item.Job}</td>
                                                    <td>{item.Progress}</td>
                                                    <td>{item.Percentage}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <nav id="paggination"></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                code: "",
                name: "",
                address: "",
                telp: "",
                TargetJob: "0",
                isFranchise: false
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("location_detail", { iddata: this.state.id });
                this.setState({
                    code: sql.data[0].CodeLocation,
                    name: sql.data[0].NameLocation,
                    address: sql.data[0].Address,
                    telp: sql.data[0].Telp,
                    TargetJob: sql.data[0].TargetJob,
                    isFranchise: sql.data[0].isFranchise == 0 ? false : true
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleCheckbox(e, ssts) {
            this.setState({ isFranchise: e.target.checked === true ? true : false });
        }

        render() {
            return (
                <>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <div class="form-group">
                        <label>Kode Lokasi</label>
                        <input type="text" name="code" class="form-control" id="edtKode" value={this.state.code} onChange={(e) => this.handleChange(e, "code")} required />
                        <div class="invalid-feedback">Silahkan isi kode lokasi</div>
                    </div>
                    <div class="form-group">
                        <label>Nama Lokasi</label>
                        <input type="text" name="name" class="form-control" id="edtNama" value={this.state.name} onChange={(e) => this.handleChange(e, "name")} required />
                        <div class="invalid-feedback">Silahkan isi kode lokasi</div>
                    </div>
                    <div class="form-group">
                        <label>Nomor Telp</label>
                        <input type="number" name="telp" class="form-control" id="edtTelp" value={this.state.telp} onChange={(e) => this.handleChange(e, "telp")} />
                        <div class="invalid-feedback">Silahkan isi nomor telp</div>
                    </div>
                    <div class="form-group">
                        <label>Target Job</label>
                        <input type="number" name="TargetJob" class="form-control" id="edtTargetJob" value={this.state.TargetJob} onChange={(e) => this.handleChange(e, "TargetJob")} />
                        <div class="invalid-feedback">Silahkan isi Target Job</div>
                    </div>
                    <div class="form-group">
                        <label>Alamat</label>
                        <textarea name="address" class="form-control" id="edtAlamat" value={this.state.address} onChange={(e) => this.handleChange(e, "address")} required></textarea>
                        <div class="invalid-feedback">Silahkan isi alamat loaksi</div>
                    </div>
                    <p></p>
                    <div class="form-check form-switch">
                        <input class="form-check-input" name="isFranchise" value="1" type="checkbox" id="chkFrencise" checked={this.state.isFranchise} onChange={(e) => this.handleCheckbox(e)} />
                        <label class="form-check-label" for="chkFrencise">Franchise</label>
                    </div>
                </>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "location_edit" : "location_insert",
        title = id ? "Edit Master Cabang" : "Tambah Master Cabang";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;