import React, { Fragment } from 'react';
import DataGrid, { Column, Pager, Paging, FilterRow, HeaderFilter, ColumnFixing, Grouping, GroupPanel, Summary, TotalItem, GroupItem, SortByGroupSummaryInfo, Form, Popup, Item, Editing } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { rendModal, api, submitForm, loading, getCookie, saiki, tanggalIndo, exportData } from "../modul";
import ReactDOM from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "Master Pelanggan";
    document.title = "Master Pelanggan";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameCustomer",
                by: "ASC",
                Data: [],
                Field: [
                    { cap: "Nama", sort: "NameCustomer" },
                    { cap: "Alamat", sort: "Address" },
                    { cap: "Email", sort: "Email", type: "str" },
                    { cap: "Telepon", sort: "Telp", type: "str" },
                    { cap: "Tanggal Lahir", sort: "DateBirth" },
                    { cap: "Total Poin", sort: "PointsCustomer" },
                    { cap: "Tanggal Join", sort: "TimeCreated" },
                    { cap: "Update", sort: "TimeUpdated" },
                    { cap: "Oleh", sort: "NameAdmin" }
                ],
                ready: false
            };
        }

        componentDidMount() {
            loading();
            this.main();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={ini.ID} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "customer_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async main() {
            let sql = await api("customer_browse_web", { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by });
            if (sql.status == "true") {
                this.setState({ Data: sql.data, Field: sql.field.thead, ready: true });
            }
        }

        async handleDownload(e) {
            e.target.innerHTML = `<span class="material-icons">hourglass_bottom</span>`;
            e.target.disabled = true;
            exportData({ data: this.state.Data, field: { thead: this.state.Field } }, `Data Pelanggan`);
            e.target.innerHTML = `<span class="material-icons">file_download</span>`;
            e.target.disabled = false;
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Pelanggan</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={() => this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })} className="form-control form-control-sc form-control-sm" placeholder="Search" />
                                    <button className="btn btn-sm btn-default" id="btnExport" onClick={(e) => this.handleDownload(e)}><span className="material-icons">file_download</span></button>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                            {
                                this.state.ready == true ?
                                    <DataGrid dataSource={this.state.Data} showBorders={true} allowColumnResizing={true} columnAutoWidth={true} rowAlternationEnabled={true} style={{ width: "100%", minWidth: "1000px" }}>
                                        <Column
                                            caption="Actions"
                                            cellRender={(rowData) => (
                                                <div>
                                                    <i className="material-icons icon-color" onClick={() => rendForm(rowData.data)}>edit</i>
                                                    <i className="material-icons icon-color" onClick={() => this.modalDelete(rowData.data)}>delete</i>
                                                </div>
                                            )}
                                        />
                                        {
                                            this.state.Field.map((tr, ii) => {
                                                if (tr.type == "number") {
                                                    return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                                                } else if (tr.type == "date") {
                                                    return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy" key={ii} />
                                                } else if (tr.type == "datetime") {
                                                    return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy H:i:s" key={ii} />
                                                } else {
                                                    return <Column dataField={tr.sort} caption={tr.cap} />
                                                }
                                            })
                                        }
                                        <Paging defaultPageSize={50} />
                                        <FilterRow visible={true} />
                                        <HeaderFilter visible={true} />
                                        <GroupPanel visible={true} />
                                        <Grouping autoExpandAll={true} />
                                        <ColumnFixing enabled={true} />
                                        <Summary>
                                            <TotalItem column="NameCustomer" summaryType="count" />
                                            <TotalItem column="PointsCustomer" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} />
                                            <GroupItem column="PointsCustomer" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Total Point : {0}" showInGroupFooter={false} alignByColumn={true} />
                                        </Summary>
                                        <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                                    </DataGrid>
                                    : <h5>Memuat data . . . .</h5>
                            }

                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.ID || "",
                name: "",
                desc: "",
                telp: "",
                address: "",
                email: "",
                DateBirth: saiki()
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("customer_detail", { iddata: this.state.id });
                this.setState({
                    name: sql.data[0].NameCustomer,
                    desc: sql.data[0].Description,
                    telp: sql.data[0].Telp,
                    address: sql.data[0].Address,
                    email: sql.data[0].Email,
                    DateBirth: sql.data[0].DateBirth2
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        setType(e, on = "focus") {
            if (on == "focus") {
                e.target.value = this.state.DateBirth;
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state.DateBirth);
            }
        }

        render() {
            return (
                <Fragment>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <input type="hidden" name="DateBirth" value={this.state.DateBirth} />
                    <div className="row">
                        <div className="col-md-3 mb-2">
                            <div className="form-group">
                                <label>Nama</label>
                                <input type="text" name="name" className="form-control" id="edtName" value={this.state.name} onChange={(e) => this.handleChange(e, "name")} required />
                                <div className="invalid-feedback">Silahkan isi nama</div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-2">
                            <div className="form-group">
                                <label>Telp</label>
                                <input type="number" name="telp" className="form-control" id="edtTelp" value={this.state.telp} onChange={(e) => this.handleChange(e, "telp")} required />
                                <div className="invalid-feedback">Silahkan isi telp</div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-2">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" name="email" className="form-control" id="edtEmail" value={this.state.email} onChange={(e) => this.handleChange(e, "email")} />
                                <div className="invalid-feedback">Silahkan isi email</div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-2">
                            <div className="form-group">
                                <label>Tanggal Lahir</label>
                                <input type="date" name="DateBirth2" className="form-control form-date" id="edtDateBirth" value={this.state.DateBirth} onChange={(e) => this.handleChange(e, "DateBirth")} onMouseOver={(e) => this.setType(e)} onBlur={(e) => this.setType(e, "blur")} />
                                <div className="invalid-feedback">Silahkan isi tanggal lahir</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Alamat</label>
                                <textarea name="address" className="form-control" id="edtAlamat" onChange={(e) => this.handleChange(e, "address")} value={this.state.address}></textarea>
                                <div className="invalid-feedback">Silahkan isi alamat admin</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Deskripsi</label>
                                <textarea name="desc" className="form-control" id="edtDesc" value={this.state.desc} onChange={(e) => this.handleChange(e, "desc")}></textarea>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    let id = ini.ID,
        act = id !== undefined ? "customer_edit" : "customer_insert",
        title = id ? "Edit Pelanggan" : "Tambah Pelanggan";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form",
        cls: "modal-lg"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;