import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { rendModal, loading, api, exportData, tanggalIndo, saiki, pesan } from '../modul';

function App() {
    document.getElementById("lblHeader").innerHTML = "Laporan Bundle";
    document.title = "laporan Bundle";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                NameCustomer: '',
                searchcount: 50,
                index: 1,
                sort: "DocDate",
                by: "DESC",
                Aktif: 0,
                Tidak: 0,
                H90: 0,
                H60: 0,
                H30: 0,
                Status: "aktif",
                DataSet: [],
                Field: [],
                EndDate: saiki()
            };
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            setTimeout(() => {
                this.main();
            }, 500);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 500);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 500);
        }

        handleChangeStatus(Status) {
            this.setState({ Status: Status });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        async main() {
            let sql = await api("laporan_bundle", { NameCustomer: this.state.NameCustomer, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by, Status: this.state.Status });
            if (sql.status == "true") {
                this.setState({
                    Aktif: sql.aktif,
                    H90: sql.H90,
                    H60: sql.H60,
                    H30: sql.H30,
                    Tidak: sql.tidak,
                    DataSet: sql.data,
                    Field: sql.field
                });
            }
        }

        async handleDetail(id) {
            let sql = await api("bundle_detail", { iddata: id });
            const dom = (
                <Fragment>
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Tanggal</th>
                                <th>Nomor Dokumen</th>
                                <th>Nama Produk</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sql.data.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{tanggalIndo(item.DocDate)}</td>
                                            <td>{item.DocNumber}</td>
                                            <td>{item.NameProduct}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Fragment>
            );

            rendModal({
                id: "modalDetail",
                body: dom,
                title: "Detail Transaksi",
                cls: "modal-lg"
            });
        }

        async handleDownload(e) {
            e.target.innerHTML = `<i class="material-icons">hourglass_bottom</i>`;
            e.target.disabled = true;
            let ct = this.state.DataSet.length;
            let tempData = { total: [" ", ct], data: this.state.DataSet, field: this.state.Field };
            exportData(tempData, `Data Bundle ${this.state.Status}`);
            setTimeout(() => {
                e.target.innerHTML = `<i class="material-icons">file_download</i>`;
                e.target.disabled = false;
            }, 2000);
        }

        handleWa(Telp) {
            rendModal({
                title: "Kirim Pesan",
                id: "KirimPesan",
                footer: "<button class='btn btn-default' id='btnKirim'>Kirim</button>",
                body: <Fragment>
                    <textarea className='form-control' placeholder='Pesan' id='edtPesan'></textarea>
                </Fragment>
            });

            setTimeout(() => {
                document.getElementById('btnKirim').addEventListener("click", () => this.handleKirimWa(Telp));
            }, 1000);
        }

        handleKirimWa(Telp) {
            let pesan = document.getElementById('edtPesan').value;
            window.open(`https://wa.me/${Telp.trim()}?text=` + encodeURI(pesan));
        }

        handlePerpanjang(ID, EndDate) {
            rendModal({
                title: "Perpanjang Bundle",
                id: "PerpanjangBundle",
                footer: "btnSave",
                form: true,
                body: <Fragment>
                    <h6>Tanggal Terahir : {tanggalIndo(EndDate)}</h6>
                    <input type="hidden" name="ID" value={ID} />
                    <div className='form-group'>
                        <label>Tanggal Perpanjangan</label>
                        <input type='date' className='form-control' id="edtEndDate" required />
                    </div>
                </Fragment>
            });

            setTimeout(() => {
                document.getElementById('edtEndDate').value = saiki();
                document.getElementById('iniForm').addEventListener("submit", async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    let EndDate = document.getElementById('edtEndDate').value;
                    let sql = await api("perpanjang_bundle", { ID: ID, EndDate: EndDate });
                    if (sql.status == "true") {
                        pesan("Pemberitahuan", sql.message, "primary");
                        this.main();
                        document.getElementById('btnCloseModalPerpanjangBundle').click();
                    } else {
                        pesan("Pemberitahuan", "Terjadi Kesalahan Ketika Memproses", "warning");
                    }
                });
            }, 500);
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className='d-flex justify-content-start align-items-center gap-2'>
                            <div className='card card-sum' style={{ cursor: "pointer" }} onClick={() => this.handleChangeStatus("aktif")}>
                                <span style={{ fontSize: "14px;" }}>Bundle Aktif</span>
                                <span style={{ fontSize: "24px", fontWeight: "bold" }}>{this.state.Aktif}</span>
                            </div>
                            <div className='card card-sum' style={{ cursor: "pointer" }} onClick={() => this.handleChangeStatus("90")}>
                                <span style={{ fontSize: "14px;" }}>H -90</span>
                                <span style={{ fontSize: "24px", fontWeight: "bold" }}>{this.state.H90}</span>
                            </div>
                            <div className='card card-sum' style={{ cursor: "pointer" }} onClick={() => this.handleChangeStatus("60")}>
                                <span style={{ fontSize: "14px;" }}>H -60</span>
                                <span style={{ fontSize: "24px", fontWeight: "bold" }}>{this.state.H60}</span>
                            </div>
                            <div className='card card-sum' style={{ cursor: "pointer" }} onClick={() => this.handleChangeStatus("30")}>
                                <span style={{ fontSize: "14px;" }}>H -30</span>
                                <span style={{ fontSize: "24px", fontWeight: "bold" }}>{this.state.H30}</span>
                            </div>
                            <div className='card card-sum' style={{ cursor: "pointer" }} onClick={() => this.handleChangeStatus("tidak")}>
                                <span style={{ fontSize: "14px;" }}>Bundle Tidak Aktif</span>
                                <span style={{ fontSize: "24px", fontWeight: "bold" }}>{this.state.Tidak}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className='btn btn-default' onClick={(e) => this.handleDownload(e)}>
                                        <span className='material-icons'>file_download</span>
                                    </button>
                                    <button className="btn btn-sc" onClick={() => this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "NameCustomer")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Nama</th>
                                        <th>Nama Pelanggan</th>
                                        <th>Telp</th>
                                        <th>Tgl. Mulai</th>
                                        <th>Tgl. Akhir</th>
                                        <th>Masa Aktif</th>
                                        <th>Jumlah Bundle</th>
                                        <th>Tgl Buat</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.DataSet.map((tr, i) => {
                                            let telp = tr.Telp.charAt(0) == "0" ? "62" + tr.Telp.slice(1) : tr.Telp;
                                            if (this.state.Status == "tidak") {
                                                return (<tr>
                                                    <td>
                                                        <i className='material-icons icon-color' onClick={() => this.handleDetail(tr.ID)}>visibility</i>
                                                        <i className='material-icons icon-color' onClick={() => this.handlePerpanjang(tr.ID, tr.EndDate)}>history</i>
                                                    </td>
                                                    <td>{tr.NamePromo}</td>
                                                    <td>{tr.NameCustomer}</td>
                                                    <td><span className='btn-link' style={{ cursor: "pointer" }} onClick={() => this.handleWa(telp)}>{telp}</span></td>
                                                    <td>{tanggalIndo(tr.StartDate)}</td>
                                                    <td>{tanggalIndo(tr.EndDate)}</td>
                                                    <td>{tr.MasaAktif}</td>
                                                    <td>{tr.Sisa}</td>
                                                    <td>{tanggalIndo(tr.TimeCreated)}</td>
                                                </tr>)
                                            } else {
                                                return (<tr>
                                                    <td>
                                                        <i className='material-icons icon-color' onClick={() => this.handleDetail(tr.ID)}>visibility</i>
                                                    </td>
                                                    <td>{tr.NamePromo}</td>
                                                    <td>{tr.NameCustomer}</td>
                                                    <td><span className='btn-link' style={{ cursor: "pointer" }} onClick={() => this.handleWa(telp)}>{telp}</span></td>
                                                    <td>{tanggalIndo(tr.StartDate)}</td>
                                                    <td>{tanggalIndo(tr.EndDate)}</td>
                                                    <td>{tr.MasaAktif}</td>
                                                    <td>{tr.Sisa}</td>
                                                    <td>{tanggalIndo(tr.TimeCreated)}</td>
                                                </tr>)
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <nav id="paggination"></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;