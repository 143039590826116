import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { tanggalIndo, rendModal, rendTable, submitForm, saiki, api, YYMMDD, getCookie, loading } from "../modul";

function App() {
    document.getElementById("lblHeader").innerHTML = "Booking Service";
    document.title = "Booking Service";

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "DocDate",
                by: "DESC",
                idlocation: localStorage.getItem("location"),
                totalBooking: 0,
                totalAntrian: 0,
                totalPotong: 0
            };
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                const sort = this.state.by == "ASC" ? "DESC" : "ASC";
                this.setState({ by: sort });
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 200);
        }

        async main() {
            let sql = await api("transaction_booking_browse_web", {
                search: this.state.search,
                searchcount: this.state.searchcount,
                index: this.state.index,
                sort: this.state.sort,
                by: this.state.by,
                idadmin: getCookie("ID"),
                d1: this.state.datestart,
                d2: this.state.dateend,
                idlocation: this.state.idlocation,
            });

            document.getElementById('divBooking').innerHTML = sql.sum.booking;
            document.getElementById('divAntri').innerHTML = sql.sum.antri;
            document.getElementById('divPotong').innerHTML = sql.sum.potong;

            rendTable({
                dataset: sql,
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi: [{ type: "delete", event: (e) => this.modalDelete(e) }],
                    id: "tbodyData"
                }
            });
        }

        async modalDelete(e) {
            const dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={e.target.dataset.id} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "transaction_booking_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3"><button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Transaksi</button>
                            </div>
                            <div className="col-md-6">
                            </div>
                            <div className="col-md-3" style={{ textAlign: "right;" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="row">
                            <div className="col-md-4 mb-2">
                                <div className="card shadow rounded">
                                    <div className="card-header">
                                        <h5>Total Booking</h5>
                                    </div>
                                    <div style={{ minHeight: "50px;" }} className="card-body"><h3 id="divBooking">{this.state.totalBooking}</h3></div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="card shadow rounded">
                                    <div className="card-header">
                                        <h5>Total Antrian</h5>
                                    </div>
                                    <div style={{ minHeight: "50px;" }} className="card-body"><h3 id="divAntri">{this.state.totalAntrian}</h3></div>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="card shadow rounded">
                                    <div className="card-header">
                                        <h5>Total Potong</h5>
                                    </div>
                                    <div style={{ minHeight: "50px;" }} className="card-body"><h3 id="divPotong">{this.state.totalPotong}</h3></div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination"></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            let today = new Date();
            let time = today.getHours() + ":" + today.getMinutes();
            this.state = {
                id: ini.target.dataset.id || "",
                __ID: getCookie("ID"),
                idcustomer: "",
                name: "",
                telp: "",
                email: "",
                modeinput: "add",
                datebooking: saiki(),
                address: "",
                iddocnumber: "",
                timebooking: time,
                idlocationorder: localStorage.getItem("location"),
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                const sql = await api("transaction_booking_detail", { docnumber: this.state.id });
                this.setState({
                    idcustomer: sql.dataHeader.IDCustomer,
                    name: sql.dataHeader.NameCustomer,
                    telp: sql.dataHeader.Telp,
                    email: sql.dataHeader.Email,
                    modeinput: "edit",
                    iddocnumber: id,
                    datebooking: YYMMDD(sql.dataHeader.DateBooking),
                    address: sql.dataHeader.Address,
                    timebooking: sql.dataHeader.TimeBooking1,
                    idlocationorder: sql.dataHeader.IDLocation
                });
            }
            const sql = await api("kasir_listmenu_web", { iddata: this.state.id });
            let arrLok = [];
            let arrTempMenu = [];
            for (let MM of sql.data) if (MM.GroupID == 0 && MM.IsGroup == 0 && MM.IsMobile == 1) arrLok.push({ ID: MM.FormCode, Name: MM.FormName, isLock: MM.isLock });
            this.setState({ menukasir: arrLok });
            for (let MM of this.state.menukasir) if (MM.isLock == 1) arrTempMenu.push({ FormCode: MM.ID, isLock: MM.isLock });
            this.setState({ tempMenu: arrTempMenu });
            document.getElementById('edtTanggal').value = tanggalIndo(this.state.datebooking);
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleModal() {
            let dom = (<>
                <div className="form-group">
                    <label>Cari Data</label>
                    <input type="search" className="form-control" id="edtModalCari" placeholder="Cari Nama Data" onFocus={(e) => this.cariData(e)} onChange={(e) => this.cariData(e)} autocomplete="off" />
                </div>
                <table className="table table-striped"><tbody id="ulCari"></tbody></table>
            </>);
            rendModal({
                title: "Cari Pelanggan",
                body: dom,
                id: "modalCariPelanggan",
                cls: "modal-lg"
            });
        }

        async cariData(e) {
            let sql = await api("customer_browse", { search: e.target.value, index: 1, searchcount: 10 });
            let tr = sql.data.map((data) => (<tr onClick={() => this.handlePilih(data)}><td>{data.NameCustomer}</td><td>{data.Email}</td><td>{data.Telp}</td><td>{data.Address}</td></tr>));
            ReactDOM.render(tr, document.getElementById('ulCari'));
        }

        handlePilih = (data) => {
            this.setState({ idcustomer: data.ID, name: data.NameCustomer, telp: data.Telp, email: data.Email, address: data.Address });
            document.getElementById('btnCloseModalmodalCariPelanggan').click();
        }

        setType(e, on = "focus") {
            if (on == "focus") {
                e.target.value = this.state.datebooking;
                e.target.type = 'date';
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state.datebooking);
            }
        }

        render() {
            return (
                <>
                    <input type="hidden" name="idadmin" value={this.state.__ID} />
                    <input type="hidden" name="modetransction" value="BOOKING" />
                    <input type="hidden" name="modeinput" value={this.state.modeinput} />
                    <input type="hidden" name="iddocnumber" value={this.state.iddocnumber} />
                    <input type="hidden" name="idlocation" value={this.state.idlocationorder} />
                    <input type="hidden" name="idcustomer" value={this.state.idcustomer} id="edtIDPelanggan" />
                    <div className="form-group">
                        <label>Nama Pelanggan</label>
                        <div class="input-group">
                            <input type="text" name="name" class="form-control" value={this.state.name} id="edtPelanggan" onMouseOver={() => this.handleModal()} required />
                            <button type="button" class="btn btn-outline-primary" onClick={(e) => this.handleModal()}><i class="material-icons">search</i></button>
                        </div>
                        <div class="invalid-feedback">Silahkan isi / pilih pelanggan</div>
                    </div>
                    <div className="form-group">
                        <label>Nomor Telp</label>
                        <input type="number" name="telp" className="form-control" value={this.state.telp} onChange={(e) => this.handleChange(e, "telp")} id="edtTelp" required />
                        <div class="invalid-feedback">Silahkan isi nomor telp</div>
                    </div>
                    <div className="form-group">
                        <label>Alamat Email</label>
                        <input type="email" name="email" className="form-control" value={this.state.email} onChange={(e) => this.handleChange(e, "email")} id="edtEmail" required />
                        <div class="invalid-feedback">Silahkan isi alamat email</div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Tanggal Booking</label>
                                <input type="text" name="datebooking" value={this.state.datebooking} onChange={(e) => this.handleChange(e, "datebooking")} onMouseOver={(e) => this.setType(e)} onBlur={(e) => this.setType(e, "blur")} className="form-control form-date" id="edtTanggal" required />
                                <div class="invalid-feedback">Silahkan pilih tanggal booking</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Jam Booking</label>
                                <input type="time" name="timebooking" value={this.state.timebooking} onChange={(e) => this.handleChange(e, "timebooking")} className="form-control" id="edtTime" required />
                                <div class="invalid-feedback">Silahkan pilih jam booking</div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "transaction_booking_detail" : "transaction_booking_create",
        title = id ? "Edit Booking" : "Tambah Booking";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form",
        cls: "modal-xl"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;