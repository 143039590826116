import React, { Fragment } from 'react';
import { rendTable, rendModal, api, submitForm, getCookie, loading, pesan } from "../modul";
import ReactDOM from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "Master Produk Bundle";
    document.title = "Master Produk Bundle";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameProduct",
                by: "DESC",
            };
        }

        async componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            e.preventDefault();
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "product_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        main() {
            rendTable({
                url: "product_bundle_browse_web",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi:
                        [
                            { type: "edit", event: (e) => rendForm(e) },
                            { type: "other", event: (e) => formLokasi(e), icon: "edit_location_alt" },
                            { type: "delete", event: (e) => this.modalDelete(e) }
                        ],
                    id: "tbodyData"
                }
            });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Produk</button>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                <div className="d-flex justify-content-center align-items-center gap-2">
                                    <div className="input-group input-group-sm">
                                        <button className="btn btn-sm btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                        <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination"></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

function formLokasi(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                dataLokasi: [],
                searchLokasi: []
            }
        }

        async componentDidMount() {
            let sql = await api("product_show_detail", { iddata: this.state.id });
            if (sql.status == "true") this.setState({ dataLokasi: sql.data });
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        async cariLokasi(e) {
            let sql = await api("location_browse", { searchcount: 100, search: e.target.value, index: 1 });
            this.setState({ searchLokasi: sql.data });
        }

        async setLocation(id, type = "product_show_insert") {
            let sql = await api(type, { lokasi: id, produk: this.state.id });
            if (sql.status == "true") {
                let prod = await api("product_show_detail", { iddata: this.state.id });
                if (prod.status == "true") {
                    this.setState({ dataLokasi: prod.data });
                } else {
                    this.setState({ dataLokasi: [] });
                }
            } else {
                pesan("Pemberitahuan", sql.message, "warning");
            }
        }

        render() {
            return (
                <Fragment>
                    <div className="form-group">
                        <label>Cari data lokasi</label>
                        <input type="search" className="form-control dropdown-toggle" placeholder="Cari data lokasi" onFocus={(e) => this.cariLokasi(e)} onChange={(e) => this.cariLokasi(e)} id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                        <ul className="dropdown-menu w-100" id="listLokasi" aria-labelledby="dropdownMenuButton1">
                            {this.state.searchLokasi.map((td) => <li><a className="dropdown-item" href="#" onClick={(e) => this.setLocation(td.ID)}>{td.NameLocation}</a></li>)}
                        </ul>
                    </div>
                    <p></p>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Nama Lokasi</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody id="tbodyLokasi">
                            {
                                this.state.dataLokasi.map((tr, i) => {
                                    return (
                                        <tr>
                                            <td>{tr.NameLocation}</td>
                                            <td><i className="material-icons icon-color" onClick={(e) => this.setLocation(tr.IDLocation, 'product_show_delete')}>delete</i></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Fragment>
            )
        }
    }

    rendModal({
        title: "Lokasi Produk",
        body: <Form />,
        id: "FormLokasi",
        cls: "modal-lg"
    });
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                code: "",
                name: "",
                kategori: "",
                kategoriid: "",
                harga: "",
                desc: "",
                commission: "",
                type: "",
                iskomisi: false,
                AmountBundle: 0,
                IDProductBundle: "",
                NameProductBundle: "",
                DaysExpiredBundle: 350,
                TypeGroup: "product"
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("product_detail", { iddata: this.state.id, admin: getCookie("ID") });
                console.log(sql);
                this.setState({
                    code: sql.data[0].CodeProduct,
                    name: sql.data[0].NameProduct,
                    kategori: sql.data[0].NameCategory,
                    kategoriid: sql.data[0].IDCategory,
                    harga: sql.data[0].Price,
                    desc: sql.data[0].Description,
                    commission: sql.data[0].Commission,
                    type: sql.data[0].ItemType,
                    iskomisi: sql.commision == 0 ? true : false,
                    AmountBundle: sql.data[0].AmountBundle,
                    IDProductBundle: sql.data[0].IDProductBundle,
                    NameProductBundle: sql.data[0].NameProductBundle,
                    DaysExpiredBundle: sql.data[0].DaysExpiredBundle
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        async scCategori(e) {
            this.setState({ kategori: e.target.value });
            const sql = await api("category_browse_web", { search: e.target.value, searchcount: 10, index: 1, sort: "NameCategory", by: "ASC" });
            const dom = sql.data.map((opt) =>
                <li><a className="dropdown-item" href="#" onClick={(e) => this.setCategori(opt.NameCategory, opt.ID)}>{opt.NameCategory}</a></li>
            )
            ReactDOM.render(dom, document.getElementById('ulKetegori'));
        }

        setCategori(nama, id) {
            this.setState({ kategori: nama, kategoriid: id });
        }

        async modalProduk() {
            let dom =
                <Fragment>
                    <input type="search" className='form-control' id="edtCariProduk" placeholder={'Cari Nama Produk'} />
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Kode</th>
                                <th>Nama</th>
                                <th>Kategori</th>
                            </tr>
                        </thead>
                        <tbody id="tbodyProduk"></tbody>
                    </table>
                </Fragment>;

            rendModal({
                title: "Cari data Produk",
                body: dom,
                id: "modalProduk",
                cls: "modal-lg"
            });

            let ini = this;

            let sql = await api("product_browse_web", { search: "", searchcount: 100, index: 1, sort: "NameProduct", by: "ASC" });
            let td = [];
            sql.data.map(tr =>
                td.push(<tr onClick={(e) => ini.pilihProduk(e, tr.ID, tr.NameProduct)}>
                    <td>{tr.CodeProduct}</td>
                    <td>{tr.NameProduct}</td>
                    <td>{tr.NameCategory}</td>
                </tr>)
            );

            ReactDOM.render(td, document.getElementById('tbodyProduk'));

            document.getElementById('edtCariProduk').addEventListener("keyup", async function () {
                let sql = await api("product_browse_web", { search: this.value, searchcount: 100, index: 1, sort: "NameProduct", by: "ASC" });
                let td = [];
                sql.data.map(tr =>
                    td.push(<tr onClick={(e) => ini.pilihProduk(e, tr.ID, tr.NameProduct)}>
                        <td>{tr.CodeProduct}</td>
                        <td>{tr.NameProduct}</td>
                        <td>{tr.NameCategory}</td>
                    </tr>)
                );

                ReactDOM.render(td, document.getElementById('tbodyProduk'));
            });
        }

        pilihProduk(e, id, nama) {
            this.setState({ IDProductBundle: id, NameProductBundle: nama });
            document.getElementById('btnCloseModalmodalProduk').click();
        }

        render() {
            return (
                <Fragment>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Kode Produk</label>
                                <input type="text" name="code" className="form-control" id="edtCode" value={this.state.code} onChange={(e) => this.handleChange(e, "code")} required />
                                <div className="invalid-feedback">Silahkan Isi Code Produk</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Nama Produk</label>
                                <input type="text" name="name" className="form-control" id="edtNama" value={this.state.name} onChange={(e) => this.handleChange(e, "name")} required />
                                <div className="invalid-feedback">Silahkan Isi Nama Produk</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Harga</label>
                                <input type="number" name="price" className="form-control" id="edtHarga" value={this.state.harga} onChange={(e) => this.handleChange(e, "harga")} required />
                                <div className="invalid-feedback">Silahkan isikan harga produk</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Komisi</label>
                                <input type="number" name="commission" className="form-control" id="edtKomisi" value={this.state.commission} onChange={(e) => this.handleChange(e, "commission")} disabled={this.state.iskomisi} required />
                                <div className="invalid-feedback">Silahkan isikan komisi produk</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-2">
                            <div class="form-group">
                                <label>Produk</label>
                                <input type="hidden" name="IDProductBundle" id="edtProductID" value={this.state.IDProductBundle} />
                                <div className='input-group'>
                                    <input type="text" name="namaproduct" class="form-control" id="edtProduct" value={this.state.NameProductBundle} onFocus={(e) => this.modalProduk()} required />
                                    <button type='button' className='btn btn-default' onClick={(e) => this.modalProduk()}>
                                        <span className='material-icons'>search</span>
                                    </button>
                                </div>
                                <div class="invalid-feedback">Silahkan pilih produk</div>
                            </div>
                        </div>
                        <div class="col mb-2">
                            <div class="form-group">
                                <label>Jumlah</label>
                                <input type="number" name="AmountBundle" class="form-control" value={this.state.AmountBundle} onChange={(e) => this.setState({ AmountBundle: e.target.value })} required />
                                <div class="invalid-feedback">Silahkan masukan jumlah</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Hari Kadarluarsa</label>
                        <input type="number" name="DaysExpiredBundle" class="form-control" value={this.state.DaysExpiredBundle} onChange={(e) => this.setState({ DaysExpiredBundle: e.target.value })} required />
                        <div class="invalid-feedback">Silahkan masukan hari kadarluarsa</div>
                    </div>
                    <div className="form-group">
                        <label>Deskripsi</label>
                        <textarea name="desc" className="form-control" id="edtDeskripsi" value={this.state.desc} onChange={(e) => this.handleChange(e, "desc")}></textarea>
                    </div>
                </Fragment>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "product_bundle_edit" : "product_bundle_insert",
        title = id ? "Edit Produk Bundle" : "Tambah Produk Bundle";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form",
        cls: "modal-lg"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;