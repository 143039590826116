import React, { Fragment } from 'react';
import { Line } from 'react-chartjs-2';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import ReactDOM from 'react-dom';
import { rendModal, saiki, loading, TampilBulan, api, randomRgb, pesan } from '../modul';

function App() {
    document.getElementById("lblHeader").innerHTML = "Laporan Omset Barberman";
    document.title = "Laporan Omset Barberman";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                IDOperator: '',
                Barberman: "",
                d1: saiki("month", -12),
                d2: saiki("month"),
                idlocation: localStorage.getItem("location"),
                Data: [],
                DataBarberman: [],
                Search: "",
                chart: {
                    labels: [],
                    datasets: []
                },
                chart2: {
                    labels: [],
                    datasets: []
                },
                chartPotong: {
                    labels: [],
                    datasets: [
                        { "label": "Jumlah Potong", "data": [10, 20], "fill": false, "borderColor": "#00bcd4", "lineTension": 0.1 },
                    ]
                },
                chartOmset: {
                    labels: [],
                    datasets: [
                        { "label": "Omset", "data": [20, 12], "fill": false, "borderColor": "#c2185b", "lineTension": 0.1 }
                    ]
                },
                Omset: [],
                NamaLokasi: "Semua Lokasi"
            };
        }

        componentDidMount() {
            this.main();
            loading();
        }

        async main() {
            let colors = [
                "#8B0000",  // Dark Red
                "#006400",  // Dark Green
                "#00008B",  // Dark Blue
                "#8B008B",  // Dark Magenta
                "#556B2F",  // Dark Olive Green
                "#FF8C00",  // Dark Orange
                "#9932CC",  // Dark Orchid
                "#8B4513",  // Dark Saddle Brown
                "#2F4F4F",  // Dark Slate Gray
                "#00CED1",  // Dark Turquoise
                "#9400D3",  // Dark Violet
                "#B8860B",  // Dark Goldenrod
                "#A52A2A",  // Brown
                "#5F9EA0",  // Cadet Blue
                "#8A2BE2",  // Blue Violet
                "#A9A9A9",  // Dark Gray
                "#483D8B",  // Dark Slate Blue
                "#2E8B57",  // Sea Green
                "#4B0082",  // Indigo
                "#696969",  // Dim Gray
                "#6B8E23",  // Olive Drab
                "#D2691E",  // Chocolate
                "#8B0000",  // Dark Red (repeated for variety)
                "#008B8B",  // Dark Cyan
                "#B22222",  // Firebrick
                "#228B22",  // Forest Green
                "#8B4513",  // Saddle Brown
                "#2F4F4F",  // Dark Slate Gray (repeated for variety)
                "#191970",  // Midnight Blue
                "#BDB76B"   // Dark Khaki
            ];

            if (this.state.IDCustomer != '') {
                let sql = await api("laporan_omset_barberman", { d1: this.state.d1, d2: this.state.d2, IDOperator: this.state.IDOperator, IDLocation: this.state.idlocation });
                if (sql.status == "true") {
                    let chartOmset = this.state.chartOmset, chartPotong = this.state.chartPotong;
                    let label = [], dataOmset = [], dataPotong = [];
                    for (let dd of sql.data) {
                        dataOmset.push(dd.Omset);
                        dataPotong.push(dd.JumlahPotong);
                        if (!label.includes(TampilBulan(dd.Tanggal))) label.push(TampilBulan(dd.Tanggal));
                    }
                    chartOmset.labels = label;
                    chartOmset.datasets = [
                        { "label": "Omset", "data": dataOmset, "fill": false, "borderColor": "#c2185b", "lineTension": 0.1 }
                    ]
                    chartPotong.labels = label;
                    chartPotong.datasets = [
                        { "label": "Jumlah Potong", "data": dataPotong, "fill": false, "borderColor": "#00bcd4", "lineTension": 0.1 }
                    ]
                    let dataset = [], dataset2 = [], i = 0;
                    for (let dd of sql.barberman) {
                        let data = [], data2 = [];
                        for (let ddd of sql.data) {
                            if (ddd.IDOperator == dd.ID) {
                                data.push(ddd.Omset);
                                data2.push(ddd.JumlahPotong);
                            }
                        }
                        let color = colors[i];
                        i++;
                        if (data.length > 0) dataset.push({ "label": dd.NameOperator, "data": data, "fill": false, "borderColor": color, backgroundColor: color, "lineTension": 0.1 })
                        if (data2.length > 0) dataset2.push({ "label": dd.NameOperator, "data": data2, "fill": false, "borderColor": color, backgroundColor: color, "lineTension": 0.1 })
                    }
                    let chart = this.state.chart;
                    chart.labels = label;
                    chart.datasets = dataset;
                    let chart2 = this.state.chart2;
                    chart2.labels = label;
                    chart2.datasets = dataset2;
                    let NamaLokasi = "Semua Lokasi";
                    if (sql.lokasi.length > 0) NamaLokasi = sql.lokasi[0].NameLocation;
                    this.setState({ Data: sql.barberman, Omset: sql.data, chartOmset: chartOmset, chartPotong: chartPotong, chart: chart, chart2: chart2, NamaLokasi: NamaLokasi });
                }
            } else {
                pesan("Perhatian", "Silahkan pilih pelanggan terlebih dahulu", "warning");
            }
        }

        async handleModal() {
            let sql = await api("browse_barberman", { q: this.state.Search });
            if (sql.status == "true") this.setState({ DataBarberman: sql.data });
            let dom = (
                <Fragment>
                    <input type="search" className='form-control' placeholder='Cari Pelanggan' onChange={(e) => this.handleCariBarberman(e)} />
                    <p></p>
                    <div className='table-responsive'>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>Nama</th>
                                    <th>Telp</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody id="tbodyBarberman">
                                {
                                    this.state.DataBarberman.map((tr, ii) => {
                                        return (
                                            <tr onClick={(e) => this.handlePilihBarberman(tr)} key={ii}>
                                                <td>{tr.NameOperator}</td>
                                                <td>{tr.Telp}</td>
                                                <td>{tr.Email}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </Fragment>
            );

            rendModal({
                title: "Cari Barberman",
                body: dom,
                form: true,
                id: "modalBarberman"
            });
        }

        async handleCariBarberman(e) {
            let sql = await api("browse_barberman", { q: e.target.value });
            let tr = sql.data.map((tr, ii) => {
                return (
                    <tr onClick={(e) => this.handlePilihBarberman(tr)} key={ii}>
                        <td>{tr.NameOperator}</td>
                        <td>{tr.Telp}</td>
                        <td>{tr.Email}</td>
                    </tr>
                )
            });
            ReactDOM.render(tr, document.getElementById('tbodyBarberman'));
        }

        handlePilihBarberman(data) {
            this.setState({ IDOperator: data.ID, Barberman: data.NameOperator });
            setTimeout(() => {
                this.main();
            }, 500);
            document.getElementById('btnCloseModalmodalBarberman').click();
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="d-flex justify-content-start align-items-start gap-2">
                            <h5>Periode</h5>
                            <div className="d-flex justify-content-center align-items-start gap-2">
                                <input type="month" style={{ maxWidth: "120px" }} className="form-control form-control-sm" value={this.state.d1} onChange={(e) => this.setState({ d1: e.target.value })} />
                                <input type="month" style={{ maxWidth: "120px" }} className="form-control form-control-sm" value={this.state.d2} onChange={(e) => this.setState({ d2: e.target.value })} />
                                <input type="search" name="edtPelanggan" style={{ maxWidth: "150px" }} className="form-control form-control-sm" readOnly={true} value={this.state.Barberman} onFocus={() => this.handleModal()} />
                                <button className="btn btn-sm btn-default" onClick={(e) => this.main()}><i className="material-icons">search</i></button>
                            </div>
                        </div>
                        <p></p>
                        {
                            this.state.IDOperator == "" ?
                                <Fragment>
                                    <div className="card" >
                                        <div className="card-header">
                                            <h5>Omset - {this.state.NamaLokasi}</h5>
                                        </div>
                                        <div className='card-body' style={{ height: "300px" }}>
                                            <Line
                                                height={300}
                                                data={this.state.chart}
                                                options={{
                                                    maintainAspectRatio: false,
                                                    title: {
                                                        display: true,
                                                        text: 'Omset',
                                                        fontSize: 20
                                                    },
                                                    legend: {
                                                        display: true,
                                                        position: 'right'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <p></p>
                                    <div className="card" >
                                        <div className="card-header">
                                            <h5>Jumlah Potong - {this.state.NamaLokasi}</h5>
                                        </div>
                                        <div className='card-body' style={{ height: "300px" }}>
                                            <Line
                                                height={300}
                                                data={this.state.chart2}
                                                options={{
                                                    maintainAspectRatio: false,
                                                    title: {
                                                        display: true,
                                                        text: 'Jumlah Potong',
                                                        fontSize: 20
                                                    },
                                                    legend: {
                                                        display: true,
                                                        position: 'right'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Fragment> : ""
                        }
                        <div>
                            <h5>{this.state.IDOperator == "" ? "Data Per Barberman" : `Omset ${this.state.Barberman}`}</h5>
                            {
                                this.state.IDOperator == "" ?
                                    this.state.Data.map((div, i) => {
                                        let chartOmset = {
                                            labels: [],
                                            datasets: []
                                        }, chartPotong = {
                                            labels: [],
                                            datasets: []
                                        }
                                        let dataPotong = [], dataOmset = [], label = [];
                                        for (let dd of this.state.Omset) if (dd.IDOperator == div.ID) {
                                            dataPotong.push(dd.JumlahPotong);
                                            dataOmset.push(dd.Omset);
                                            if (!label.includes(dd.Tanngal)) label.push(dd.Tanggal);
                                        }
                                        chartOmset.datasets = [
                                            { "label": "Omset", "data": dataOmset, "fill": false, "borderColor": "#c2185b", backgroundColor: "#c2185b", "lineTension": 0.1 }
                                        ]
                                        chartPotong.datasets = [
                                            { "label": "Jumlah Potong", "data": dataPotong, "fill": false, "borderColor": "#00bcd4", backgroundColor: "#00bcd4", "lineTension": 0.1 }
                                        ]
                                        chartOmset.labels = label;
                                        chartPotong.labels = label;
                                        if (dataOmset.length > 0) {
                                            return (
                                                <div className='card'>
                                                    <div className='card-header'>
                                                        <h6>{div.NameOperator} - {this.state.NamaLokasi}</h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className='row mb-2'>
                                                            <div className="col-6 mb-2" style={{ height: '200px' }}>
                                                                <Line
                                                                    height={200}
                                                                    data={chartOmset}
                                                                    options={{
                                                                        maintainAspectRatio: false,
                                                                        title: {
                                                                            display: true,
                                                                            text: 'Omset',
                                                                            fontSize: 20
                                                                        },
                                                                        legend: {
                                                                            display: true,
                                                                            position: 'right'
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-6 mb-2" style={{ height: '200px' }}>
                                                                <Line
                                                                    height={200}
                                                                    data={chartPotong}
                                                                    options={{
                                                                        maintainAspectRatio: false,
                                                                        title: {
                                                                            display: true,
                                                                            text: 'Jumlah Potong',
                                                                            fontSize: 20
                                                                        },
                                                                        legend: {
                                                                            display: true,
                                                                            position: 'right'
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                    :
                                    <div className='row'>
                                        <div className="col-6 mb-2">
                                            <Line
                                                height={"200px"}
                                                data={this.state.chartOmset}
                                                options={{
                                                    title: {
                                                        display: true,
                                                        text: 'Omset',
                                                        fontSize: 20
                                                    },
                                                    legend: {
                                                        display: true,
                                                        position: 'right'
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 mb-2">
                                            <Line
                                                height={"200px"}
                                                data={this.state.chartPotong}
                                                options={{
                                                    title: {
                                                        display: true,
                                                        text: 'Jumlah Potong',
                                                        fontSize: 20
                                                    },
                                                    legend: {
                                                        display: true,
                                                        position: 'right'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>

                    <div className="modal fade" id="modalCard" tabIndex="1000" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Cari Barberman</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input type="search" className='form-control' placeholder='Cari Barberman' value={this.state.Search} onChange={(e) => this.handleCariBarberman(e)} />
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>Nama</th>
                                                    <th>Telp</th>
                                                    <th>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DataBarberman.map((tr, ii) => {
                                                        return (
                                                            <tr>
                                                                <td>{tr.NameOperator}</td>
                                                                <td>{tr.Telp}</td>
                                                                <td>{tr.Email}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;