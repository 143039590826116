import React, { Fragment } from 'react';
import DataGrid, { Column, Pager, Paging, FilterRow, HeaderFilter, ColumnFixing, Grouping, GroupPanel, Summary, TotalItem, GroupItem, SortByGroupSummaryInfo } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import ReactDOM from 'react-dom';
import { rendModal, saiki, loading, tanggalIndo, api, exportData, cekStatus, getCookie } from '../modul';

function App() {
    document.getElementById("lblHeader").innerHTML = "Laporan Histori Poin";
    document.title = "Laporan Histori Poin";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                IDCustomer: '',
                Pelanggan: "",
                d1: saiki(),
                d2: saiki(),
                idlocation: localStorage.getItem("location"),
                Data: [],
                Field: [
                    { cap: "Tanggal", sort: "DocDate", type: "date" },
                    { cap: "Lokasi", sort: "Lokasi" },
                    { cap: "No. Transaksi", sort: "DocNumber" },
                    { cap: "Produk", sort: "Produk" },
                    { cap: "Kasir", sort: "Kasir" },
                    { cap: "Barberman", sort: "Barberman" },
                    { cap: "Qty", sort: "Qty", type: "number" },
                    { cap: "Price", sort: "Price", type: "number" },
                    { cap: "Debit", sort: "dpoint", type: "number" },
                    { cap: "Credit", sort: "cpoint", type: "number" },
                    { cap: "Balance", sort: "BalancePOint", type: "number" },
                    { cap: "Tanggal Buat", sort: "TimeCreated", type: "datetime" }
                ],
                DataPelanggan: [],
                Search: ""
            };
        }

        componentDidMount() {
            if (sessionStorage.getItem("datestart")) this.setState({ datestart: sessionStorage.getItem("datestart") });
            if (sessionStorage.getItem("dateend")) this.setState({ dateend: sessionStorage.getItem("dateend") });
            setTimeout(() => {
                document.getElementById('edtD1').value = tanggalIndo(this.state.d1);
                document.getElementById('edtD2').value = tanggalIndo(this.state.d2);
                this.main();
                loading();
            }, 200);
        }

        async main() {
            let sql = await api("laporan_penggunaan_point", { D1: this.state.d1, D2: this.state.d2, IDCustomer: this.state.IDCustomer });
            if (sql.status == "true") this.setState({ Data: sql.data });
            // rendTable({
            //     url: "penukaran_poin_report_web",
            //     param: {
            //         search: this.state.search,
            //         searchcount: this.state.searchcount,
            //         index: this.state.index,
            //         sort: this.state.sort,
            //         by: this.state.by,
            //         idadmin: getCookie("ID"),
            //         d1: this.state.datestart,
            //         d2: this.state.dateend,
            //         idlocation: this.state.idlocation
            //     },
            //     id: "iniTable",
            //     div: "divView",
            //     sort: (e) => this.handleSort(e),
            //     paggination: { event: (e) => this.handlePage(e), index: this.state.index },
            //     table: { cls: "table table-striped" },
            //     thead: { cls: "color-bg-biru" },
            //     tbody: { id: "tbodyData" }
            // });
        }

        handleChange(e, ssts, fn = false) {
            this.setState({ [ssts]: e.target.value });
            sessionStorage.setItem([ssts], e.target.value);
            if (fn != false) {
                setTimeout(() => {
                    this.main();
                }, 200);
            }
        }

        setType(e, on = "focus", ssts) {
            if (on == "focus") {
                e.target.value = this.state[ssts];
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state[ssts]);
            }
        }

        async handleDownload(e) {
            e.target.innerHTML = `<span class="material-icons">hourglass_bottom</span>`;
            e.target.disabled = true;
            let field = { thead: this.state.Field };
            exportData({ data: this.state.Data, field: field }, `Data Transaksi Penukaran Poin ${tanggalIndo(this.state.d1)} - ${tanggalIndo(this.state.d2)}`);
            e.target.innerHTML = `<span class="material-icons">file_download</span>`;
            e.target.disabled = false;
        }

        async handleModal() {
            let sql = await api("browse_pelanggan", { Search: this.state.Search });
            if (sql.status == "true") this.setState({ DataPelanggan: sql.data });
            let dom = (
                <Fragment>
                    <input type="search" className='form-control' placeholder='Cari Pelanggan' onChange={(e) => this.handleCariPelanggan(e)} />
                    <p></p>
                    <div className='table-responsive'>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>Nama</th>
                                    <th>Telp</th>
                                    <th>Email</th>
                                </tr>
                            </thead>
                            <tbody id="tbodyPelanggan">
                                {
                                    this.state.DataPelanggan.map((tr, ii) => {
                                        return (
                                            <tr onClick={(e) => this.handlePilihPelanggan(tr)} key={ii}>
                                                <td>{tr.NameCustomer}</td>
                                                <td>{tr.Telp}</td>
                                                <td>{tr.Email}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </Fragment>
            );

            rendModal({
                title: "Cari Pelanggan",
                body: dom,
                form: true,
                id: "modalPalanggan"
            });
        }

        async handleCariPelanggan(e) {
            let sql = await api("browse_pelanggan", { Search: e.target.value });
            let tr = sql.data.map((tr, ii) => {
                return (
                    <tr onClick={(e) => this.handlePilihPelanggan(tr)} key={ii}>
                        <td>{tr.NameCustomer}</td>
                        <td>{tr.Telp}</td>
                        <td>{tr.Email}</td>
                    </tr>
                )
            });
            ReactDOM.render(tr, document.getElementById('tbodyPelanggan'));
        }

        handlePilihPelanggan(data) {
            this.setState({ IDCustomer: data.ID, Pelanggan: data.NameCustomer });
            this.main();
            document.getElementById('btnCloseModalmodalPalanggan').click();
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="d-flex justify-content-start align-items-center gap-2">
                            <h5>Periode</h5>
                            <div className="input-group input-group-sm gap-2">
                                <input type="text" name="edtD1" id="edtD1" style={{ maxWidth: "120px" }} className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "d1")} onMouseOver={(e) => this.setType(e, 'focus', 'd1')} onBlur={(e) => this.setType(e, "blur", 'd1')} />
                                <input type="text" name="edtD2" id="edtD2" style={{ maxWidth: "120px" }} className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "d2")} onMouseOver={(e) => this.setType(e, 'focus', 'd2')} onBlur={(e) => this.setType(e, "blur", 'd2')} />
                                <input type="search" name="edtPelanggan" style={{ maxWidth: "150px" }} className="form-control form-control-sm" readOnly={true} value={this.state.Pelanggan} onFocus={() => this.handleModal()} />
                                <button className="btn btn-sm btn-default" onClick={(e) => this.main()}><i className="material-icons">search</i></button>
                                <button className="btn btn-sm btn-default" id="btnExport" onClick={(e) => this.handleDownload(e)}><span className="material-icons">file_download</span></button>
                            </div>
                        </div>
                        <p></p>
                        <div className="d-flex justify-content-start align-items-center gap-3">
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                            <DataGrid dataSource={this.state.Data} showBorders={true} allowColumnResizing={true} columnAutoWidth={true} rowAlternationEnabled={true} style={{ width: "100%", minWidth: "1000px" }}>
                                {
                                    this.state.Field.map((tr, ii) => {
                                        if (tr.type == "number") {
                                            return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                                        } else if (tr.type == "date") {
                                            return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy" key={ii} />
                                        } else if (tr.type == "datetime") {
                                            return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy H:i:s" key={ii} />
                                        } else {
                                            return <Column dataField={tr.sort} caption={tr.cap} key={ii} />
                                        }
                                    })
                                }
                                <Paging defaultPageSize={50} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                <GroupPanel visible={true} />
                                <Grouping autoExpandAll={true} />
                                <ColumnFixing enabled={true} />
                                <Summary>
                                    <TotalItem column="ID" summaryType="count" />
                                    <TotalItem column="Qty" summaryType="sum" displayFormat="Total Qty : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                    <TotalItem column="Price" summaryType="sum" displayFormat="Total Price : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                    <TotalItem column="Poin" summaryType="sum" displayFormat="Total Point : {0}" valueFormat={{ type: 'fixedPoint' }} />

                                    <GroupItem column="Qty" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Total Qty : {0}" showInGroupFooter={false} alignByColumn={true} />
                                    <GroupItem column="Price" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Total Price : {0}" showInGroupFooter={false} alignByColumn={true} />
                                    <GroupItem column="Poin" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Total : {0}" showInGroupFooter={false} alignByColumn={true} />
                                </Summary>
                                <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                            </DataGrid>
                        </div>
                    </div>

                    <div className="modal fade" id="modalCard" tabIndex="1000" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <input type="search" className='form-control' placeholder='Cari Pelanggan' value={this.state.Search} onChange={(e) => this.handleCariPelanggan(e)} />
                                    <p></p>
                                    <div className='table-responsive'>
                                        <table className='table table-striped'>
                                            <thead>
                                                <tr>
                                                    <th>Nama</th>
                                                    <th>Telp</th>
                                                    <th>Email</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.DataPelanggan.map((tr, ii) => {
                                                        return (
                                                            <tr>
                                                                <td>{tr.NameCustomer}</td>
                                                                <td>{tr.Telp}</td>
                                                                <td>{tr.Email}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;