import React, { Fragment } from 'react';
import { rendTable, rendModal, api, submitForm, saiki, loading, numberFormat, tanggalIndo, getCookie } from "../modul";
import ReactDOM from 'react-dom';
import reactDom from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "Promo Voucher";
    document.title = "Promo Voucher";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "ID",
                by: "DESC"
            };
        }

        main() {
            rendTable({
                url: "voucher_browse_web",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi:
                        [
                            { type: "edit", event: (e) => rendForm(e) },
                            { type: "view", event: (e) => this.handleDetail(e) },
                            { type: "delete", event: (e) => this.modalDelete(e) }
                        ],
                    id: "tbodyData"
                }
            });
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            setTimeout(() => {
                this.main();
            }, 500);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "voucher_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async handleDetail(e) {
            let id = e.target.dataset.id;
            let sql = await api("voucher_detail", { iddata: id });
            let dom = <Fragment>
                <table className='table table-stripped'>
                    <tbody>
                        <tr>
                            <td>Kode</td>
                            <td>:</td>
                            <td>{sql.data.CodeVoucher}</td>
                        </tr>
                        <tr>
                            <td>Nama</td>
                            <td>:</td>
                            <td>{sql.data.NameVoucher}</td>
                        </tr>
                        <tr>
                            <td>tanggal</td>
                            <td>:</td>
                            <td>{tanggalIndo(sql.data.DateStart1)} / {tanggalIndo(sql.data.DateExpired1)}</td>
                        </tr>
                        <tr>
                            <td>Tipe</td>
                            <td>:</td>
                            <td>{sql.data.TypeVoucher}</td>
                        </tr>
                        <tr>
                            <td>Produk</td>
                            <td>:</td>
                            <td>{sql.data.NameProduct}</td>
                        </tr>
                        <tr>
                            <td>Cabang</td>
                            <td>:</td>
                            <td>{sql.data.NameLocation}</td>
                        </tr>
                    </tbody>
                </table>
                <p></p>
                <div className='tablde-responsive'>
                    <table className='table table-stripped'>
                        <thead>
                            <tr>
                                <th>Nama</th>
                                <th>Telp</th>
                                <th>Email</th>
                                <th>Alamat</th>
                                <th>Qty Voucher</th>
                                <th>Sisa</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                sql.details.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{item.NameCustomer}</td>
                                            <td>{item.Telp}</td>
                                            <td>{item.Email}</td>
                                            <td>{item.Address}</td>
                                            <td>{item.QtyFree}</td>
                                            <td>{item.QtyUsed}</td>
                                        </tr>)
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Fragment>;
            rendModal({
                id: "modalInfo",
                body: dom,
                title: "Detail Voucher",
                cls: "modal-lg"
            });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Promo Voucher</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                        </div>
                    </div>
                    <nav id="paggination"></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                code: "",
                name: "",
                DateStart: saiki(),
                DateExpired: saiki(),
                IDProduct: "0",
                NameProduct: "",
                idpelanggan: "",
                namapelanggan: "",
                idlocation: "",
                namelocation: "",
                qtyrequirement: "1",
                Amount: 0,
                TypeVoucher: "Price",
                TypeGroup: "product",
                dataPelanggan: [],
                Pelanggan: []
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("voucher_detail", { iddata: this.state.id });
                this.setState({
                    code: sql.data.CodeVoucher,
                    name: sql.data.NameVoucher,
                    DateStart: sql.data.DateStart1,
                    DateExpired: sql.data.DateExpired1,
                    IDProduct: sql.data.IDProduct,
                    NameProduct: sql.data.NameProduct,
                    idpelanggan: sql.data.IDCustomer,
                    namapelanggan: sql.data.NameCustomer,
                    idlocation: sql.data.IDLocation,
                    namelocation: sql.data.NameLocation,
                    qtyrequirement: sql.data.QtyRequirement,
                    TypeVoucher: sql.data.TypeVoucher,
                    Amount: sql.data.Amount,
                    TypeGroup: sql.data.TypeGroup,
                    Pelanggan: sql.detail
                });
            }

            setTimeout(() => {
                document.getElementById('edtD1').value = tanggalIndo(this.state.DateStart);
                document.getElementById('edtD2').value = tanggalIndo(this.state.DateExpired);
                this.handleCariPelanggan("");
            }, 200);
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        async scLokasi(e) {
            this.setState({ namaproduct: e.target.value });
            const sql = await api("product_browse_web", { search: e.target.value, searchcount: 100, index: 1, sort: "NameProduct", by: "ASC" });
            const dom = sql.data.map((opt) =>
                <li><a class="dropdown-item" href="#" onClick={(e) => this.setLokasi(opt.NameProduct, opt.ID)}>{opt.NameProduct}</a></li>
            )
            ReactDOM.render(dom, document.getElementById('ulProduct'));
        }

        setLokasi(nama, id) {
            document.getElementById('edtProduct').value = nama;
            document.getElementById('edtProductID').value = id;
        }

        setType(e, on = "focus", ssts) {
            if (on == "focus") {
                e.target.value = this.state[ssts];
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state[ssts]);
            }
        }

        async modalPelanggan() {
            let dom =
                <Fragment>
                    <input type="search" className='form-control' id="edtCariPelanggan" placeholder={'Cari Nama Pelanggan'} />
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Nama</th>
                                <th>Telp</th>
                                <th>Email</th>
                                <th>Alamat</th>
                            </tr>
                        </thead>
                        <tbody id="tbodyPelanggan"></tbody>
                    </table>
                </Fragment>;

            rendModal({
                title: "Cari data Pelanggan",
                body: dom,
                id: "modalPelanggan",
                cls: "modal-lg"
            });

            let ini = this;

            let sql = await api("customer_browse", { search: "", searchcount: 100, index: 1, sort: "NameCustomer", by: "ASC" });
            let td = [];
            sql.data.map(tr =>
                td.push(
                    <tr onClick={(e) => ini.pilihPelanggan(e, tr.ID, tr.NameCustomer, tr.Telp, tr.Address)}>
                        <td>{tr.NameCustomer}</td>
                        <td>{tr.Telp}</td>
                        <td>{tr.Email}</td>
                        <td>{tr.Address}</td>
                    </tr>)
            );

            reactDom.render(td, document.getElementById('tbodyPelanggan'));

            document.getElementById('edtCariPelanggan').addEventListener("keyup", async function () {
                let sql = await api("customer_browse_web", { search: this.value, searchcount: 50, index: 1, sort: "NameCustomer", by: "ASC" });
                let td = [];
                sql.data.map(tr =>
                    td.push(
                        <tr onClick={(e) => ini.pilihPelanggan(e, tr.ID, tr.NameCustomer, tr.Telp, tr.Address)}>
                            <td>{tr.NameCustomer}</td>
                            <td>{tr.Telp}</td>
                            <td>{tr.Email}</td>
                            <td>{tr.Address}</td>
                        </tr>)
                );

                reactDom.render(td, document.getElementById('tbodyPelanggan'));
            });
        }

        async handleCariPelanggan(val) {
            let sql = await api("customer_browse", { search: val, searchcount: 100, index: 1, sort: "NameCustomer", by: "ASC" });
            if (sql.status == "true") this.setState({ dataPelanggan: sql.data });
        }

        pilihPelanggan(e, id, nama, telp, address) {
            let data = this.state.Pelanggan;
            let tempID = [];
            for (let dd of data) tempID.push(dd.IDCustomer);
            if (!tempID.includes(id)) {
                data.push({ IDCustomer: id, NameCustomer: nama, Telp: telp, Address: address });
                setTimeout(() => {
                    this.setState({ Pelanggan: data });
                }, 200);
            } else {
                alert("Pelanggan Sudah dipilih");
            }
        }

        async modalLokasi() {
            let dom =
                <Fragment>
                    <input type="search" className='form-control' id="edtCariLokasi" placeholder={'Cari Nama Lokasi / Cabang'} />
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Kode</th>
                                <th>Nama</th>
                            </tr>
                        </thead>
                        <tbody id="tbodyLokasi"></tbody>
                    </table>
                </Fragment>;

            rendModal({
                title: "Cari data Cabang",
                body: dom,
                id: "modalCabang",
                cls: "modal-lg"
            });

            let ini = this;

            let sql = await api("location_browse_web", { search: "", searchcount: 50, index: 1, sort: "NameLocation", by: "ASC" });
            let td = [];
            sql.data.map(tr =>
                td.push(
                    <tr onClick={(e) => ini.pilihLocation(e, tr.ID, tr.NameLocation)}>
                        <td>{tr.CodeLocation}</td>
                        <td>{tr.NameLocation}</td>
                    </tr>)
            );

            reactDom.render(td, document.getElementById('tbodyLokasi'));

            document.getElementById('edtCariLokasi').addEventListener("keyup", async function () {
                let sql = await api("location_browse_web", { search: this.value, searchcount: 50, index: 1, sort: "NameLocation", by: "ASC" });
                let td = [];
                sql.data.map(tr =>
                    td.push(
                        <tr onClick={(e) => ini.pilihLocation(e, tr.ID, tr.NameLocation)}>
                            <td>{tr.CodeLocation}</td>
                            <td>{tr.NameLocation}</td>
                        </tr>)
                );

                reactDom.render(td, document.getElementById('tbodyLokasi'));
            });
        }

        pilihLocation(e, id, nama) {
            this.setState({ idlocation: id, namelocation: nama });
            document.getElementById('btnCloseModalmodalCabang').click();
        }

        modalCari(e) {
            let type = this.state.TypeGroup;
            let thead = <tr>
                <th>Nama Produk</th>
                <th>Harga</th>
            </tr>;

            if (type == "category") {
                thead = <tr>
                    <th>Kode</th>
                    <th>Nama Kategori</th>
                </tr>;
            }
            rendModal({
                title: "Cari " + this.state.TypeGroup,
                cls: "modal-lg",
                id: "modalCari",
                body: <Fragment>
                    <input type="search" class="form-control form-control-sm" onChange={(e) => this.cariProduct(e.target.value, type)} />
                    <p></p>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                {
                                    thead
                                }
                            </thead>
                            <tbody id="tbodyCariProduk"></tbody>
                        </table>
                    </div>
                </Fragment>
            });

            this.cariProduct("", type);
        }

        async cariProduct(val, type) {
            const sql = await api(type == "product" ? "product_browse" : "category_browse", { search: val, index: 1, searchcount: 10 });
            let dom = sql.data.map(opt =>
                <tr style={{ cursor: "pointer" }} onClick={() => this.pilihProduct(opt.ID, opt.NameProduct)}>
                    <td>{opt.NameProduct}</td>
                    <td>{numberFormat(opt.Price)}</td>
                </tr>
            )
            if (type == "category") {
                dom = sql.data.map(opt =>
                    <tr style={{ cursor: "pointer" }} onClick={() => this.pilihProduct(opt.ID, opt.NameCategory)}>
                        <td>{opt.CodeCategory}</td>
                        <td>{opt.NameCategory}</td>
                    </tr>
                )
            }
            ReactDOM.render(dom, document.getElementById('tbodyCariProduk'));
        }

        pilihProduct(id, name) {
            this.setState({ IDProduct: id, NameProduct: name });
            document.getElementById('btnCloseModalmodalCari').click();
        }

        handleDeleteCustomer(i) {
            let data = this.state.Pelanggan;
            let tmpData = [];
            for (let idx in data) if (i != idx) tmpData.push(data[idx]);
            this.setState({ Pelanggan: tmpData });
        }

        render() {
            return (
                <Fragment>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <div class="row">
                        <div class="col-md-6 mb-2"></div>
                        <div class="col-md-6 mb-2"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-group">
                                <label>Nama Voucher</label>
                                <input type="text" name="NameVoucher" class="form-control" id="edtNama" value={this.state.name} onChange={(e) => this.handleChange(e, "name")} required />
                                <div class="invalid-feedback">Silahakan isi code voucher</div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-2">
                            <div class="form-group">
                                <label>Jenis Voucher</label>
                                <select class="form-select" name='TypeVoucher' value={this.state.TypeVoucher} onChange={(e) => this.handleChange(e, "TypeVoucher")} required >
                                    <option value="price">Harga</option>
                                    <option value="percentage">Persen</option>
                                    <option value="product">Barang</option>
                                </select>
                                <div class="invalid-feedback">Silahkan pilih type voucher</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-group">
                                <label>Tanggal Mulai</label>
                                <input type="text" class="form-control form-date" id="edtD1" value={this.state.DateStart} onChange={(e) => this.handleChange(e, "DateStart")} onMouseOver={(e) => this.setType(e, "focus", "DateStart")} onBlur={(e) => this.setType(e, "blur", "DateStart")} required />
                                <input type="hidden" name="DateStart" value={this.state.DateStart} />
                                <div class="invalid-feedback">Silahkan pilih tanggal mulai</div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-2">
                            <div class="form-group">
                                <label>Tanggal Selesai</label>
                                <input type="text" class="form-control form-date" id="edtD2" value={this.state.DateExpired} onChange={(e) => this.handleChange(e, "DateExpired")} onMouseOver={(e) => this.setType(e, "focus", "DateExpired")} onBlur={(e) => this.setType(e, "blur", "DateExpired")} required />
                                <input type="hidden" name="DateExpired" value={this.state.DateExpired} />
                                <div class="invalid-feedback">Silahkan pilih tanggal expired</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-group">
                                <label>Tipe Produk</label>
                                <select className='form-select' value={this.state.TypeGroup} name="TypeGroup" onChange={(e) => this.setState({ TypeGroup: e.target.value, IDProduct: "", NameProduct: "" })} readOnly={this.state.TypeVoucher == 'product' ? false : true} >
                                    <option value="product">Produk</option>
                                    <option value="category">Kategori Produk</option>
                                </select>
                            </div>
                        </div>
                        <div class="col mb-2">
                            <div class="form-group">
                                <label>{this.state.TypeGroup == "product" ? "Produk" : "Kategori"}</label>
                                <input type="hidden" name="IDProduct" id="edtProductID" value={this.state.IDProduct} />
                                <div className='input-group'>
                                    <input type="text" name="namaproduct" class="form-control" id="edtProduct" value={this.state.NameProduct} onFocus={(e) => this.modalCari()} required={this.state.TypeVoucher == "product" ? true : false} readOnly={this.state.TypeVoucher == 'product' ? false : true} />
                                    <button type='button' className='btn btn-default' onClick={(e) => this.modalCari()}>
                                        <span className='material-icons'>search</span>
                                    </button>
                                </div>
                                <div class="invalid-feedback">Silahkan pilih produk</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-2">
                            <div className='form-grouo'>
                                <label>Nilai Voucher</label>
                                <input type="number" className='form-control' name="Amount" value={this.state.Amount} onChange={(e) => this.setState({ Amount: e.target.value })} required={this.state.TypeVoucher == "product" ? false : true} readOnly={this.state.TypeVoucher == 'product' ? true : false} />
                            </div>
                        </div>
                        <div class="col mb-2">
                            <div class="form-group">
                                <input type="hidden" name="IDLocation" id="edtLocationID" value={this.state.idlocation} />
                                <label>Lokasi / Cabang</label>
                                <div class="input-group">
                                    <input type="text" name="namalocation" class="form-control" id="edtLocation" value={this.state.namelocation} onFocus={(e) => this.modalLokasi()} />
                                    <button type='button' className='btn btn-default' onClick={(e) => this.modalLokasi()}>
                                        <span className='material-icons'>search</span>
                                    </button>
                                </div>
                                <div class="invalid-feedback">Silahkan pilih cabang</div>
                            </div>
                        </div>
                    </div>
                    <p></p>
                    <div className='row'>
                        <div className='col-md-6 mb-2'>
                            <input type="search" className='form-control' placeholder='Cari Nama Pelanggan' onChange={(e) => this.handleCariPelanggan(e.target.value)} />
                            <div className='table-responsive' style={{ maxHeight: "300px" }}>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <td width="35%">Nama</td>
                                            <td width="20%">Telp</td>
                                            <td width="20%">Email</td>
                                            <td width="25%">Alamat</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.dataPelanggan.map((tr, i) => {
                                                return (
                                                    <tr onClick={(e) => this.pilihPelanggan(e, tr.ID, tr.NameCustomer, tr.Telp, tr.Address)}>
                                                        <td>{tr.NameCustomer}</td>
                                                        <td>{tr.Telp}</td>
                                                        <td>{tr.Email}</td>
                                                        <td>{tr.Address}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='col-md-6 mb-2'>
                            <h5>Pelanggan yang dipilih</h5>
                            <div className='table-responsive' style={{ maxHeight: "300px" }}>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <td width="40%">Nama</td>
                                            <td width="25%">Telp</td>
                                            <td width="30%">Alamat</td>
                                            <td width="5%"></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.Pelanggan.map((item, i) => {
                                                return (
                                                    <tr>
                                                        <td>{item.NameCustomer}</td>
                                                        <td>{item.Telp}</td>
                                                        <td>{item.Address}</td>
                                                        <td>
                                                            <button type='button' className='btn' onClick={() => this.handleDeleteCustomer(i)}>
                                                                <span className='material-icons'>delete</span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" name='pelanggan' value={JSON.stringify(this.state.Pelanggan)} />
                </Fragment>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "voucher_edit" : "voucher_insert",
        title = id ? "Edit Voucher" : "Tambah Voucher";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form",
        cls: "modal-xl"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;