import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import "./Login.css";
import { Fragment } from 'react';
import { rendMenu, rendLokasi, changeLocation, rendModal, pesan, submitForm, getCookie, logout, api, setCookie, loading } from './modul.js';
import Customer from './page/Customer';
import Dashboard from './page/Dashboard';
import Booking from './page/Booking';
import Setoperator from './page/Setoperator';
import Counter from './page/Counter';
import Payment from './page/Payment';
import Location from './page/Location';
import Cost from './page/Cost';
import Hardware from './page/Hardware';
import Transaksipenjualan from './page/Transaksipenjualan';
import Penukaranpoin from './page/Penukaranpoin';
import Transaksiendors from './page/Transaksiendors';
import Customerendors from './page/Customerendors';
import Barberman from './page/Barberman';
import Logkasir from './page/Logkasir';
import Kategori from './page/Kategori';
import Kasir from './page/Kasir';
import Admin from './page/Admin';
import Promovoucher from './page/Promovoucher';
import Listvoucher from './page/Listvoucher';
import Product from './page/Product';
import ProductBundle from './page/Bundle';
import Formulaproduct from './page/Formulaproduct';
import Promosi from './page/Promosi';
import Point from './page/Point';
import Penukaranpoint from './page/Penukaranpoint';
import Penjualanoutlete from './page/Penjualanoutlete';
import Pivotoperator from './page/Pivotoperator'
import Paymenttype from './page/Paymenttype';
import Penjualanproduk from './page/Penjualanproduk';
import Laporanpelanggan from './page/Laporanpelanggan';
import Aktifitascabang from './page/Aktifitascabang';
import TransaksiFranc from './page/TransaksiFranc';
import PromoCustomer from './page/PromoCustomer';
import PromoBirthday from './page/Promobirthday';
import Target from './page/Target';
import TargetBarberman from './page/TargetBarberman';
import Promomember from './page/Promomember';
import LaporanBundle from './page/LaporanBundle';
import CustomerCompliment from './page/complaint';
import LaporanHistoryPoint from './page/LaporanHistoryPoint'
import LaporanEndorsement from './page/LaporanEndorsement'
import LaporanOmsetBarbermen from './page/LaporanOmsetBarbermen'

function App() {
  class Main extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Menu: [],
        Group: [],
        Lokasi: [],
        isHO: 0
      }
    }

    async componentDidMount() {
      let sql = await api("data_listmenuadmin", { idadmin: getCookie("ID") });
      if (sql.status == "true") this.setState({ Menu: sql.data, Group: sql.group, Lokasi: sql.lokasi, isHO: sql.ho });
      rendLokasi();
      let dataMenu = window.location.pathname.replace("/", "");
      pilihMenu(dataMenu, false);
      window.addEventListener('popstate', function (event) {
        if (event.state != null) {
          let dataMenu = window.location.pathname.replace("/", "");
          pilihMenu(dataMenu, false);
        }
      });

      document.getElementById("sidebarCollapse").onclick = function () {
        document.getElementById("sidebar").style.display = "block";
        document.getElementById("sidebar").classList.toggle("active");
      }
    }

    render() {
      return (
        <Fragment>
          <div style={{ position: 'fixed', bottom: '0px', height: '100%', width: '100%' }}>
            <div className="wrapper">
              <nav id="sidebar">
                <div className="sidebar-header" onClick={(e) => window.location.reload()} style={{ cursor: 'pointer' }}>
                  <h3 className="logo-panjang"><img src="logo-panjang.png" alt="Broadway" width="100%" /></h3>
                  <h3 className="logo"><img src="logo.png" alt="B" width="100%" /></h3>
                </div>
                <div className="transbox">
                  <ul className="list-unstyled components" id="divMenu">
                    {
                      this.state.Menu.map((li, ii) => {
                        if (li.GroupID == 0) {
                          return (
                            <li id={li.FormCode} onClick={() => pilihMenu(li.FormCode, true)} key={ii}>
                              <a href="#">
                                <i className="material-icons">{li.ImgMenu}</i> <span className="nama-menu">{li.FormName}</span>
                              </a>
                            </li>
                          )
                        }
                      })
                    }
                    {
                      this.state.Group.map((li, ii) => {
                        return (
                          <li key={ii}>
                            <a href={`#${li.FormCode}`} data-bs-toggle="collapse" aria-expanded="false">
                              <i className="material-icons">{li.ImgMenu}</i> <span className="nama-menu">{li.FormName}</span>
                            </a>
                            <ul className="collapse list-unstyled" id={li.FormCode}>
                              {
                                this.state.Menu.map((menu, iii) => {
                                  if (menu.GroupID == li.ID) {
                                    return (
                                      <li id={menu.FormCode} onClick={() => pilihMenu(menu.FormCode, true)} key={iii}>
                                        <a href="#"> <span className="nama-menu">{menu.FormName}</span> </a>
                                      </li>
                                    )
                                  }
                                })
                              }
                            </ul>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </nav>
              <div id="content">
                <div className="card" style={{ padding: '5px 10px 0 10px', backgroundColor: '#FEFEFE', borderRadius: '20px 20px 0 0' }}>
                  <div className="row">
                    <div className="col-md-9 d-flex justify-content-start align-items-center gap-4">
                      <nav className="navbar"> <button type="button" id="sidebarCollapse" className="btn btn-sm btn-default" style={{ borderRadius: '8px' }}> <i className="material-icons">menu</i> </button> </nav>
                      <span className="card-title"> <label style={{ fontSize: '18px', color: 'black' }} id="lblHeader"></label> </span>
                    </div>
                    <div className="col-md-3 d-flex justify-content-start align-items-center gap-4">
                      <div className="input-group input-group-sm">
                        <button className="btn btn-sm btn-secondary" style={{ backgroundColor: '#e9ecee !important', border: 'solid #e9ecee !important', borderRadius: '0' }} disabled><i className="material-icons" style={{ color: 'black' }}>room</i></button>
                        <select className="form-select" id="ddLocation"
                          onChange={(e) => {
                            e.preventDefault();
                            changeLocation(e);
                            pilihMenu(window.location.pathname.substring(1), false);
                          }}></select>
                        <div className="dropdown">
                          <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="material-icons icon-color" style={{ fontSize: '30px', verticalAlign: 'bottom' }}>person</i> <label style={{ fontSize: ' 20px' }} id="lblNamaAdmin">{getCookie("NM")}</label>
                          </button>
                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a className="dropdown-item" href="#" onClick={() => modalGantiPassword()}>Ganti Password</a> </li>
                            <li><a className="dropdown-item" href="#" onClick={() => logout()}>Log Out</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tampil" className="card shadow shadow-lg" style={{ minHeight: '85vh', maxHeight: '85vh', borderRadius: '0 0 20px 20px', overflowY: 'scroll' }}></div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    }
  }

  class Login extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        username: '',
        pwd: "",
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e, ssts) {
      this.setState({ [ssts]: e.target.value });
    }

    handleSubmit(event) {
      event.preventDefault();
      let btn = document.getElementById("btnLogin");
      let form = document.getElementById('iniForm');
      let data = new FormData(form);
      const host = window.location.hostname == "localhost" ? "http://localhost:2005/api_mobile/" : "http://pxa.co.id:2009/api_mobile/";
      fetch(host + "login_account_admin", {
        method: 'POST',
        body: data,
      }).then(response => response.json()).then(hasil => {
        if (hasil.status == "true") {
          setCookie("ID", hasil.data.ID, 30);
          setCookie("NM", hasil.data.NameAdmin, 30);
          window.location.href = "./";
        } else {
          alert(hasil.message);
          btn.innerHTML = "Login";
          btn.disabled = false;
        }
      }).catch((error) => {
        console.log("Error: " + error);
        btn.innerHTML = "Login";
        btn.disabled = false;
      });
    }

    render() {
      return (
        <Fragment>
          <div className="header">
            <div className="inner-header flex">
              <h3>Broadway Login Page</h3>
            </div>
          </div>
          <div className="content flex">
            <form id="iniForm" onSubmit={this.handleSubmit}>
              <input className="input" type="text" name="username" placeholder="Username" value={this.state.username} onChange={(e) => this.handleChange(e, "username")} required />
              <input className="input" type="password" name="password" placeholder="Password" value={this.state.pwd} onChange={(e) => this.handleChange(e, "pwd")} required />
              <button type="submit" className="login-btn" id="btnLogin">Login</button>
            </form>
          </div>
        </Fragment>
      )
    }
  }

  let __ID = getCookie("ID") || "none";
  if (__ID != "none") {
    return <Main />
  } else {
    return <Login />
  }
}

function modalGantiPassword() {
  let dom = <Fragment>
    <input type="hidden" name="idadmin" value={getCookie("ID")} />
    <div className="form-group">
      <label>Password Lama</label>
      <input type="password" name="oldpassword" id="edtOldPassword" className="form-control" required />
      <div className="invalid-feedback">Silahkan isi Password Lama</div>
    </div>
    <div className="form-group">
      <label>Password Baru</label>
      <input type="password" name="newpassword" id="edtNewPassword1" className="form-control" required />
      <div className="invalid-feedback">Silahkan isi Password baru</div>
    </div>
    <div className="form-group">
      <label>Ulangi Password Baru</label>
      <input type="password" name="PWDN2" id="edtNewPassword2" className="form-control" required />
      <div className="invalid-feedback">Silahkan ulangi password baru</div>
    </div>
  </Fragment>

  rendModal({
    title: "Ganti Passworld",
    body: dom,
    form: true,
    footer: "btnSave"
  });

  document.getElementById('iniForm').addEventListener("submit", function (e) {
    e.preventDefault();
    let d1 = document.getElementById('edtNewPassword1').value;
    let d2 = document.getElementById('edtNewPassword2').value;
    if (d1 == d2 && d1 != "") {
      submitForm(e, { act: "profile_changepassword", reload: true, modal: "btnCloseModalForm" });
    } else {
      pesan("Info", "Password baru tidak sesuai", "warning");
    }
  });
}

export const pilihMenu = function (path, ch = true) {
  document.getElementById('loading').style.display = "block";
  var e = document.getElementsByClassName("active");
  for (let h = 0; h < e.length; h++) { e[h].classList.remove("active"); }
  if (ch == true) {
    window.history.pushState(path, "data", path);
    sessionStorage.removeItem('tgl');
    sessionStorage.removeItem('bln');
    sessionStorage.removeItem('d1');
    sessionStorage.removeItem('d2');
    sessionStorage.removeItem('datestart');
    sessionStorage.removeItem('dateend');
  }
  if (document.getElementById(path)) document.getElementById(path).className = "active";
  switch (path) {
    case 'frmcustomer':
      Customer();
      break;

    case 'frmdashboard':
      Dashboard();
      break;

    case 'frmboking':
      Booking();
      break;

    case 'frmsetoperatorloc':
      Setoperator();
      break;

    case 'frmcounter':
      Counter();
      break;

    case 'frmpayment':
      Payment();
      break;

    case 'frmlocation':
      Location();
      break;

    case 'frmcosttemplate':
      Cost();
      break;

    case 'frmhw':
      Hardware();
      break;

    case 'frmpromocus':
      PromoCustomer();
      break;

    case 'frmtransaction':
      Transaksipenjualan();
      break;

    case 'frmpenukaranpointreport':
      Penukaranpoin();
      break;

    case 'frmpenjualanendorsemen':
      Transaksiendors();
      break;

    case 'frmendorsement':
      Customerendors();
      break;

    case 'frmoperator':
      Barberman();
      break;

    case 'frmkasirlog':
      Logkasir();
      break

    case 'frmcategory':
      Kategori();
      break;

    case 'frmkasir':
      Kasir();
      break;

    case 'frmadmin':
      Admin();
      break;

    case 'frmvoucher':
      Promovoucher();
      break;

    case 'frmvouchertrans':
      Listvoucher();
      break;

    case 'frmproduct':
      Product();
      break;

    case 'frmformulaproduct':
      Formulaproduct();
      break;

    case 'frmpromo':
      Promosi();
      break;

    case 'frmpointproduct':
      Point();
      break;

    case 'frmbirhtday':
      PromoBirthday();
      break;

    case 'frmpenukaranpoints':
      Penukaranpoint();
      break;

    case 'frmpenjualanoutlete':
      Penjualanoutlete();
      break;

    case 'frmpivotoperator':
      Pivotoperator();
      break;

    case 'frmpaymenttype':
      Paymenttype();
      break;

    case 'frmpivotproduct':
      Penjualanproduk();
      break;

    case 'frmcustomerreport':
      Laporanpelanggan();
      break;

    case 'frmbookinglist':
      Aktifitascabang();
      break;

    case 'frmtransactionfranc':
      TransaksiFranc();
      break;

    case 'frmtarget':
      Target();
      break;

    case 'frmtargetbarberman':
      TargetBarberman();
      break;

    case 'frmpromomember':
      Promomember();
      break;

    case 'frmbundle':
      ProductBundle();
      break;

    case 'frmlaporanbundle':
      LaporanBundle();
      break;

    case 'frmcstcmp':
      CustomerCompliment();
      break;

    case 'laporanhistorypoin': {
      LaporanHistoryPoint();
    } break;

    case 'frmReportEndorse': {
      LaporanEndorsement();
    } break;

    case 'LaporanOmsetBarbermen': {
      LaporanOmsetBarbermen();
    } break;

    default: {
      if (document.getElementById('frmdashboard')) {
        document.getElementById('frmdashboard').click();
      } else {
        let elm = <h1>Tidak ada menu</h1>
        document.getElementById("lblHeader").innerHTML = "Menu Tidak di temukan";
        document.title = "Broadway Barbershop";
        ReactDOM.render(elm, document.getElementById('tampil'));
      }
      loading();
    } break;
  }
  var x = window.matchMedia("(max-width: 768px)");
  if (x.matches == true) {
    document.getElementById("sidebar").classList.add("active");
  } else {
    document.getElementById("sidebar").classList.remove("active");
  }
}

export default App;
