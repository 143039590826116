import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import DataGrid, { Column, Pager, Paging, FilterRow, HeaderFilter, ColumnFixing, Grouping, GroupPanel, Summary, TotalItem, GroupItem, SortByGroupSummaryInfo } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import { numberFormat, api, saiki, tanggalIndo, rowspan, exportData, rendModal, submitForm, loading, rendPagginations, cekStatus, getCookie } from '../modul';

function App() {
    document.getElementById("lblHeader").innerHTML = "Transaksi Penjualan";
    document.title = "Transaksi Penjualan";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "DocNumber",
                by: "ASC",
                mode: "ringkasan",
                url: "transaction_browse_web",
                filteridbarberman: "all",
                modeview: "transaction",
                d1: saiki(),
                d2: saiki(),
                idlocation: localStorage.getItem("location"),
                arrBarberan: [],
                disBarberman: false,
                DataRingkasan: [],
                DataDetail: [],
                Detail: {},
                field: [],
                data: [],
                pay: [],
                td: [],
                dataPayment: [],
                paymentID: "",
                nota: 0,
                total: 0,
                diskon: 0,
                tips: 0,
                grandtotal: 0,
                komisi: 0,
                jasa: 0,
                produk: 0,
                TotalPending: 0,
                TotalFinish: 0,
                TotalBatal: 0,
                IsProcessed: 2
            };
        }

        async componentDidMount() {
            if (sessionStorage.getItem("d1")) this.setState({ d1: sessionStorage.getItem("d1") });
            if (sessionStorage.getItem("d2")) this.setState({ d2: sessionStorage.getItem("d2") });
            if (sessionStorage.getItem("bln")) this.setState({ bln: sessionStorage.getItem("bln") });
            let sql = await api("operator_browse_web", { searchcount: 100 });
            let arrTmp = [{ ID: "0", NameOperator: "Semua Barberman" }];
            for (let i of sql.data) arrTmp.push({ ID: i.ID, NameOperator: i.NameOperator });
            this.setState({ arrBarberan: arrTmp, dataPayment: sql.payment });
            setTimeout(() => {
                this.main();
                cekStatus();
                document.getElementById('edtD1').value = tanggalIndo(this.state.d1);
                document.getElementById('edtD2').value = tanggalIndo(this.state.d2);
            }, 200);
            loading();
        }

        handleMode(mode) {
            loading("show");
            this.setState({ mode: mode });
            setTimeout(() => {
                this.main();
            }, 300);
            loading();
        }

        setType(e, on = "focus", ssts) {
            if (on == "focus") {
                e.target.value = this.state[ssts];
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state[ssts]);
            }
        }

        handleChange(e, ssts, fn = false) {
            this.setState({ [ssts]: e.target.value });
            sessionStorage.setItem([ssts], e.target.value);
            if (fn != false) {
                loading("show");
                setTimeout(() => {
                    this.main();
                    loading();
                }, 200);
            }
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        modalResend(id) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="docnumber" value={id} />
                    <h3 style={{ fontSize: "24px" }}>Apakah anda yakin akan mengirim ulang invoice</h3>
                </Fragment>
            );

            rendModal({
                title: "Kirim Ulang Inovice",
                body: dom,
                form: true,
                id: "kirimUlang",
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">send</i> Kirim</button>`
            });

            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "resend_email", reload: true, modal: "btnCloseModalkirimUlang", form: "#iniForm" });
            });
        }

        async modalDetail(e) {
            let sql = await api("detail_transaction_web", { iddata: e.data.ID });
            let dom = (
                <Fragment>
                    <div className="row">
                        <div className="col-md-4 mb2">
                            <table className="table table-striped">
                                <tbody>
                                    <tr><td>Tanggal</td><td>:</td><td>{tanggalIndo(sql.h.DocDate)}</td></tr>
                                    <tr><td>Invoice</td><td>:</td><td>{sql.h.DocNumber}</td></tr>
                                    <tr><td>Pelanggan</td><td>:</td><td>{sql.h.NameAccount}</td></tr>
                                    <tr><td>Kasir</td><td>:</td><td>{sql.h.Kasir}</td></tr>
                                    <tr><td>Barberman</td><td>:</td><td>{sql.h.Operator}</td></tr>
                                    <tr><td>Jam Datang</td><td>:</td><td>{sql.h.TimeCreated.substring(11, 19)}</td></tr>
                                    <tr><td>Jam Mulai</td><td>:</td><td>{sql.h.TimeStartProcess.substring(11, 19)}</td></tr>
                                    <tr><td>Jam Selesai</td><td>:</td><td>{sql.h.TimeEndProcces.substring(11, 19)}</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-8 mb2">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-header"><h5 style={{ fontSize: "20px" }}>Total</h5></div>
                                        <div className="card-body"><h2 id="detailTotal" style={{ fontSize: "24px" }}>{numberFormat(sql.s.Total)}</h2></div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-header"><h5 style={{ fontSize: "20px" }}>Diskon</h5></div>
                                        <div className="card-body"><h2 id="detailDiskon" style={{ fontSize: "24px" }}>{numberFormat(sql.s.Diskon)}</h2></div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-header"><h5 style={{ fontSize: "20px" }}>Tips</h5></div>
                                        <div className="card-body"><h2 id="detailTips" style={{ fontSize: "24px" }}>{numberFormat(sql.s.Tips)}</h2></div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-header"><h5 style={{ fontSize: "20px" }}>Grandtotal</h5></div>
                                        <div className="card-body"><h2 id="detailGT" style={{ fontSize: "24px" }}>{numberFormat(sql.s.GT)}</h2></div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <h5 style={{ fontSize: "20px" }}>Detail Produk</h5>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Nama Produk</th>
                                        <th>Qty</th>
                                        <th>Harga</th>
                                        <th>Diskon</th>
                                        <th>Grandtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sql.d.map((td, i) =>
                                            <tr key={i}>
                                                <td>{td.NameProduct}</td>
                                                <td>{td.Qty}</td>
                                                <td>{numberFormat(td.Price)}</td>
                                                <td>{numberFormat(td.Discount)}</td>
                                                <td>{numberFormat(td.Total)}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <p></p>
                            <h5 style={{ fontSize: "20px" }}>Detail Pembayaran</h5>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Nama Pembayaran</th>
                                        <th>Nominal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sql.pay.map((td, i) =>
                                            <tr key={i}>
                                                <td>{td.NamePayment}</td>
                                                <td>{numberFormat(td.Debit)}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Fragment>
            );

            rendModal({
                title: "Detail Transaksi",
                cls: "modal-xl",
                id: "modalDetail",
                body: dom
            })
        }

        async modalSendWa(id) {
            let sql = await api("detail_transaction_web", { iddata: id });
            let dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={id} />
                    <div className="form-group">
                        <label>Telp</label>
                        <input type="text" name="telp" value={sql.h.Telp} className="form-control form-control-sm" />
                    </div>
                    <div className="form-group">
                        <label>Pesan</label>
                        <textarea className="form-control" name="notes" rows="5" value={"Halo " + sql.h.NameAccount + " terimakasih sudah berkunjung ke Broadway Barbershop cabang " + sql.h.NameLocation + " pada tanggal " + tanggalIndo(sql.h.DocDate) + " Berikut terlampir invoice transaksi anda."}></textarea>
                    </div>
                </Fragment>
            );

            rendModal({
                title: "Kirim Invoice via Wa",
                body: dom,
                form: true,
                id: "kirimWA",
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">send</i> Kirim</button>`
            });

            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "send_wa", reload: true, modal: "btnCloseModalkirimWA", form: "#iniForm" });
            });
        }

        async handleDownload(e) {
            e.target.innerHTML = `<span className="material-icons">hourglass_bottom</span>`;
            e.target.disabled = true;
            let url = this.state.mode == "ringkasan" ? "transaction_browse_web" : "transaction_detail_browse_web";
            let param = {
                search: this.state.search,
                searchcount: 10000,
                index: this.state.index,
                sort: this.state.sort,
                by: this.state.by,
                idadmin: getCookie("ID"),
                filter: "2",
                datestart: this.state.d1,
                dateend: this.state.d2,
                idlocation: this.state.idlocation,
                filteridbarberman: this.state.filteridbarberman
            }
            let sql = await api(url, param);
            exportData(sql, `Data Laporan Penjualan ${tanggalIndo(this.state.d1)} - ${tanggalIndo(this.state.d2)}`);
            setTimeout(() => {
                e.target.innerHTML = `<span className="material-icons">file_download</span>`;
                e.target.disabled = false;
            }, 2000);
        }

        async main() {
            let url = this.state.mode == "ringkasan" ? "transaction_browse_web" : "transaction_detail_browse_web";
            let param = {
                search: this.state.search,
                searchcount: this.state.searchcount,
                index: this.state.index,
                sort: this.state.sort,
                by: this.state.by,
                idadmin: getCookie("ID"),
                filter: this.state.IsProcessed,
                datestart: this.state.d1,
                dateend: this.state.d2,
                idlocation: this.state.idlocation,
                filteridbarberman: this.state.filteridbarberman,
                paymentID: this.state.paymentID
            }

            let sql = await api(url, param);
            console.log(sql.data);
            if (sql.status == "true") {
                if (this.state.mode == "detail") {
                    this.setState({
                        field: sql.field.thead,
                        DataDetail: sql.data,
                        pay: sql.pay,
                        nota: sql.sum.TotalNota,
                        total: sql.sum.Total,
                        diskon: sql.sum.Discount,
                        tips: sql.sum.Tips,
                        grandtotal: sql.sum.Grandtotal,
                        komisi: sql.sum.Commision,
                        jasa: sql.jasa,
                        produk: sql.produk,
                        TotalPending: sql.TotalPending,
                        TotalFinish: sql.TotalFinish,
                        TotalBatal: sql.TotalBatal
                    });
                } else {
                    this.setState({
                        field: sql.field.thead,
                        DataRingkasan: sql.data,
                        pay: sql.pay,
                        nota: sql.sum.TotalNota,
                        total: sql.sum.Total,
                        diskon: sql.sum.Discount,
                        tips: sql.sum.Tips,
                        grandtotal: sql.sum.Grandtotal,
                        komisi: sql.sum.Commision,
                        jasa: sql.jasa,
                        produk: sql.produk,
                        TotalPending: sql.TotalPending,
                        TotalFinish: sql.TotalFinish,
                        TotalBatal: sql.TotalBatal
                    });
                }
            }
        }

        handleChangeFilter(state) {
            this.setState({ IsProcessed: state });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col col-md-7">
                                <div className="d-flex justify-content-start align-items-center gap-1">
                                    <h5>Periode</h5>
                                    <div className="input-group input-group-sm gap-2">
                                        <input type="text" name="edtD1" id="edtD1" className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "d1")} onMouseOver={(e) => this.setType(e, 'focus', 'd1')} onBlur={(e) => this.setType(e, "blur", 'd1')} />
                                        <input type="text" name="edtD2" id="edtD2" className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "d2")} onMouseOver={(e) => this.setType(e, 'focus', 'd2')} onBlur={(e) => this.setType(e, "blur", 'd2')} />
                                        <select className="form-select form-select-sm" onChange={(e) => this.handleChangeFilter(e.target.value)}>
                                            <option value="2">Finish</option>
                                            <option value="1">Pending</option>
                                            <option value="3">Batal</option>
                                        </select>
                                        <button className="btn btn-sm btn-default" onClick={() => this.main()}><span className="material-icons">search</span></button>
                                        <button className="btn btn-sm btn-default" id="btnExport" onClick={(e) => this.handleDownload(e)}><span className="material-icons">file_download</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col col-md-3'>
                                <div className="d-flex justify-content-start align-items-center gap-1">
                                    <span className='badge rounded-pill bg-success' onClick={() => this.handleChangeFilter('1')}>Pending : {numberFormat(this.state.TotalPending)}</span>
                                    <span className='badge rounded-pill bg-primary' onClick={() => this.handleChangeFilter('2')}>Finish : {numberFormat(this.state.TotalFinish)}</span>
                                    <span className='badge rounded-pill bg-danger' onClick={() => this.handleChangeFilter('3')}>Batal : {numberFormat(this.state.TotalBatal)}</span>
                                </div>
                            </div>
                            <div className="col col-md-2">
                                <div className="d-flex justify-content-end align-items-center">
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-ringkasan-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={(e) => this.handleMode('ringkasan')}>Ringkasan</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-detail-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={(e) => this.handleMode('detail')}>Detail</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='div-card-sum'>
                            <div className="d-flex justify-content-start align-items-center gap-2">
                                <div className="card card-sum">
                                    <span style={{ fontSize: "14px;" }}>Total Nota</span>
                                    <span id="divCT" style={{ fontSize: "20px", fontWeight: "bold" }}>{numberFormat(this.state.nota)}</span>
                                </div>
                                <div className="card card-sum">
                                    <span style={{ fontSize: "14px;" }}>Total</span>
                                    <span id="divSUM" style={{ fontSize: "20px", fontWeight: "bold" }}>{numberFormat(this.state.total)}</span>
                                </div>
                                <div className="card card-sum">
                                    <span style={{ fontSize: "14px;" }}>Diskon</span>
                                    <span id="divDiskon" style={{ fontSize: "20px", fontWeight: "bold" }}>{numberFormat(this.state.diskon)}</span>
                                </div>
                                <div className="card card-sum">
                                    <span style={{ fontSize: "14px;" }}>Tips</span>
                                    <span id="divTips" style={{ fontSize: "20px", fontWeight: "bold" }}>{numberFormat(this.state.tips)}</span>
                                </div>
                                <div className="card card-sum">
                                    <span style={{ fontSize: "14px;" }}>Grandtotal</span>
                                    <span id="divGT" style={{ fontSize: "20px", fontWeight: "bold" }}>{numberFormat(this.state.grandtotal)}</span>
                                </div>
                                <div className="card card-sum">
                                    <span style={{ fontSize: "14px;" }}>Jasa</span>
                                    <span id="divKomisi" style={{ fontSize: "20px", fontWeight: "bold" }}>{numberFormat(this.state.jasa)}</span>
                                </div>
                                <div className="card card-sum">
                                    <span style={{ fontSize: "14px;" }}>Produk</span>
                                    <span id="divKomisi" style={{ fontSize: "20px", fontWeight: "bold" }}>{numberFormat(this.state.produk)}</span>
                                </div>
                                <div className="card card-sum">
                                    <span style={{ fontSize: "14px;" }}>Komisi</span>
                                    <span id="divKomisi" style={{ fontSize: "20px", fontWeight: "bold" }}>{numberFormat(this.state.komisi)}</span>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive">
                            {
                                this.state.mode == "ringkasan" ?
                                    <DataGrid dataSource={this.state.DataRingkasan} showBorders={true} allowColumnResizing={true} columnAutoWidth={true} rowAlternationEnabled={true} style={{ width: "100%", minWidth: "1000px" }} onRowClick={(e) => this.modalDetail(e)}>
                                        <Column dataField="NameLocation" caption="Nama Cabang" wordWrap="normal" />
                                        <Column dataField="DocNumber" caption="No Order" wordWrap="normal" />
                                        <Column dataField="DocDate" caption="Tanggal" dataType="date" format="dd MMMM yyyy" />
                                        <Column dataField="NameAccount" caption="Pelanggan" />
                                        <Column dataField="NameOperator" caption="Barberman" />
                                        <Column dataField="NameKasir" caption="Kasir" />
                                        <Column dataField="Produk" caption="Produk" dataType="number" format={{ type: 'fixedPoint' }} />
                                        <Column dataField="Jasa" caption="Jasa" dataType="number" format={{ type: 'fixedPoint' }} />
                                        <Column dataField="Total" caption="Total" dataType="number" format={{ type: 'fixedPoint' }} />
                                        <Column dataField="Discount" caption="Diskon" wordWrap="normal" />
                                        <Column dataField="Tips" caption="Tips" dataType="number" format={{ type: 'fixedPoint' }} />
                                        <Column dataField="GrandTotal" caption="Grandtotal" dataType="number" format={{ type: 'fixedPoint' }} />
                                        <Column dataField="NamePayment" caption="Akun Pembayaran" />
                                        <Column dataField="Pembayaran" caption="Pembayaran" />
                                        <Paging defaultPageSize={50} />
                                        <FilterRow visible={true} />
                                        <HeaderFilter visible={true} />
                                        <GroupPanel visible={true} />
                                        <Grouping autoExpandAll={true} />
                                        <ColumnFixing enabled={true} />
                                        <Summary>
                                            <TotalItem column="DocNumber" summaryType="count" />
                                            <TotalItem column="Produk" summaryType="sum" sidplayFormay="Produk : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                            <TotalItem column="Jasa" summaryType="sum" sidplayFormay="Jasa : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                            <TotalItem column="Total" summaryType="sum" sidplayFormay="Total : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                            <TotalItem column="Discount" summaryType="sum" sidplayFormay="Disc : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                            <TotalItem column="Tips" summaryType="sum" sidplayFormay="Tips : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                            <TotalItem column="GrandTotal" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} />
                                            <GroupItem column="DocNumber" summaryType="count" displayFormat="{0} Transaksi" />
                                            <GroupItem column="Total" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Total : {0}" showInGroupFooter={false} alignByColumn={true} />
                                            <GroupItem column="VDiscount" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Disc : {0}" showInGroupFooter={false} alignByColumn={true} />
                                            <GroupItem column="Tips" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Tips : {0}" showInGroupFooter={false} alignByColumn={true} />
                                            <GroupItem column="GrandTotal" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="GrandTotal : {0}" showInGroupFooter={false} alignByColumn={true} />
                                        </Summary>
                                        <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                                    </DataGrid> :
                                    <DataGrid dataSource={this.state.DataDetail} showBorders={true} rowAlternationEnabled={true} style={{ width: "100%", minWidth: "1000px" }} allowColumnResizing={true} columnAutoWidth={true}>
                                        <Column dataField="NameLocation" caption="Nama Cabang" wordWrap="normal" />
                                        <Column dataField="DocNumber" caption="No Order" wordWrap="normal" />
                                        <Column dataField="DocDate" caption="Tanggal" dataType="date" format="dd MMMM yyyy" />
                                        <Column dataField="NameAccount" caption="Pelanggan" />
                                        <Column dataField="NameOperator" caption="Barberman" />
                                        <Column dataField="NameKasir" caption="Kasir" />
                                        <Column dataField="Produk" caption="Produk" />
                                        <Column dataField="Qty" caption="Qty" />
                                        <Column dataField="Price" caption="Harga" dataType="number" format={{ type: 'fixedPoint' }} />
                                        <Column dataField="Total" caption="Total" dataType="number" format={{ type: 'fixedPoint' }} />
                                        <Column dataField="Discount" caption="Diskon" wordWrap="normal" />
                                        <Column dataField="Tips" caption="Tips" dataType="number" format={{ type: 'fixedPoint' }} />
                                        <Column dataField="Grandtotal" caption="Grandtotal" dataType="number" format={{ type: 'fixedPoint' }} />
                                        <Column dataField="Komisi" caption="Komisi" dataType="number" format={{ type: 'fixedPoint' }} />
                                        <Column dataField="Pembayaran" caption="Pembayaran" />
                                        <Paging defaultPageSize={50} />
                                        <FilterRow visible={true} />
                                        <HeaderFilter visible={true} />
                                        <GroupPanel visible={true} />
                                        <Grouping visible={true} />
                                        <ColumnFixing enabled={true} />
                                        <Summary>
                                            <TotalItem column="DocNumber" summaryType="count" />
                                            <TotalItem column="Qty" summaryType="sum" sidplayFormay="Qty : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                            <TotalItem column="Total" summaryType="sum" sidplayFormay="Total : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                            <TotalItem column="Discount" summaryType="sum" sidplayFormay="Disc : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                            <TotalItem column="Grandtotal" summaryType="sum" sidplayFormay="Grandtotal : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                            <TotalItem column="Tips" summaryType="sum" sidplayFormay="Tips : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                            <TotalItem column="Komisi" summaryType="sum" sidplayFormay="Komisi : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                            <GroupItem column="DocNumber" summaryType="count" displayFormat="{0} Transaksi" />
                                            <GroupItem column="Total" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Total : {0}" showInGroupFooter={false} alignByColumn={true} />
                                            <GroupItem column="VDiscount" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Disc : {0}" showInGroupFooter={false} alignByColumn={true} />
                                            <GroupItem column="Tips" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Tips : {0}" showInGroupFooter={false} alignByColumn={true} />
                                            <GroupItem column="Grandtotal" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="GrandTotal : {0}" showInGroupFooter={false} alignByColumn={true} />
                                            <GroupItem column="Komisi" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Komisi : {0}" showInGroupFooter={false} alignByColumn={true} />
                                        </Summary>
                                        <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                                    </DataGrid>
                            }
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;