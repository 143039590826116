import React, { Fragment } from 'react';
import { rendTable, rendModal, api, submitForm, getCookie, loading, pesan, numberFormat } from "../modul";
import ReactDOM from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "Master Produk";
    document.title = "Master Produk";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameProduct",
                by: "DESC",
                category: [{ ID: "", NameCategory: "Semua Kategori" }],
                kategori: 0,
                DataOperator: [],
                DataOperatorProduct: []
            };
        }

        async componentDidMount() {
            let sql = await api("category_browse_web", { index: 1, search: "", searchcount: 100, sort: "NameCategory", by: "ASC" });
            let cat = this.state.category;
            for (let opt of sql.data) cat.push({ ID: opt.ID, NameCategory: opt.NameCategory });
            this.setState({ category: cat });
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            e.preventDefault();
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }

            setTimeout(() => {
                this.main();
            }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "product_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        main() {
            rendTable({
                url: "product_browse_web",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by, kategori: this.state.kategori },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi:
                        [
                            { type: "edit", event: (e) => rendForm(e) },
                            { type: "other", event: (e) => formLokasi(e), icon: "edit_location_alt" },
                            { type: "delete", event: (e) => this.modalDelete(e) },
                            { type: "other", icon: "settings", event: (e) => this.modalProduct(e) }
                        ],
                    id: "tbodyData"
                }
            });
        }

        async modalProduct(ini) {
            let sql = await api("detail_product_barberman", { IDProduct: ini.target.dataset.id });
            if (sql.status == "true" && sql.product.isHaircut == 1) {
                this.setState({ DataOperator: sql.operator, DataOperatorProduct: sql.data, IDProduct: ini.target.dataset.id });
                let modal = new window.bootstrap.Modal(document.getElementById("modalProduk"), {});
                modal.show("#modalProduk");
            } else {
                pesan("Info", "Produk bukan haircut", "warning");
            }
        }

        setProductBarberman(data) {
            let DataOperatorProduct = this.state.DataOperatorProduct;
            DataOperatorProduct.push({
                IDOperator: data.ID,
                CodeOperator: data.CodeOperator,
                Address: data.Address,
                NameOperator: data.NameOperator,
            });
            this.setState({ DataOperatorProduct: DataOperatorProduct });
        }

        handleDeleteProduct(i) {
            let data = this.state.DataOperatorProduct;
            let temp = [];
            for (let dd in data) if (dd != i) temp.push(data[dd]);
            this.setState({ DataOperatorProduct: temp });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Produk</button>
                            </div>
                            <div className="col-md-3"></div>
                            <div className="col-md-6" style={{ textAlign: "right" }}>
                                <div className="d-flex justify-content-center align-items-center gap-2">
                                    <select className="form-select form-select-sm" id="edtKategory" value={this.state.kategori} onChange={(e) => this.handleChange(e, "kategori")}>
                                        {
                                            this.state.category.map(opt => <option value={opt.ID}>{opt.NameCategory}</option>)
                                        }
                                    </select>
                                    <div className="input-group input-group-sm">
                                        <button className="btn btn-sm btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                        <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination"></nav>

                    <div className="modal fade" id="modalProduk" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <form onSubmit={(e) => submitForm(e, { act: "insert_product_barberman", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniFormProduct" })} id='iniFormProduct' className='needs-validation' noValidate>
                                    <div className="modal-header">
                                        <h1 className="modal-title fs-5" id="exampleModalLabel">Produk Barberman</h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <input type="hidden" name="Operator" value={JSON.stringify(this.state.DataOperatorProduct)} />
                                        <input type="hidden" name="UpdateBy" value={getCookie("ID")} />
                                        <input type="hidden" name="IDProduct" value={this.state.IDProduct} />
                                        <input type="hidden" name="NameProduct" value="" />
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="table-responsive">
                                                    <table className="table table-stripped">
                                                        <thead>
                                                            <tr>
                                                                <th>Kode</th>
                                                                <th>Nama</th>
                                                                <th>Alamat</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.DataOperator.map((tr, i) => {
                                                                    return (<tr key={i} onClick={() => this.setProductBarberman(tr)}>
                                                                        <td>{tr.CodeOperator}</td>
                                                                        <td>{tr.NameOperator}</td>
                                                                        <td>{tr.Address}</td>
                                                                    </tr>)
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <table className="table table-stripped">
                                                    <thead>
                                                        <tr>
                                                            <th>Kode</th>
                                                            <th>Nama</th>
                                                            <th>Alamat</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.DataOperatorProduct.map((tr, i) => {
                                                                return (<tr key={i}>
                                                                    <td>{tr.CodeOperator}</td>
                                                                    <td>{tr.NameOperator}</td>
                                                                    <td>{tr.Address}</td>
                                                                    <td>
                                                                        <button type='button' className='btn btn-opsi' onClick={(e) => this.handleDeleteProduct(i)}>
                                                                            <span className='material-icons'>delete</span>
                                                                        </button>
                                                                    </td>
                                                                </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" id="btnCloseModalmodalDelete" data-bs-dismiss="modal">Tutup</button>
                                        <button type="submit" className="btn btn-primary">Simpan</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

function formLokasi(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                dataLokasi: [],
                searchLokasi: []
            }
        }

        async componentDidMount() {
            let sql = await api("product_show_detail", { iddata: this.state.id });
            if (sql.status == "true") this.setState({ dataLokasi: sql.data });
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        async cariLokasi(e) {
            let sql = await api("location_browse", { searchcount: 100, search: e.target.value, index: 1 });
            this.setState({ searchLokasi: sql.data });
        }

        async setLocation(id, type = "product_show_insert") {
            let sql = await api(type, { lokasi: id, produk: this.state.id, idadmin: getCookie("ID") });
            if (sql.status == "true") {
                let prod = await api("product_show_detail", { iddata: this.state.id });
                if (prod.status == "true") {
                    this.setState({ dataLokasi: prod.data });
                } else {
                    this.setState({ dataLokasi: [] });
                }
            } else {
                pesan("Pemberitahuan", sql.message, "warning");
            }
        }

        render() {
            return (
                <Fragment>
                    <div className="form-group">
                        <label>Cari data lokasi</label>
                        <input type="search" className="form-control dropdown-toggle" placeholder="Cari data lokasi" onFocus={(e) => this.cariLokasi(e)} onChange={(e) => this.cariLokasi(e)} id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                        <ul className="dropdown-menu w-100" id="listLokasi" aria-labelledby="dropdownMenuButton1">
                            {this.state.searchLokasi.map((td) => <li><a className="dropdown-item" href="#" onClick={(e) => this.setLocation(td.ID)}>{td.NameLocation}</a></li>)}
                        </ul>
                    </div>
                    <p></p>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Nama Lokasi</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody id="tbodyLokasi">
                            {
                                this.state.dataLokasi.map((tr, i) => {
                                    return (
                                        <tr>
                                            <td>{tr.NameLocation}</td>
                                            <td><i className="material-icons icon-color" onClick={(e) => this.setLocation(tr.IDLocation, 'product_show_delete')}>delete</i></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Fragment>
            )
        }
    }

    rendModal({
        title: "Lokasi Produk",
        body: <Form />,
        id: "FormLokasi",
        cls: "modal-lg"
    });
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                code: "",
                name: "",
                kategori: "",
                kategoriid: "",
                harga: "",
                desc: "",
                commission: "",
                type: "",
                iskomisi: false,
                isHaircut: false
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("product_detail", { iddata: this.state.id, admin: getCookie("ID") });
                this.setState({
                    code: sql.data[0].CodeProduct,
                    name: sql.data[0].NameProduct,
                    kategori: sql.data[0].NameCategory,
                    kategoriid: sql.data[0].IDCategory,
                    harga: sql.data[0].Price,
                    desc: sql.data[0].Description,
                    commission: sql.data[0].Commission,
                    type: sql.data[0].ItemType,
                    iskomisi: sql.commision == 0 ? true : false,
                    isHaircut: sql.data[0].isHaircut
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        async scCategori(e) {
            this.setState({ kategori: e.target.value });
            const sql = await api("category_browse_web", { search: e.target.value, searchcount: 10, index: 1, sort: "NameCategory", by: "ASC" });
            const dom = sql.data.map((opt) =>
                <li><a className="dropdown-item" href="#" onClick={(e) => this.setCategori(opt.NameCategory, opt.ID)}>{opt.NameCategory}</a></li>
            )
            ReactDOM.render(dom, document.getElementById('ulKetegori'));
        }

        setCategori(nama, id) {
            this.setState({ kategori: nama, kategoriid: id });
        }

        render() {
            return (
                <Fragment>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Kode Produk</label>
                                <input type="text" name="code" className="form-control" id="edtCode" value={this.state.code} onChange={(e) => this.handleChange(e, "code")} required />
                                <div className="invalid-feedback">Silahkan Isi Code Produk</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Nama Produk</label>
                                <input type="text" name="name" className="form-control" id="edtNama" value={this.state.name} onChange={(e) => this.handleChange(e, "name")} required />
                                <div className="invalid-feedback">Silahkan Isi Nama Produk</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Harga</label>
                                <input type="number" name="price" className="form-control" id="edtHarga" value={this.state.harga} onChange={(e) => this.handleChange(e, "harga")} required />
                                <div className="invalid-feedback">Silahkan isikan harga produk</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Komisi</label>
                                <input type="number" name="commission" className="form-control" id="edtKomisi" value={this.state.commission} onChange={(e) => this.handleChange(e, "commission")} disabled={this.state.iskomisi} required />
                                <div className="invalid-feedback">Silahkan isikan komisi produk</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Kategori</label>
                                <input type="hidden" id="edtCategoryID" name="idcategory" value={this.state.kategoriid} />
                                <div className="dropdown">
                                    <input type="text" name="kategori" className="form-control" id="edtCategory" value={this.state.kategori} onChange={(e) => this.scCategori(e)} onMouseOver={(e) => this.scCategori(e)} data-bs-toggle="dropdown" aria-expanded="false" autocomplete="off" required />
                                    <div className="invalid-feedback">Silahkan pilih kategori Produk</div>
                                    <ul className="dropdown-menu w-100" id="ulKetegori" aria-labelledby="edtCategory"></ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Tipe</label>
                                <select name="itemtype" className="form-control" id="edtType" value={this.state.type} onChange={(e) => this.handleChange(e, "type")} required>
                                    <option value="0" id="type0">Barang</option>
                                    <option value="2" id="type2">Jasa</option>
                                </select>
                                <div className="invalid-feedback">Silahkan isikan harga produk</div>
                            </div>
                            <div className="form-check form-switch">
                                <input className="form-check-input" name="IsHaircut" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={(e) => this.setState({ isHaircut: e.target.checked == true ? 1 : 0 })} checked={this.state.isHaircut} />
                                <label className="form-check-label" for="flexSwitchCheckChecked">Tandai Sebagai Haircut</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Deskripsi</label>
                        <textarea name="desc" className="form-control" id="edtDeskripsi" value={this.state.desc} onChange={(e) => this.handleChange(e, "desc")}></textarea>
                    </div>
                </Fragment>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "product_edit" : "product_insert",
        title = id ? "Edit Produk" : "Tambah Produk";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form",
        cls: "modal-lg"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;