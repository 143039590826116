import React, { Fragment } from 'react';
import { rendTable, rendModal, api, submitForm, saiki, numberFormat, loading, tanggalIndo, getCookie } from "../modul";
import ReactDOM from 'react-dom';
import reactDom from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "Promo Birthday";
    document.title = "Promo Birthday";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "ID",
                by: "DESC"
            };
        }

        main() {
            rendTable({
                url: "promo_birthday_browse_web",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi:
                        [
                            { type: "edit", event: (e) => rendForm(e) },
                            { type: "delete", event: (e) => this.modalDelete(e) }
                        ],
                    id: "tbodyData"
                }
            });
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <>
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "promo_birthday_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Promo Birthday</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                        </div>
                    </div>
                    <nav id="paggination"></nav>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                code: "",
                name: "",
                IDProduct: "",
                NameProduct: "",
                idpelanggan: "",
                namapelanggan: "",
                idlocation: "",
                namelocation: "",
                qtyrequirement: "1",
                Amount: 0,
                TypePromo: "price",
                TypeGroup: "product"
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("promo_birthday_detail", { iddata: this.state.id });
                this.setState({
                    IDProduct: sql.data[0].IDProduct,
                    NameProduct: sql.data[0].NameProduct,
                    TypePromo: sql.data[0].TypePromo,
                    Amount: sql.data[0].Amount,
                    TypeGroup: sql.data[0].TypeGroup
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        async scLokasi(e) {
            this.setState({ namaproduct: e.target.value });
            const sql = await api("product_browse_web", { search: e.target.value, searchcount: 100, index: 1, sort: "NameProduct", by: "ASC" });
            const dom = sql.data.map((opt) =>
                <li><a class="dropdown-item" href="#" onClick={(e) => this.setLokasi(opt.NameProduct, opt.ID)}>{opt.NameProduct}</a></li>
            )
            ReactDOM.render(dom, document.getElementById('ulProduct'));
        }

        setLokasi(nama, id) {
            document.getElementById('edtProduct').value = nama;
            document.getElementById('edtProductID').value = id;
        }

        setType(e, on = "focus", ssts) {
            if (on == "focus") {
                e.target.value = this.state[ssts];
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state[ssts]);
            }
        }

        modalPelanggan() {
            let dom =
                <Fragment>
                    <input type="search" className='form-control' id="edtCariPelanggan" placeholder={'Cari Nama Pelanggan'} />
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Nama</th>
                                <th>Telp</th>
                                <th>Email</th>
                                <th>Alamat</th>
                            </tr>
                        </thead>
                        <tbody id="tbodyPelanggan"></tbody>
                    </table>
                </Fragment>;

            rendModal({
                title: "Cari data Pelanggan",
                body: dom,
                id: "modalPelanggan",
                cls: "modal-lg"
            });

            document.getElementById('edtCariPelanggan').addEventListener("keyup", async function () {
                let sql = await api("customer_browse_web", { search: this.value, searchcount: 100, index: 1, sort: "NameCustomer", by: "ASC" });
                let td = [];
                sql.data.map(tr =>
                    td.push(<tr onClick={(e) => pilihPelanggan(e, tr.ID, tr.NameCustomer)}>
                        <td>{tr.NameCustomer}</td>
                        <td>{tr.Telp}</td>
                        <td>{tr.Email}</td>
                        <td>{tr.Address}</td>
                    </tr>)
                );

                reactDom.render(td, document.getElementById('tbodyPelanggan'));

                function pilihPelanggan(e, id, nama) {
                    document.getElementById('edtPelangganID').value = id;
                    document.getElementById('edtPelanggan').value = nama;
                    document.getElementById('btnCloseModalmodalPelanggan').click();
                }
            });
        }

        modalLokasi() {
            let dom =
                <Fragment>
                    <input type="search" className='form-control' id="edtCariLokasi" placeholder={'Cari Nama Lokasi / Cabang'} />
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Kode</th>
                                <th>Nama</th>
                            </tr>
                        </thead>
                        <tbody id="tbodyLokasi"></tbody>
                    </table>
                </Fragment>;

            rendModal({
                title: "Cari data Cabang",
                body: dom,
                id: "modalCabang",
                cls: "modal-lg"
            });

            document.getElementById('edtCariLokasi').addEventListener("keyup", async function () {
                let sql = await api("location_browse_web", { search: this.value, searchcount: 100, index: 1, sort: "NameLocation", by: "ASC" });
                let td = [];
                sql.data.map(tr =>
                    td.push(<tr onClick={(e) => pilihLocation(e, tr.ID, tr.NameLocation)}>
                        <td>{tr.Code}</td>
                        <td>{tr.NameLocation}</td>
                    </tr>)
                );

                reactDom.render(td, document.getElementById('tbodyLokasi'));

                function pilihLocation(e, id, nama) {
                    document.getElementById('edtLocationID').value = id;
                    document.getElementById('edtLocation').value = nama;
                    document.getElementById('btnCloseModalmodalCabang').click();
                }
            });
        }

        pilihLocation(e, id, nama) {
            this.setState({ idlocation: id, namalocation: nama });
        }

        modalCari(e) {
            let type = this.state.TypeGroup;
            let thead = <tr>
                <th>Nama Produk</th>
                <th>Harga</th>
            </tr>;

            if (type == "category") {
                thead = <tr>
                    <th>Kode</th>
                    <th>Nama Kategori</th>
                </tr>;
            }
            rendModal({
                title: "Cari " + this.state.TypeGroup,
                cls: "modal-lg",
                id: "modalCari",
                body: <Fragment>
                    <input type="search" class="form-control form-control-sm" onChange={(e) => this.cariProduct(e.target.value, type)} />
                    <p></p>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                {
                                    thead
                                }
                            </thead>
                            <tbody id="tbodyCariProduk"></tbody>
                        </table>
                    </div>
                </Fragment>
            });

            this.cariProduct("", type);
        }

        async cariProduct(val, type) {
            const sql = await api(type == "product" ? "product_browse" : "category_browse", { search: val, index: 1, searchcount: 10 });
            let dom = sql.data.map(opt =>
                <tr style={{ cursor: "pointer" }} onClick={() => this.pilihProduct(opt.ID, opt.NameProduct)}>
                    <td>{opt.NameProduct}</td>
                    <td>{numberFormat(opt.Price)}</td>
                </tr>
            )
            if (type == "category") {
                dom = sql.data.map(opt =>
                    <tr style={{ cursor: "pointer" }} onClick={() => this.pilihProduct(opt.ID, opt.NameCategory)}>
                        <td>{opt.CodeCategory}</td>
                        <td>{opt.NameCategory}</td>
                    </tr>
                )
            }
            ReactDOM.render(dom, document.getElementById('tbodyCariProduk'));
        }

        pilihProduct(id, name) {
            this.setState({ IDProduct: id, NameProduct: name });
            document.getElementById('btnCloseModalmodalCari').click();
        }

        render() {
            return (
                <Fragment>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <div class="row">
                        <div class="col-md-6 mb-2"></div>
                        <div class="col-md-6 mb-2"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div class="form-group">
                                <label>Type Promo</label>
                                <select className='form-select' name="TypePromo" value={this.state.TypePromo} onChange={(e) => this.setState({ TypePromo: e.target.value })}>
                                    <option value="price">Price</option>
                                    <option value="percentage">Percentage</option>
                                </select>
                                <div class="invalid-feedback">Silahakan tentukan Type Promo</div>
                            </div>
                        </div>
                        <div class="col-md-6 mb-2">
                            <div class="form-group">
                                <label>Nilai</label>
                                <input type="number" name="Amount" class="form-control" value={this.state.Amount} onChange={(e) => this.setState({ Amount: e.target.value })} required />
                                <div class="invalid-feedback">Silahkan masukan nilai promo</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <div className='form-group'>
                                <label>Tipe Produk</label>
                                <select className='form-select' value={this.state.TypeGroup} name="TypeGroup" onChange={(e) => this.setState({ TypeGroup: e.target.value, IDProduct: "", NameProduct: "" })}>
                                    <option value="product">Produk</option>
                                    <option value="category">Kategori Produk</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6 mb-2">
                            <div class="form-group">
                                <label>{this.state.TypeGroup == "product" ? "Produk" : "Kategori"}</label>
                                <input type="hidden" name="IDProduct" id="edtProductID" value={this.state.IDProduct} />
                                <input type="text" name="namaproduct" class="form-control" id="edtProduct" value={this.state.NameProduct} onFocus={(e) => this.modalCari()} required />
                                <div class="invalid-feedback">Silahkan pilih produk</div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "promo_birthday_edit" : "promo_birthday_insert",
        title = id ? "Edit Promo Brithday" : "Tambah Promo Brithday";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;