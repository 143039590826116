import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { rendTable, rendModal, api, saiki, submitForm, getCookie, numberFormat, loading, tanggalIndo, pesan } from '../modul';

function App() {
    document.getElementById("lblHeader").innerHTML = "Master Promo Member";
    document.title = "Master Promosi";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NamePromo",
                by: "DESC",
                datestart: saiki(),
                dateend: saiki(),
                idlocation: localStorage.getItem("location")
            };
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            setTimeout(() => {
                this.main();
            }, 300);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                const sort = this.state.by == "ASC" ? "DESC" : "ASC";
                this.setState({ by: sort });
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        async modalDelete(e) {
            const dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={e.target.dataset.id} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "promo_member_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async main() {
            let sql = await api("promo_member_browse_web", {
                search: this.state.search,
                searchcount: this.state.searchcount,
                index: this.state.index,
                sort: this.state.sort,
                by: this.state.by,
                idadmin: getCookie("ID"),
                d1: this.state.datestart,
                d2: this.state.dateend,
                idlocation: this.state.idlocation,
            });

            rendTable({
                dataType: "url",
                dataset: sql,
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi: [
                        { type: "edit", event: (e) => rendForm(e) },
                        { type: "delete", event: (e) => this.modalDelete(e) }
                    ],
                    id: "tbodyData"
                }
            });
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={(e) => rendForm(e)}>Tambah Promo</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination"></nav>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                __ID: getCookie("ID"),
                CodePromo: "",
                NamePromo: "",
                IDProduct: "",
                NameProduct: "",
                TypePromo: "price",
                TypeGroup: "product",
                Amount: 0,
                DateStart: saiki(),
                DateExpired: saiki(),
                Pelanggan: [],
                dataPelanggan: []
            }
        }

        async componentDidMount() {
            this.handleCariPelanggan("");
            if (this.state.id !== "") {
                const sql = await api("promo_member_detail", { iddata: this.state.id });
                this.setState({
                    CodePromo: sql.data.CodePromo,
                    NamePromo: sql.data.NamePromo,
                    IDProduct: sql.data.IDProduct,
                    NameProduct: sql.data.NameProduct,
                    TypePromo: sql.data.TypePromo,
                    TypeGroup: sql.data.TypeGroup,
                    Amount: sql.data.Amount,
                    DateStart: sql.data.DateStart,
                    DateExpired: sql.data.DateExpired,
                    Pelanggan: sql.detail
                });
            }
            setTimeout(() => {
                document.getElementById('edtD1').value = tanggalIndo(this.state.DateStart);
                document.getElementById('edtD2').value = tanggalIndo(this.state.DateExpired);
            }, 200);
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        setType(e, on = "focus", ssts) {
            if (on == "focus") {
                e.target.value = this.state[ssts];
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state[ssts]);
            }
        }

        modalCari(e) {
            let type = this.state.TypeGroup;
            let thead = <tr>
                <th>Nama Produk</th>
                <th>Harga</th>
            </tr>;

            if (type == "category") {
                thead = <tr>
                    <th>Kode</th>
                    <th>Nama Kategori</th>
                </tr>;
            }
            rendModal({
                title: "Cari " + this.state.TypeGroup,
                cls: "modal-lg",
                id: "modalCari",
                body: <Fragment>
                    <input type="search" class="form-control form-control-sm" onChange={(e) => this.cariProduct(e.target.value, type)} />
                    <p></p>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                {
                                    thead
                                }
                            </thead>
                            <tbody id="tbodyCariProduk"></tbody>
                        </table>
                    </div>
                </Fragment>
            });

            this.cariProduct("", type);
        }

        async cariProduct(val, type) {
            const sql = await api(type == "product" ? "product_browse" : "category_browse", { search: val, index: 1, searchcount: 10 });
            let dom = sql.data.map(opt =>
                <tr style={{ cursor: "pointer" }} onClick={() => this.pilihProduct(opt.ID, opt.NameProduct)}>
                    <td>{opt.NameProduct}</td>
                    <td>{numberFormat(opt.Price)}</td>
                </tr>
            )
            if (type == "category") {
                dom = sql.data.map(opt =>
                    <tr style={{ cursor: "pointer" }} onClick={() => this.pilihProduct(opt.ID, opt.NameCategory)}>
                        <td>{opt.CodeCategory}</td>
                        <td>{opt.NameCategory}</td>
                    </tr>
                )
            }
            ReactDOM.render(dom, document.getElementById('tbodyCariProduk'));
        }

        pilihProduct(id, name) {
            this.setState({ IDProduct: id, NameProduct: name });
            document.getElementById('btnCloseModalmodalCari').click();
        }

        async modalPelanggan() {
            let dom =
                <Fragment>
                    <input type="search" className='form-control' id="edtCariPelanggan" placeholder={'Cari Nama Pelanggan'} />
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Nama</th>
                                <th>Telp</th>
                                <th>Email</th>
                                <th>Alamat</th>
                            </tr>
                        </thead>
                        <tbody id="tbodyPelanggan"></tbody>
                    </table>
                </Fragment>;

            rendModal({
                title: "Cari data Pelanggan",
                body: dom,
                id: "modalPelanggan",
                cls: "modal-lg"
            });

            let ini = this;

            let sql = await api("customer_browse_web", { search: "", searchcount: 100, index: 1, sort: "NameCustomer", by: "ASC" });
            let td = [];
            sql.data.map(tr =>
                td.push(
                    <tr onClick={(e) => ini.pilihPelanggan(e, tr.ID, tr.NameCustomer, tr.Telp, tr.Address)}>
                        <td>{tr.NameCustomer}</td>
                        <td>{tr.Telp}</td>
                        <td>{tr.Email}</td>
                        <td>{tr.Address}</td>
                    </tr>)
            );

            ReactDOM.render(td, document.getElementById('tbodyPelanggan'));

            document.getElementById('edtCariPelanggan').addEventListener("keyup", async function () {
                let sql = await api("customer_browse_web", { search: this.value, searchcount: 50, index: 1, sort: "NameCustomer", by: "ASC" });
                let td = [];
                sql.data.map(tr =>
                    td.push(
                        <tr onClick={(e) => ini.pilihPelanggan(e, tr.ID, tr.NameCustomer, tr.Telp, tr.Address)}>
                            <td>{tr.NameCustomer}</td>
                            <td>{tr.Telp}</td>
                            <td>{tr.Email}</td>
                            <td>{tr.Address}</td>
                        </tr>)
                );

                ReactDOM.render(td, document.getElementById('tbodyPelanggan'));
            });
        }

        async handleCariPelanggan(val) {
            let sql = await api("customer_browse", { search: val, searchcount: 100, index: 1, sort: "NameCustomer", by: "ASC" });
            if (sql.status == "true") this.setState({ dataPelanggan: sql.data });
        }

        pilihPelanggan(e, id, nama, telp, address) {
            let data = this.state.Pelanggan;
            let tempID = [];
            for (let dd of data) tempID.push(dd.IDCustomer);
            if (!tempID.includes(id)) {
                data.push({ IDCustomer: id, NameCustomer: nama, Telp: telp, Address: address });
                setTimeout(() => {
                    this.setState({ Pelanggan: data });
                }, 200);
            } else {
                alert("Pelanggan Sudah dipilih");
            }
        }

        handleDeleteCustomer(i) {
            let data = this.state.Pelanggan;
            let tmpData = [];
            for (let idx in data) if (i != idx) tmpData.push(data[idx]);
            this.setState({ Pelanggan: tmpData });
        }

        render() {
            return (
                <Fragment>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={this.state.__ID} />
                    <input type="hidden" name="DateStart" value={this.state.DateStart} />
                    <input type="hidden" name="DateExpired" value={this.state.DateExpired} />
                    <input type="hidden" name="Pelanggan" value={JSON.stringify(this.state.Pelanggan)} />
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Kode Promosi</label>
                                <input type="text" name="CodePromo" className="form-control" id="edtCode" value={this.state.CodePromo} onChange={(e) => this.handleChange(e, "CodePromo")} required />
                                <div className="invalid-feedback">Silahakan isi code promosi</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Nama Promosi</label>
                                <input type="text" name="NamePromo" className="form-control" id="edtNama" value={this.state.NamePromo} onChange={(e) => this.handleChange(e, "NamePromo")} required />
                                <div className="invalid-feedback">Silahakan isi code promosi</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Tanggal Mulai</label>
                                <input type="text" className="form-control form-date" id="edtD1" onChange={(e) => this.handleChange(e, "DateStart")} onMouseOver={(e) => this.setType(e, 'focus', 'DateStart')} onBlur={(e) => this.setType(e, "blur", 'DateStart')} required />
                                <div className="invalid-feedback">Silahkan pilih tanggal mulai</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Tanggal Expired</label>
                                <input type="text" className="form-control form-date" id="edtD2" onChange={(e) => this.handleChange(e, "DateExpired")} onMouseOver={(e) => this.setType(e, 'focus', 'DateExpired')} onBlur={(e) => this.setType(e, "blur", 'DateExpired')} required />
                                <div className="invalid-feedback">Silahkan pilih tanggal expired</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className='form-group'>
                                <label>Tipe Promo</label>
                                <select className='form-select' value={this.state.TypePromo} name="TypePromo" onChange={(e) => this.setState({ TypePromo: e.target.value })}>
                                    <option value="price">Harga</option>
                                    <option value="percentage">Persen</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Nilai Promosi</label>
                                <input type="number" name="Amount" className="form-control" id="edtPrice" value={this.state.Amount} onChange={(e) => this.handleChange(e, "Amount")} required />
                                <div className="invalid-feedback">Silahkan isi jumlah syarat</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <label>Tipe Produk</label>
                            <select className='form-select' value={this.state.TypeGroup} name="TypeGroup" onChange={(e) => this.setState({ TypeGroup: e.target.value, IDProduct: "", NameProduct: "" })}>
                                <option value="product">Produk</option>
                                <option value="category">Kategori Produk</option>
                            </select>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>{this.state.TypeGroup}</label>
                                <input type="hidden" name="IDProduct" id="edtProductID" value={this.state.IDProduct} />
                                <input type="text" name="NameProduct" className="form-control" id="edtProduct" value={this.state.NameProduct} onFocus={(e) => this.modalCari(e)} required />
                                <div className="invalid-feedback">Silahkan pilih produk</div>
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-default' type='button' onClick={() => this.modalPelanggan()}>Tambah Pelanggan</button>
                    <p></p>
                    <div className='row'>
                        <div className='col-md-6 mb-2'>
                            <input type="search" className='form-control form-control-sm' placeholder="Cari nama pelanggan" onChange={(e) => this.handleCariPelanggan(e.target.value)} />
                            <div className='table-responsive' style={{ maxHeight: "300px" }}>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Nama</th>
                                            <th>Telp</th>
                                            <th>Email</th>
                                            <th>Alamat</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.dataPelanggan.map((tr, i) => {
                                                return (
                                                    <tr onClick={(e) => this.pilihPelanggan(e, tr.ID, tr.NameCustomer, tr.Telp, tr.Address)}>
                                                        <td>{tr.NameCustomer}</td>
                                                        <td>{tr.Telp}</td>
                                                        <td>{tr.Email}</td>
                                                        <td>{tr.Address}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='col-md-6 mb-2'>
                            <h6>Pelanggan Terpilih</h6>
                            <div className='table-responsive' style={{ maxHeight: "300px" }}>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Nama</th>
                                            <th>Telp</th>
                                            <th>Alamat</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.Pelanggan.map((item, i) => {
                                                return (
                                                    <tr>
                                                        <td>{item.NameCustomer}</td>
                                                        <td>{item.Telp}</td>
                                                        <td>{item.Address}</td>
                                                        <td>
                                                            <button type='button' className='btn' onClick={() => this.handleDeleteCustomer(i)}>
                                                                <span className='material-icons'>delete</span>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "promo_member_edit" : "promo_member_insert",
        title = id ? "Edit Promo Member" : "Tambah Promo Member";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form",
        cls: "modal-xl"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;