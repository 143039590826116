import React, { Fragment } from 'react';
import DataGrid, { Column, Pager, Paging, FilterRow, HeaderFilter, ColumnFixing, Grouping, GroupPanel, Summary, TotalItem, GroupItem, SortByGroupSummaryInfo } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import ReactDOM from 'react-dom';
import { rendTable, saiki, loading, tanggalIndo, api, exportData, cekStatus, getCookie } from '../modul';

function App() {
    document.getElementById("lblHeader").innerHTML = "Transaksi Penukaran Poin";
    document.title = "Transaksi Penukaran Poin";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "ID",
                by: "DESC",
                datestart: saiki(),
                dateend: saiki(),
                idlocation: localStorage.getItem("location"),
                Data: [],
                Field: []
            };
        }

        componentDidMount() {
            if (sessionStorage.getItem("datestart")) this.setState({ datestart: sessionStorage.getItem("datestart") });
            if (sessionStorage.getItem("dateend")) this.setState({ dateend: sessionStorage.getItem("dateend") });
            setTimeout(() => {
                document.getElementById('edtD1').value = tanggalIndo(this.state.datestart);
                document.getElementById('edtD2').value = tanggalIndo(this.state.dateend);
                this.main();
                loading();
            }, 200);
        }

        async main() {
            let sql = await api("penukaran_poin_report_web", { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by, idadmin: getCookie("ID"), d1: this.state.datestart, d2: this.state.dateend, idlocation: this.state.idlocation });
            if (sql.status == "true") this.setState({ Data: sql.data, Field: sql.field.thead });
            // rendTable({
            //     url: "penukaran_poin_report_web",
            //     param: {
            //         search: this.state.search,
            //         searchcount: this.state.searchcount,
            //         index: this.state.index,
            //         sort: this.state.sort,
            //         by: this.state.by,
            //         idadmin: getCookie("ID"),
            //         d1: this.state.datestart,
            //         d2: this.state.dateend,
            //         idlocation: this.state.idlocation
            //     },
            //     id: "iniTable",
            //     div: "divView",
            //     sort: (e) => this.handleSort(e),
            //     paggination: { event: (e) => this.handlePage(e), index: this.state.index },
            //     table: { cls: "table table-striped" },
            //     thead: { cls: "color-bg-biru" },
            //     tbody: { id: "tbodyData" }
            // });
        }

        handleChange(e, ssts, fn = false) {
            this.setState({ [ssts]: e.target.value });
            sessionStorage.setItem([ssts], e.target.value);
            if (fn != false) {
                setTimeout(() => {
                    this.main();
                }, 200);
            }
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        setType(e, on = "focus", ssts) {
            if (on == "focus") {
                e.target.value = this.state[ssts];
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state[ssts]);
            }
        }

        async handleDownload(e) {
            e.target.innerHTML = `<span className="material-icons">hourglass_bottom</span>`;
            e.target.disabled = true;
            let url = "penukaran_poin_report_web";
            let param = {
                search: this.state.search,
                searchcount: 99999,
                index: this.state.index,
                sort: this.state.sort,
                by: this.state.by,
                idadmin: getCookie("ID"),
                d1: this.state.datestart,
                d2: this.state.dateend,
                idlocation: this.state.idlocation
            }
            let sql = await api(url, param);
            exportData(sql, `Data Transaksi Penukaran Poin ${tanggalIndo(this.state.d1)} - ${tanggalIndo(this.state.d2)}`);
            setTimeout(() => {
                e.target.innerHTML = `<span className="material-icons">file_download</span>`;
                e.target.disabled = false;
            }, 2000);
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="d-flex justify-content-start align-items-center gap-2">
                            <h5>Periode</h5>
                            <div className="input-group input-group-sm gap-2">
                                <input type="text" name="edtD1" id="edtD1" style={{ maxWidth: "120px" }} className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "datestart")} onMouseOver={(e) => this.setType(e, 'focus', 'datestart')} onBlur={(e) => this.setType(e, "blur", 'datestart')} />
                                <input type="text" name="edtD2" id="edtD2" style={{ maxWidth: "120px" }} className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "dateend")} onMouseOver={(e) => this.setType(e, 'focus', 'dateend')} onBlur={(e) => this.setType(e, "blur", 'dateend')} />
                                <button className="btn btn-sm btn-default" onClick={(e) => this.main()}><i className="material-icons">search</i></button>
                                <button className="btn btn-sm btn-default" id="btnExport" onClick={(e) => this.handleDownload(e)}><span className="material-icons">file_download</span></button>
                            </div>
                        </div>
                        <p></p>
                        <div className="d-flex justify-content-start align-items-center gap-3">
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                            <DataGrid dataSource={this.state.Data} showBorders={true} allowColumnResizing={true} columnAutoWidth={true} rowAlternationEnabled={true} style={{ width: "100%", minWidth: "1000px" }}>
                                {
                                    this.state.Field.map((tr, ii) => {
                                        if (tr.type == "number") {
                                            return <Column dataField={tr.sort} caption={tr.cap} dataType="number" format={{ type: 'fixedPoint' }} key={ii} />
                                        } else if (tr.type == "date") {
                                            return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy" key={ii} />
                                        } else if (tr.type == "datetime") {
                                            return <Column dataField={tr.sort} caption={tr.cap} dataType="date" format="dd-MM-yyyy H:i:s" key={ii} />
                                        } else {
                                            return <Column dataField={tr.sort} caption={tr.cap} key={ii} />
                                        }
                                    })
                                }
                                <Paging defaultPageSize={50} />
                                <FilterRow visible={true} />
                                <HeaderFilter visible={true} />
                                <GroupPanel visible={true} />
                                <Grouping autoExpandAll={true} />
                                <ColumnFixing enabled={true} />
                                <Summary>
                                    <TotalItem column="ID" summaryType="count" />
                                    <TotalItem column="Qty" summaryType="sum" displayFormat="Total Qty : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                    <TotalItem column="Price" summaryType="sum" displayFormat="Total Price : {0}" valueFormat={{ type: 'fixedPoint' }} />
                                    <TotalItem column="Poin" summaryType="sum" displayFormat="Total Point : {0}" valueFormat={{ type: 'fixedPoint' }} />

                                    <GroupItem column="Qty" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Total Qty : {0}" showInGroupFooter={false} alignByColumn={true} />
                                    <GroupItem column="Price" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Total Price : {0}" showInGroupFooter={false} alignByColumn={true} />
                                    <GroupItem column="Poin" summaryType="sum" valueFormat={{ type: 'fixedPoint' }} displayFormat="Total : {0}" showInGroupFooter={false} alignByColumn={true} />
                                </Summary>
                                <Pager allowedPageSizes={[20, 50, 100, 150]} showPageSizeSelector={true} />
                            </DataGrid>
                        </div>
                    </div>
                    <nav id="paggination"></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;