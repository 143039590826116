import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { rendTable, rendModal, api, saiki, submitForm, getCookie } from '../modul';

function App() {
    document.getElementById("lblHeader").innerHTML = "Master Kasir";
    document.title = "Master Kasir";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameKasir",
                by: "DESC",
                datestart: saiki(),
                dateend: saiki(),
                idlocation: localStorage.getItem("location")
            };
        }

        componentDidMount() {
            this.main();
            document.getElementById('loading').style.display = "none";
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                const sort = this.state.by == "ASC" ? "DESC" : "ASC";
                this.setState({ by: sort });
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        async modalDelete(e) {
            const dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={e.target.dataset.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "kasir_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async main() {
            let sql = await api("kasir_browse_web", {
                search: this.state.search,
                searchcount: this.state.searchcount,
                index: this.state.index,
                sort: this.state.sort,
                by: this.state.by,
                idadmin: 1,
                d1: this.state.datestart,
                d2: this.state.dateend,
                idlocation: this.state.idlocation,
            });

            rendTable({
                dataType: "url",
                dataset: sql,
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi: [
                        { type: "edit", event: (e) => rendForm(e) },
                        { type: "delete", event: (e) => this.modalDelete(e) }
                    ],
                    id: "tbodyData"
                }
            });
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={(e) => rendForm(e)}>Tambah Kasir</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination"></nav>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}


function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                __ID: getCookie("ID"),
                code: "",
                name: "",
                desc: "",
                telp: "",
                address: "",
                username: "",
                pwd: "",
                idlocation: "",
                namelocation: "",
                menukasir: [],
                tempMenu: []
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                const sql = await api("kasir_detail", { iddata: this.state.id });
                this.setState({
                    code: sql.data[0].CodeKasir,
                    name: sql.data[0].NameKasir,
                    desc: sql.data[0].Description,
                    telp: sql.data[0].Telp,
                    address: sql.data[0].Address,
                    username: sql.data[0].Username,
                    pwd: "",
                    idlocation: sql.data[0].IDLocation,
                    namelocation: sql.data[0].NameLocation
                });
            }
            const sql = await api("kasir_listmenu_web", { iddata: this.state.id });
            let arrLok = [];
            let arrTempMenu = [];
            for (let MM of sql.data) if (MM.GroupID == 0 && MM.IsGroup == 0 && MM.IsMobile == 1) arrLok.push({ ID: MM.FormCode, Name: MM.FormName, isLock: MM.isLock });
            this.setState({ menukasir: arrLok });
            for (let MM of this.state.menukasir) if (MM.isLock == 1) arrTempMenu.push({ FormCode: MM.ID, isLock: MM.isLock });
            this.setState({ tempMenu: arrTempMenu });
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleChk(e, i, id, name) {
            let myjsonobj = this.state.menukasir;
            if (e.target.checked == false) {
                myjsonobj[i].isLock = 0;
                this.setState({ menukasir: myjsonobj });
            } else {
                myjsonobj[i].isLock = 1;
                this.setState({ menukasir: myjsonobj });
            }
            let arrTempMenu = [];
            for (let MM of this.state.menukasir) if (MM.isLock == 1) arrTempMenu.push({ FormCode: MM.ID, isLock: MM.isLock });
            this.setState({ tempMenu: arrTempMenu });
        }

        async scLokasi(e) {
            this.setState({ namelocation: e.target.value });
            const sql = await api("location_browse", { search: e.target.value, searchcount: 100, index: 1 });
            const dom = sql.data.map((opt) =>
                <li><a class="dropdown-item" href="#" onClick={(e) => this.setLokasi(opt.NameLocation, opt.ID)}>{opt.NameLocation}</a></li>
            )
            ReactDOM.render(dom, document.getElementById('ulLokasi'));
        }

        setLokasi(nama, id) {
            document.getElementById('edtLokasi').value = nama;
            document.getElementById('edtLokasiID').value = id;
        }

        render() {
            return (
                <>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={this.state.__ID} />
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Kode Kasir</label>
                                        <input type="text" name="code" className="form-control" id="edtCode" value={this.state.code} onChange={(e) => this.handleChange(e, "code")} required />
                                        <div className="invalid-feedback">Silahkan isi kode kasir</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Nama Kasir</label>
                                        <input type="text" name="name" className="form-control" id="edtName" value={this.state.name} onChange={(e) => this.handleChange(e, "name")} required />
                                        <div className="invalid-feedback">Silahkan isi nama kasir</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Lokasi</label>
                                        <input type="hidden" name="idlocation" id="edtLokasiID" value={this.state.idlocation} />
                                        <div className="dropdown">
                                            <input type="text" name="lokasi" className="form-control" id="edtLokasi" value={this.state.namelocation} onChange={(e) => this.scLokasi(e)} onFocus={(e) => this.scLokasi(e)} data-bs-toggle="dropdown" aria-expanded="false" autocomplete="off" required />
                                            <div className="invalid-feedback">Silahkan pilih lokasi</div>
                                            <ul className="dropdown-menu w-100" id="ulLokasi" aria-labelledby="edtLokasi"></ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Telp</label>
                                        <input type="number" name="telp" className="form-control" id="edtTelp" value={this.state.telp} onChange={(e) => this.handleChange(e, "telp")} required />
                                        <div className="invalid-feedback">Silahkan isi telp</div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Username</label>
                                        <input type="text" name="username" className="form-control" id="edtUsername" value={this.state.username} onChange={(e) => this.handleChange(e, "username")} required />
                                        <div className="invalid-feedback">Silahkan isi username</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Password</label>
                                        <input type="password" name="pwd" className="form-control" id="edtPwd" readOnly={this.state.id == "" ? false : true} />
                                        <div className="invalid-feedback">Silahkan isi password</div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Deskripsi</label>
                                <textarea name="desc" className="form-control" id="edtDesc" value={this.state.desc} onChange={(e) => this.handleChange(e, "desc")}></textarea>
                            </div>
                            <textarea name="address" style={{ display: "none" }} id="edtAlamat" value={this.state.address} onChange={(e) => this.handleChange(e, "address")}></textarea>
                        </div>
                        <div className="col-md-6 mb-2">
                            <h4>Akses Menu</h4>
                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                {
                                    this.state.menukasir.map((MM, i) =>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input chk-menu" type="checkbox" onChange={(e) => this.handleChk(e, i, MM.ID, MM.Name)} id={"flexSwitchCheck" + MM.ID} checked={MM.isLock} />
                                            <label class="form-check-label" for={"flexSwitchCheck" + MM.ID}>{MM.Name}</label>
                                        </div>)
                                }
                            </div>
                        </div>
                        <textarea name="datasetmenu" style={{ display: "none" }} id="edtMenu" value={JSON.stringify(this.state.tempMenu)}></textarea>
                    </div>
                </>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "kasir_edit" : "kasir_insert",
        title = id ? "Edit Kasir" : "Tambah Kasir";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form",
        cls: "modal-xl"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;