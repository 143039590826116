import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { numberFormat, api, saiki, tanggalIndo, rowspan, exportData, rendModal, submitForm, loading, rendPagginations, cekStatus, getCookie } from '../modul';

function App() {
    document.getElementById("lblHeader").innerHTML = "Transaksi Penjualan Franchise";
    document.title = "Transaksi Penjualan Franchise";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "DocNumber",
                by: "ASC",
                mode: "ringkasan",
                url: "transaction_browse_web",
                filteridbarberman: "all",
                modeview: "transaction",
                d1: saiki(),
                d2: saiki(),
                idlocation: localStorage.getItem("location"),
                arrBarberan: [],
                disBarberman: false,
                field: [],
                data: [],
                pay: [],
                td: [],
                nota: 0,
                total: 0,
                diskon: 0,
                tips: 0,
                grandtotal: 0,
                komisi: 0
            };
        }

        async componentDidMount() {
            if (sessionStorage.getItem("d1")) this.setState({ d1: sessionStorage.getItem("d1") });
            if (sessionStorage.getItem("d2")) this.setState({ d2: sessionStorage.getItem("d2") });
            if (sessionStorage.getItem("bln")) this.setState({ bln: sessionStorage.getItem("bln") });
            let sql = await api("operator_browse_web", { searchcount: 100 });
            let arrTmp = [{ ID: "0", NameOperator: "Semua Barberman" }];
            for (let i of sql.data) arrTmp.push({ ID: i.ID, NameOperator: i.NameOperator });
            this.setState({ arrBarberan: arrTmp });
            setTimeout(() => {
                this.main();
                cekStatus();
                document.getElementById('edtD1').value = tanggalIndo(this.state.d1);
                document.getElementById('edtD2').value = tanggalIndo(this.state.d2);
            }, 200);
            loading();
        }

        handleMode(mode) {
            loading("show");
            this.setState({ mode: mode });
            if (mode == "detail") {
                this.setState({ disBarberman: false });
            } else {
                this.setState({ disBarberman: false });
            }
            setTimeout(() => {
                this.main();
            }, 200);
            loading();
        }

        setType(e, on = "focus", ssts) {
            if (on == "focus") {
                e.target.value = this.state[ssts];
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state[ssts]);
            }
        }

        handleChange(e, ssts, fn = false) {
            this.setState({ [ssts]: e.target.value });
            sessionStorage.setItem([ssts], e.target.value);
            if (fn != false) {
                loading("show");
                setTimeout(() => {
                    this.main();
                    loading();
                }, 200);
            }
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        modalResend(id) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="docnumber" value={id} />
                    <h3 style={{ fontSize: "24px" }}>Apakah anda yakin akan mengirim ulang invoice</h3>
                </Fragment>
            );

            rendModal({
                title: "Kirim Ulang Inovice",
                body: dom,
                form: true,
                id: "kirimUlang",
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">send</i> Kirim</button>`
            });

            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "resend_email", reload: true, modal: "btnCloseModalkirimUlang", form: "#iniForm" });
            });
        }

        async modalDetail(id) {
            let sql = await api("detail_transaction_web", { iddata: id });
            let dom = (
                <Fragment>
                    <div className="row">
                        <div className="col-md-4 mb2">
                            <table className="table table-striped">
                                <tbody>
                                    <tr><td>Tanggal</td><td>:</td><td>{tanggalIndo(sql.h.DocDate)}</td></tr>
                                    <tr><td>Invoice</td><td>:</td><td>{sql.h.DocNumber}</td></tr>
                                    <tr><td>Pelanggan</td><td>:</td><td>{sql.h.NameAccount}</td></tr>
                                    <tr><td>Kasir</td><td>:</td><td>{sql.h.Kasir}</td></tr>
                                    <tr><td>Barberman</td><td>:</td><td>{sql.h.Operator}</td></tr>
                                    <tr><td>Jam Datang</td><td>:</td><td>{sql.h.TimeCreated.substring(11, 19)}</td></tr>
                                    <tr><td>Jam Mulai</td><td>:</td><td>{sql.h.TimeStartProcess.substring(11, 19)}</td></tr>
                                    <tr><td>Jam Selesai</td><td>:</td><td>{sql.h.TimeEndProcces.substring(11, 19)}</td></tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-8 mb2">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-header"><h5 style={{ fontSize: "20px" }}>Total</h5></div>
                                        <div className="card-body"><h2 id="detailTotal" style={{ fontSize: "24px" }}>{numberFormat(sql.s.Total)}</h2></div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-header"><h5 style={{ fontSize: "20px" }}>Diskon</h5></div>
                                        <div className="card-body"><h2 id="detailDiskon" style={{ fontSize: "24px" }}>{numberFormat(sql.s.Diskon)}</h2></div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-header"><h5 style={{ fontSize: "20px" }}>Tips</h5></div>
                                        <div className="card-body"><h2 id="detailTips" style={{ fontSize: "24px" }}>{numberFormat(sql.s.Tips)}</h2></div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card">
                                        <div className="card-header"><h5 style={{ fontSize: "20px" }}>Grandtotal</h5></div>
                                        <div className="card-body"><h2 id="detailGT" style={{ fontSize: "24px" }}>{numberFormat(sql.s.GT)}</h2></div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <h5 style={{ fontSize: "20px" }}>Detail Produk</h5>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Nama Produk</th>
                                        <th>Qty</th>
                                        <th>Harga</th>
                                        <th>Diskon</th>
                                        <th>Grandtotal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sql.d.map(td =>
                                            <tr>
                                                <td>{td.NameProduct}</td>
                                                <td>{td.Qty}</td>
                                                <td>{numberFormat(td.Price)}</td>
                                                <td>{numberFormat(td.Discount)}</td>
                                                <td>{numberFormat(td.Total)}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                            <p></p>
                            <h5 style={{ fontSize: "20px" }}>Detail Pembayaran</h5>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Nama Pembayaran</th>
                                        <th>Nominal</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sql.pay.map(td =>
                                            <tr>
                                                <td>{td.NamePayment}</td>
                                                <td>{numberFormat(td.Debit)}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Fragment>
            );

            rendModal({
                title: "Detail Transaksi",
                cls: "modal-xl",
                id: "modalDetail",
                body: dom
            });
        }

        async modalSendWa(id) {
            let sql = await api("detail_transaction_web", { iddata: id });
            let dom = (
                <Fragment>
                    <div className="form-group">
                        <label>Telp</label>
                        <input type="text" name="telp" value={sql.h.Telp} className="form-control form-control-sm" />
                    </div>
                    <div className="form-group">
                        <label>Pesan</label>
                        <textarea className="form-control" name="notes" rows="5" value={"Halo " + sql.h.NameAccount + " terimakasih sudah berkunjung ke Broadway Barbershop cabang " + sql.h.NameLocation + " pada tanggal " + tanggalIndo(sql.h.DocDate) + " Berikut terlampir invoice transaksi anda."}></textarea>
                    </div>
                </Fragment>
            );

            rendModal({
                title: "Kirim Invoice via Wa",
                body: dom,
                form: true,
                id: "kirimWA",
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">send</i> Kirim</button>`
            });

            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "send_wa", reload: true, modal: "btnCloseModalkirimWA", form: "#iniForm" });
            });
        }

        async handleDownload(e) {
            e.target.innerHTML = `<span class="material-icons">hourglass_bottom</span>`;
            e.target.disabled = true;
            let url = this.state.mode == "ringkasan" ? "transactionfranc_browse_web" : "transactionfranc_detail_browse_web";
            let param = {
                search: this.state.search,
                searchcount: 99999,
                index: this.state.index,
                sort: this.state.sort,
                by: this.state.by,
                idadmin: getCookie("ID"),
                filter: "2",
                datestart: this.state.d1,
                dateend: this.state.d2,
                idlocation: this.state.idlocation,
                filteridbarberman: this.state.filteridbarberman
            }
            let sql = await api(url, param);
            exportData(sql, `Data Laporan Penjualan ${tanggalIndo(this.state.d1)} - ${tanggalIndo(this.state.d2)}`);
            setTimeout(() => {
                e.target.innerHTML = `<span class="material-icons">file_download</span>`;
                e.target.disabled = false;
            }, 2000);
        }

        async main() {
            let url = this.state.mode == "ringkasan" ? "transactionfranc_browse_web" : "transactionfranc_detail_browse_web", tdd = [];
            let param = {
                search: this.state.search,
                searchcount: this.state.searchcount,
                index: this.state.index,
                sort: this.state.sort,
                by: this.state.by,
                idadmin: getCookie("ID"),
                filter: "2",
                datestart: this.state.d1,
                dateend: this.state.d2,
                idlocation: this.state.idlocation,
                filteridbarberman: this.state.filteridbarberman
            }
            let sql = await api(url, param);
            this.setState({
                field: sql.field.thead, data: sql.data, pay: sql.pay,
                nota: sql.sum.TotalNota,
                total: sql.sum.Total,
                diskon: sql.sum.Discount,
                tips: sql.sum.Tips,
                grandtotal: sql.sum.Grandtotal,
                komisi: sql.sum.Commision
            });
            if (this.state.mode == "ringkasan") {
                for (let td of sql.data) {
                    let lok = this.state.idlocation == 0 ? <td>{td.NameLocation}</td> : "";
                    tdd.push(<tr>
                        <td>
                            <i className="material-icons icon-color" onClick={() => this.modalSendWa(td.ID)}>chat</i>
                            <i className="material-icons icon-color" onClick={() => this.modalResend(td.ID)}>schedule_send</i>
                        </td>
                        {lok}
                        <td className={"td-doc td-doc-" + td.DocNumber} data-id={td.DocNumber}> <a href="#" className="link-primary" onClick={() => this.modalDetail(td.ID)}>{td.DocNumber}</a> </td>
                        <td>{tanggalIndo(td.DocDate)}</td>
                        <td>{td.NameAccount}</td>
                        <td>{td.NameOperator}</td>
                        <td>{td.NameKasir}</td>
                        <td>{numberFormat(td.Total)}</td>
                        <td>{numberFormat(td.Discount)}</td>
                        <td>{numberFormat(td.Tips)}</td>
                        <td>{numberFormat(td.GrandTotal)}</td>
                        <td className={"td-payment td-payment-" + td.DocNumber} data-id={td.DocNumber}>
                            {
                                <table className="table">
                                    {
                                        this.state.pay.map((tdpay, i) => (
                                            (tdpay.DocNumber == td.DocNumber) &&
                                            <tr>
                                                <td>{tdpay.NamePayment}</td>
                                                <td>{numberFormat(tdpay.Nominal)}</td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            }
                        </td>
                    </tr>);
                }
            } else {
                let tdd = [];
                for (let td of sql.data) {
                    let lok = this.state.idlocation == 0 ? <td>{td.NameLocation}</td> : "";
                    tdd.push(<tr>
                        {lok}
                        <td className={"td-doc td-doc-" + td.DocNumber} data-id={td.DocNumber}>
                            <a href="#" className="link-primary" onClick={() => this.modalDetail(td.ID)}>{td.DocNumber}</a>
                        </td>
                        <td>{tanggalIndo(td.DocDate)}</td>
                        <td>{td.NameCustomer}</td>
                        <td>{td.NameOperator}</td>
                        <td>{td.NameKasir}</td>
                        <td>{td.Produk}</td>
                        <td>{td.Qty}</td>
                        <td>{td.Price}</td>
                        <td>{numberFormat(td.Total)}</td>
                        <td>{numberFormat(td.Discount)}</td>
                        <td>{numberFormat(td.Tips)}</td>
                        <td>{numberFormat(td.GrandTotal)}</td>
                        <td>{numberFormat(td.Komisi)}</td>
                        <td className={"td-payment td-payment-" + td.DocNumber} data-id={td.DocNumber}>
                            {<table className="table">
                                {
                                    this.state.pay.map((tdpay, i) => (
                                        (tdpay.DocNumber == td.DocNumber) &&
                                        <tr>
                                            <td>{tdpay.NamePayment}</td>
                                            <td>{numberFormat(tdpay.Nominal)}</td>
                                        </tr>
                                    ))
                                }
                            </table>
                            }
                        </td>
                    </tr>);
                }
                rowspan(".td-doc");
                rowspan(".td-payment");
            }
            this.setState({ td: tdd });
            rendPagginations({
                jml: sql.field.j, fn: (e) => this.handlePage(e), index: this.state.index
            });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col col-md-4 col-sm-6">
                                <div className="d-flex justify-content-start align-items-center gap-1">
                                    <h5>Periode</h5>
                                    <div className="input-group input-group-sm gap-2">
                                        <input type="text" name="edtD1" id="edtD1" className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "d1")} onMouseOver={(e) => this.setType(e, 'focus', 'd1')} onBlur={(e) => this.setType(e, "blur", 'd1')} />
                                        <input type="text" name="edtD2" id="edtD2" className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "d2")} onMouseOver={(e) => this.setType(e, 'focus', 'd2')} onBlur={(e) => this.setType(e, "blur", 'd2')} />
                                        <button className="btn btn-sm btn-default" onClick={() => this.main()}><span className="material-icons">search</span></button>
                                        <button className="btn btn-sm btn-default" id="btnExport" onClick={(e) => this.handleDownload(e)}><span className="material-icons">file_download</span></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-8 col-sm-6">
                                <div className="d-flex justify-content-end align-items-center">
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-ringkasan-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={(e) => this.handleMode('ringkasan')}>Ringkasan</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-detail-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={(e) => this.handleMode('detail')}>Detail</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="d-flex justify-content-start align-items-center gap-3">
                            <div className="card text-start" style={{ minWidth: "150px", minHeight: "100px" }}>
                                <div className="card-header">
                                    <h6 className="card-title" style={{ fontSize: "10px;" }}>Total Nota</h6>
                                </div>
                                <div className="card-body">
                                    <p className="card-text" id="divCT">{numberFormat(this.state.nota)}</p>
                                </div>
                            </div>
                            <div className="card text-start" style={{ minWidth: "150px", minHeight: "100px" }}>
                                <div className="card-header">
                                    <h6 className="card-title" style={{ fontSize: "10px;" }}>Total</h6>
                                </div>
                                <div className="card-body">
                                    <p className="card-text" id="divSUM">{numberFormat(this.state.total)}</p>
                                </div>
                            </div>
                            <div className="card text-start" style={{ minWidth: "150px", minHeight: "100px" }}>
                                <div className="card-header">
                                    <h6 className="card-title" style={{ fontSize: "10px;" }}>Diskon</h6>
                                </div >
                                <div className="card-body">
                                    <p className="card-text" id="divDiskon">{numberFormat(this.state.diskon)}</p>
                                </div>
                            </div>
                            <div className="card text-start" style={{ minWidth: "150px", minHeight: "100px" }}>
                                <div className="card-header">
                                    <h6 className="card-title" style={{ fontSize: "10px;" }}>Tips</h6>
                                </div >
                                <div className="card-body">
                                    <p className="card-text" id="divTips">{numberFormat(this.state.tips)}</p>
                                </div>
                            </div>
                            <div className="card text-start" style={{ minWidth: "150px", minHeight: "100px" }}>
                                <div className="card-header">
                                    <h6 className="card-title" style={{ fontSize: "10px;" }}>Grandtotal</h6>
                                </div>
                                <div className="card-body">
                                    <p className="card-text" id="divGT">{numberFormat(this.state.grandtotal)}</p>
                                </div>
                            </div >
                            <div className="card text-start" style={{ minWidth: "150px", minHeight: "100px" }}>
                                <div className="card-header">
                                    <h6 className="card-title" style={{ fontSize: "10px;" }}>Komisi</h6>
                                </div>
                                <div className="card-body">
                                    <p className="card-text" id="divKomisi">{numberFormat(this.state.komisi)}</p>
                                </div>
                            </div >
                            <select name="barberman" id="edtBarberman" className="form-select" onChange={(e) => this.handleChange(e, "filteridbarberman", "main")} disabled={this.state.disBarberman}>
                                {this.state.arrBarberan.map(elm => <option value={elm.ID}>{elm.NameOperator}</option>)}
                            </select>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                            <table className="table table-bordered">
                                <thead className="color-bg-biru">
                                    <tr>
                                        {this.state.field.map(th => <th onClick={(e) => this.handleSort(th.sort)}>{th.cap}</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.td}
                                </tbody>
                            </table>
                        </div>
                    </div >
                    <nav id="paggination"></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;