import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { rendTable, rendModal, api, saiki, submitForm, loading, getCookie } from '../modul';

function App() {
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameAdmin",
                by: "DESC",
                datestart: saiki(),
                dateend: saiki(),
                idlocation: localStorage.getItem("location")
            };
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        async modalDelete(e) {
            const dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={e.target.dataset.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "admin_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async main() {
            let sql = await api("admin_browse_web", {
                search: this.state.search,
                searchcount: this.state.searchcount,
                index: this.state.index,
                sort: this.state.sort,
                by: this.state.by,
                idadmin: 1,
                d1: this.state.datestart,
                d2: this.state.dateend,
                idlocation: this.state.idlocation,
            });

            rendTable({
                dataType: "url",
                dataset: sql,
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi: [
                        { type: "edit", event: (e) => rendForm(e) },
                        { type: "delete", event: (e) => this.modalDelete(e) }
                    ],
                    id: "tbodyData"
                }
            });
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={(e) => rendForm(e)}>Tambah Admin</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination"></nav>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}


function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                __ID: getCookie("ID"),
                username: "",
                name: "",
                desc: "",
                telp: "",
                address: "",
                idlocation: "",
                namelocation: "",
                limit: "",
                inpLimit: 1,
                chkLimit: 0,
                act: "admin_insert",
                isho: 0,
                chkho: 0,
                menuAdmin: [],
                lokasiAdmin: [],
                valMenu: []
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                const sql = await api("admin_detail", { iddata: this.state.id });
                if (sql.data.length > 0) {
                    this.setState({
                        username: sql.data[0].Username,
                        name: sql.data[0].NameAdmin,
                        desc: sql.data[0].Description,
                        telp: sql.data[0].Telp,
                        address: sql.data[0].Address,
                        idlocation: sql.data[0].IDLocation,
                        namelocation: sql.data[0].NameLocation,
                        limit: sql.data[0].LimitPeriod,
                        inpLimit: sql.data[0].LimitPeriod == 0 ? 1 : 0,
                        chkLimit: sql.data[0].LimitPeriod == 0 ? 0 : 1,
                        act: "admin_edit",
                        isho: sql.data[0].IsHO,
                        chkho: sql.data[0].IsHO == 1 ? "checked" : ""
                    });
                }
            }
            let arrLokasi = [];
            let sqlLokasi = await api("location_admin", { iddata: id });
            for (let MM of sqlLokasi.data) arrLokasi.push(MM);
            this.setState({ lokasiAdmin: arrLokasi });

            let sqlMenu = await api("admin_listmenu_web", { iddata: id });
            let arrMenu = [];
            for (let mm of sqlMenu.data) if (mm.isLock == 1) arrMenu.push(mm);
            this.setState({ valMenu: arrMenu });
            this.setState({ menuAdmin: sqlMenu });
            this.menu();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        async scLokasi(e) {
            this.setState({ namelocation: e.target.value });
            const sql = await api("location_browse", { search: e.target.value, searchcount: 100, index: 1 });
            const dom = sql.data.map((opt) =>
                <li><a className="dropdown-item" href="#" onClick={(e) => this.setLokasi(opt.NameLocation, opt.ID)}>{opt.NameLocation}</a></li>
            )
            ReactDOM.render(dom, document.getElementById('ulLokasi'));
        }

        setLokasi(nama, id) {
            document.getElementById('edtLokasi').value = nama;
            document.getElementById('edtLokasi').value = id;
        }

        chkMenu(e, id) {
            const myjsonobj = this.state.menuAdmin;
            if (e.target.checked == true) {
                Object.keys(myjsonobj.data).forEach(function (key) {
                    if (myjsonobj.data[key].FormCode == id) myjsonobj.data[key].isLock = 1;
                });
            } else {
                Object.keys(myjsonobj.data).forEach(function (key) {
                    if (myjsonobj.data[key].FormCode == id) myjsonobj.data[key].isLock = 0;
                });
            }
            this.setState({ menuAdmin: myjsonobj });
            let arrMenu = [];
            for (let mm of this.state.menuAdmin.data) if (mm.isLock == 1) arrMenu.push(mm);
            this.setState({ valMenu: arrMenu });
            this.menu();
        }

        getMenu(id, dataset) {
            let arrMenu = [];
            for (let mm of dataset.data) {
                if (mm.GroupID == id) {
                    let set = 0;
                    for (let SET of dataset.data) if (SET.GroupID == mm.ID) set = 1;
                    if (set == 0) {
                        arrMenu.push(
                            <div className="form-check form-switch">
                                <input className="form-check-input chk-menu" type="checkbox" onChange={(e) => this.chkMenu(e, mm.FormCode)} id={"flexSwitchCheck" + mm.ID} checked={mm.isLock} />
                                <label className="form-check-label" for={"flexSwitchCheck" + mm.ID}>{mm.FormName}</label>
                            </div>
                        );
                    } else {
                        arrMenu.push(
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input chk-menu" type="checkbox" onChange={(e) => this.chkMenu(e, mm.FormCode)} id={"flexSwitchCheck" + mm.ID} checked={mm.isLock} />
                                        <label className="form-check-label" for={"flexSwitchCheck" + mm.ID}>{mm.FormName}</label>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    {
                                        dataset.data.map(SET => {
                                            if (SET.GroupID == mm.ID) {
                                                return <div className="form-check">
                                                    <input className="form-check-input chk-menu" type="checkbox" onChange={(e) => this.chkMenu(e, SET.FormCode)} data-form={SET.FormCode} id={"set" + SET.ID} checked={SET.isLock} />
                                                    <label className="form-check-label" for={"set" + SET.ID}>{SET.FormName}</label>
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                }
            }
            return arrMenu;
        }

        menu() {
            let datasetmenu = this.state.menuAdmin;;
            let arrMenuRoot = []
            for (let MM of datasetmenu.data) {
                if (MM.GroupID == 0 && MM.IsGroup == 0) {
                    arrMenuRoot.push(
                        <div className="form-check form-switch">
                            <input className="form-check-input chk-menu" type="checkbox" onChange={(e) => this.chkMenu(e, MM.FormCode)} id={"flexSwitchCheck" + MM.ID} checked={MM.isLock} />
                            <label className="form-check-label" for={"flexSwitchCheck" + MM.ID}>{MM.FormName}</label>
                        </div>
                    );
                }
            }

            let arrGroup = [];
            for (let gp of datasetmenu.group) {
                arrGroup.push(
                    <div className="accordion-item">
                        <h2 className="accordion-header" id={"panelsStayOpen-heading" + gp.ID}>
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#panelsStayOpen-collapse" + gp.ID} aria-expanded="true" aria-controls={"panelsStayOpen-collapse" + gp.ID}>
                                {gp.FormName}
                            </button>
                        </h2>
                        <div id={"panelsStayOpen-collapse" + gp.ID} className="accordion-collapse collapse show" aria-labelledby={"panelsStayOpen-heading" + gp.ID}>
                            <div className="accordion-body">
                                {this.getMenu(gp.ID, datasetmenu)}
                            </div>
                        </div>
                    </div>
                );
            }
            let dom = <div>{arrMenuRoot}{arrGroup}</div>;
            ReactDOM.render(dom, document.getElementById('accordionPanelsStayOpenExample'));
        }

        async lokasi() {
            let qlok = await api("location_admin", { iddata: id });
            let dom = [];
            for (let lok of qlok.data) {
                dom.push(
                    <div className="form-check form-switch">
                        <input className="form-check-input chk-menu" name="idlocation" value={lok.ID} type="checkbox" id={"chkLok" + lok.ID} checked={lok.isLock} />
                        <label className="form-check-label" for={"chkLok" + lok.ID}>{lok.NameLocation}</label>
                    </div>
                )
            }
            ReactDOM.render(dom, document.getElementById('divLokasiForm'));
        }

        handleLokasi(e, i) {
            let old = this.state.lokasiAdmin;
            old[i].isLock = e.target.checked == true ? 1 : 0;
            this.setState({ lokasiAdmin: old });
        }

        handleChk(e, ssts) {
            this.setState({ [ssts]: e.target.checked === true ? 1 : 0 });
        }

        hanldeLimit(e) {
            this.setState({
                chkLimit: e.target.checked == true ? 1 : 0,
                inpLimit: e.target.checked == true ? 0 : 1
            });
            if (e.target.checked == false) this.setState({ limit: 0 });
        }

        render() {
            return (
                <>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={this.state.__ID} />
                    <input type="hidden" name="isho" id="edtISHO" value={this.state.isho} />
                    <textarea name="datasetmenu" style={{ display: "none" }} id="edtMenu" value={JSON.stringify(this.state.valMenu)}></textarea>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="row">
                                <div className="col-md-4 mb-2">
                                    <div className="form-group">
                                        <label>Username</label>
                                        <input type="text" name="username" className="form-control" id="edtUserName" value={this.state.username} onChange={(e) => this.handleChange(e, "username")} required />
                                        <div className="invalid-feedback">Silahkan Isi Username</div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <div className="form-group">
                                        <label>Nama Admin</label>
                                        <input type="text" name="name" className="form-control" id="edtName" value={this.state.name} onChange={(e) => this.handleChange(e, "name")} required />
                                        <div className="invalid-feedback">Silahkan pilih lokasi</div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <div className="form-group">
                                        <label>Telp</label>
                                        <input type="number" name="telp" className="form-control" id="edtTelp" value={this.state.telp} onChange={(e) => this.handleChange(e, "telp")} required />
                                        <div className="invalid-feedback">Silahkan isi telp admin</div>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label>Alamat</label>
                                        <textarea name="address" className="form-control" id="edtAlamat" value={this.state.address} onChange={(e) => this.handleChange(e, "address")} required></textarea>
                                        <div className="invalid-feedback">Silahkan isi alamat admin</div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label>Deskripsi</label>
                                        <textarea name="desc" className="form-control" id="edtDesc" value={this.state.desc} onChange={(e) => this.handleChange(e, "desc")}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Limit Periode</label>
                                <div className="input-group mb-3" style={{ border: "2px solid rgba(84, 58, 183, 1) !important;" }} >
                                    <div className="input-group-text">
                                        <input className="form-check-input" id="chkLimit" type="checkbox" aria-label="limit periode" onChange={(e) => this.hanldeLimit(e)} checked={this.state.chkLimit} />
                                    </div>
                                    <input type="number" name="limitPeriod" id="edtLimit" value={this.state.limit} onChange={(e) => this.handleChange(e, "limit")} className="form-control" readOnly={this.state.inpLimit} style={{ border: "none !important;" }} required />
                                    <div className="input-group-text">Hari</div>
                                </div>
                                <div className="invalid-feedback">Silahkan isi limit period, beri nilai 0 jika tidak ada limit</div>
                            </div>
                            <div className="form-check form-switch">
                                <input className="form-check-input" name="IsHO" type="checkbox" id="edtHO" onChange={(e) => this.handleChk(e, "isho")} checked={this.state.isho} />
                                <label className="form-check-label" for="edtHO">Admin Pusat</label>
                            </div>
                            <h4>Akses Lokasi / Cabang</h4>
                            <div id="divLokasiForm">
                                {
                                    this.state.lokasiAdmin.map((lok, i) =>
                                        <div className="form-check form-switch" >
                                            <input className="form-check-input chk-menu" name="idlocation" value={lok.ID} onChange={(e) => this.handleLokasi(e, i)} type="checkbox" id={"chkLok" + lok.ID} checked={lok.isLock} />
                                            <label className="form-check-label" for={"chkLok" + lok.ID}>{lok.NameLocation}</label>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <h4>Akses Menu</h4>
                            <div className="accordion" id="accordionPanelsStayOpenExample"></div>
                        </div>
                    </div>
                </>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "admin_edit" : "admin_insert",
        title = id ? "Edit Admin" : "Tambah Admin";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form",
        cls: "modal-xl"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;