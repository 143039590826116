import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { rendTable, rendModal, api, saiki, submitForm, getCookie, numberFormat, loading, tanggalIndo } from '../modul';

function App() {
    document.getElementById("lblHeader").innerHTML = "Master Promosi Pelanggan";
    document.title = "Master Promosi Pewlanggan";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NamePromoCustomer",
                by: "ASC",
                datestart: saiki(),
                dateend: saiki(),
                idlocation: localStorage.getItem("location")
            };
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                const sort = this.state.by == "ASC" ? "DESC" : "ASC";
                this.setState({ by: sort });
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        async modalDelete(e) {
            const dom = (
                <Fragment>
                    <input type="hidden" name="iddata" value={e.target.dataset.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data Promo Pelanggan",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "promopelanggan_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async main() {
            let sql = await api("promopelanggan_browse", {
                search: this.state.search,
                searchcount: this.state.searchcount,
                index: this.state.index,
                sort: this.state.sort,
                by: this.state.by,
                idadmin: getCookie("ID"),
                d1: this.state.datestart,
                d2: this.state.dateend,
                idlocation: this.state.idlocation,
            });

            console.log(sql);

            let field = {
                thead: [
                    { cap: "No", sort: "CodePromoCustomer" },
                    { cap: "Nama Promo", sort: "NamePromoCustomer" },
                    { cap: "Tanggal Mulai", sort: "DateStart" },
                    { cap: "Tanggal Selesai", sort: "DateEnd" },
                    { cap: "Pelanggan", sort: "NameCustomer" },
                    { cap: "Produk", sort: "NameProduct" },
                    { cap: "Nilai", sort: "Price" },
                    { cap: "Persentase", sort: "Percentage" },
                    { cap: "", sort: "", type: "opsi" }
                ], j: sql.ct
            }

            rendTable({
                dataType: "dataset",
                dataset: { data: sql.data, field: field },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi: [
                        { type: "edit", event: (e) => rendForm(e) },
                        { type: "delete", event: (e) => this.modalDelete(e) }
                    ],
                    id: "tbodyData"
                }
            });
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={(e) => rendForm(e)}>Tambah Promo Pelanggan</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination"></nav>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                idadmin: getCookie("ID"),
                iddata: ini.target.dataset.id || "",
                CodePromoCustomer: "",
                NamePromoCustomer: "",
                IDCustomer: "",
                NamewCustomer: "",
                IDProduct: "",
                NameProduct: "",
                TypePromo: "price",
                Percentage: "0",
                Price: "0",
                DateStart: saiki(),
                DateExpired: saiki(),
                IsPercentage: false,
                IsPrice: true,
                isFilterTime: true,
                LimitTime: false
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                const sql = await api("promopelanggan_detail", { iddata: this.state.id });
                console.log(sql);
                this.setState({
                    CodePromoCustomer: sql.data.CodePromoCustomer,
                    NamePromoCustomer: sql.data.NamePromoCustomer,
                    IDCustomer: sql.data.IDCustomer,
                    NameCustomer: sql.data.NameCustomer,
                    IDProduct: sql.data.IDProduct,
                    NameProduct: sql.data.NameProduct,
                    TypePromo: sql.data.TypePromo,
                    Percentage: sql.data.Percentage,
                    Price: sql.data.Price,
                    DateStart: sql.data.DateStart,
                    DateExpired: sql.data.DateExpired,
                    IsPercentage: sql.data.TypePromo == "percentage" ? true : false,
                    IsPrice: sql.data.TypePromo == "price" ? true : false,
                    isFilterTime: sql.data.isFilterTime == 1 ? true : false,
                    LimitTime: sql.data.isFilterTime == 1 ? false : true
                });
            }
            setTimeout(() => {
                document.getElementById('edtD1').value = tanggalIndo(this.state.DateStart);
                document.getElementById('edtD2').value = tanggalIndo(this.state.DateExpired);
            }, 200);
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        HandleFilter(e) {
            if (e.target.checked == true) {
                this.setState({ LimitTime: false, isFilterTime: true });
            } else {
                this.setState({ LimitTime: true, isFilterTime: false });
            }
        }

        setType(e, on = "focus", ssts) {
            if (on == "focus") {
                e.target.value = this.state[ssts];
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state[ssts]);
            }
        }

        modalCari(e) {
            const dom = (
                <>
                    <input type="search" class="form-control form-control-sm" onChange={(e) => this.cariProduct(e)} onFocus={(e) => this.cariProduct(e)} />
                    <p></p>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Nama Produk</th>
                                    <th>Harga</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id="tbodyCariProduk"></tbody>
                        </table>
                    </div>
                </>
            );

            rendModal({
                title: "Cari Produk",
                cls: "modal-lg",
                id: "modalCari",
                body: dom
            });
        }

        async cariProduct(e) {
            const sql = await api("product_browse", { search: e.target.value, index: 1, searchcount: 10 });
            const dom = sql.data.map(opt =>
                <tr style={{ cursor: "pointer" }} onClick={() => this.pilihProduct(opt.ID, opt.NameProduct)}>
                    <td>{opt.NameProduct}</td>
                    <td>{numberFormat(opt.Price)}</td>
                    <td></td>
                </tr>
            )
            ReactDOM.render(dom, document.getElementById('tbodyCariProduk'));
        }

        pilihProduct(id, name) {
            this.setState({ IDProduct: id, NameProduct: name });
            document.getElementById('btnCloseModalmodalCari').click();
        }

        modalCariPelanggan(e) {
            const dom = (
                <Fragment>
                    <input type="search" class="form-control form-control-sm" onChange={(e) => this.cariPelanggan(e)} onFocus={(e) => this.cariPelanggan(e)} />
                    <p></p>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Nama Pelanggan</th>
                                    <th>Telp</th>
                                    <th>Alamat</th>
                                </tr>
                            </thead>
                            <tbody id="tbodyCariPelanggan"></tbody>
                        </table>
                    </div>
                </Fragment>
            );

            rendModal({
                title: "Cari Pelanggan",
                cls: "modal-xl",
                id: "modalCariPelanggan",
                body: dom
            });
        }

        async cariPelanggan(e) {
            const sql = await api("customer_browse", { search: e.target.value, index: 1, searchcount: 10 });
            const dom = sql.data.map(opt =>
                <tr style={{ cursor: "pointer" }} onClick={() => this.pilihPelanggan(opt.ID, opt.NameCustomer)}>
                    <td>{opt.NameCustomer}</td>
                    <td>{opt.Telp}</td>
                    <td>{opt.Address}</td>
                </tr>
            )
            ReactDOM.render(dom, document.getElementById('tbodyCariPelanggan'));
        }

        pilihPelanggan(id, name) {
            this.setState({ IDCustomer: id, NameCustomer: name });
            document.getElementById('btnCloseModalmodalCariPelanggan').click();
        }

        handleLokasi(e, index) {
            let old = this.state.datalocation;
            let isLock = e.target.checked == true ? 1 : 0;
            old[index].isLock = isLock;
            let arrNew = [];
            for (let lok of old) if (lok.isLock == 1) arrNew.push({ ID: lok.ID });
            this.setState({ datalocation: old, arrLokasi: arrNew });
        }

        handleType(e, ssts) {
            if (ssts == "IsPercentage") {
                this.setState({ IsPercentage: true, IsPrice: false, TypePromo: "percentage" });
            } else {
                this.setState({ IsPercentage: false, IsPrice: true, TypePromo: "price" });
            }
        }

        render() {
            return (
                <Fragment>
                    <input type="hidden" name="iddata" value={this.state.iddata} />
                    <input type="hidden" name="idadmin" value={this.state.idadmin} />
                    <input type="hidden" name="DateStart" value={this.state.DateStart} />
                    <input type="hidden" name="DateExpired" value={this.state.DateExpired} />
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Kode Promosi</label>
                                <input type="text" name="CodePromoCustomer" className="form-control" id="edtCode" value={this.state.CodePromoCustomer} onChange={(e) => this.handleChange(e, "CodePromoCustomer")} required />
                                <div className="invalid-feedback">Silahakan isi code promosi</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Nama Promosi</label>
                                <input type="text" name="NamePromoCustomer" className="form-control" id="edtNama" value={this.state.NamePromoCustomer} onChange={(e) => this.handleChange(e, "NamePromoCustomer")} required />
                                <div className="invalid-feedback">Silahakan isi code promosi</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" name="isFilterTime" id="flexSwitchCheckDefault" onChange={(e) => this.HandleFilter(e)} checked={this.state.isFilterTime} />
                        <label class="form-check-label" for="flexSwitchCheckDefault">Batas Waktu</label>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Tanggal Mulai</label>
                                <input type="text" className="form-control form-date" id="edtD1" onChange={(e) => this.handleChange(e, "DateStart")} onMouseOver={(e) => this.setType(e, 'focus', 'DateStart')} onBlur={(e) => this.setType(e, "blur", 'DateStart')} required readOnly={this.state.LimitTime} />
                                <div className="invalid-feedback">Silahkan pilih tanggal mulai</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Tanggal Expired</label>
                                <input type="text" className="form-control form-date" id="edtD2" onChange={(e) => this.handleChange(e, "DateExpired")} onMouseOver={(e) => this.setType(e, 'focus', 'DateExpired')} onBlur={(e) => this.setType(e, "blur", 'DateExpired')} required readOnly={this.state.LimitTime} />
                                <div className="invalid-feedback">Silahkan pilih tanggal expired</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Produk</label>
                                <input type="hidden" name="IDProduct" id="edtProductID" value={this.state.IDProduct} />
                                <input type="text" name="NameProduct" className="form-control" id="edtProduct" value={this.state.NameProduct} onFocus={(e) => this.modalCari(e)} required />
                                <div className="invalid-feedback">Silahkan pilih produk</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Nama Pelanggan</label>
                                <input type="hidden" name="IDCustomer" id="edtCustomerID" value={this.state.IDCustomer} />
                                <input type="text" name="NameCuctomer" className="form-control" id="edtNameCustomer" value={this.state.NameCustomer} onFocus={() => this.modalCariPelanggan()} required />
                                <div className="invalid-feedback">Silahkan pilih nama pelanggan</div>
                            </div>
                        </div>
                    </div>
                    <div className='form-group'>
                        <label>Type Nominal</label>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="TypePromo" value='percentage' id="flexRadioDefault1" onChange={(e) => this.handleType(e, "IsPercentage")} checked={this.state.IsPercentage} />
                            <label class="form-check-label" for="flexRadioDefault1">
                                Persentase
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="TypePromo" value='price' id="flexRadioDefault2" onChange={(e) => this.handleType(e, "IsPrice")} checked={this.state.IsPrice} />
                            <label class="form-check-label" for="flexRadioDefault2">
                                Nominal
                            </label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 mb-2'>
                            <div className="form-group">
                                <label>Nominal</label>
                                <input type="number" name="Price" className="form-control" id="edtPrice" value={this.state.Price} onChange={(e) => this.handleChange(e, "Price")} required readOnly={this.state.IsPercentage} />
                                <div className="invalid-feedback">Silahkan isi nominal</div>
                            </div>
                        </div>
                        <div className='col-md-6 mb-2'>
                            <div className="form-group">
                                <label>Persentase</label>
                                <input type="number" name="Percentage" className="form-control" id="edtPercentage" value={this.state.Percentage} onChange={(e) => this.handleChange(e, "Percentage")} required readOnly={this.state.IsPrice} />
                                <div className="invalid-feedback">Silahkan isi persentase</div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id != undefined ? "promopelanggan_edit" : "promopelanggan_insert",
        title = id != undefined ? "Edit Promo pelanggan" : "Tambah Promo pelanggan";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form",
        cls: "modal-lg"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;