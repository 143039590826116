import React, { Fragment } from 'react';
import { rendTable, tanggalIndo, api, pesan, saiki, exportData, rendModal, loading, cekStatus } from "../modul";
import ReactDOM from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "Laporan Histori Pelanggan";
    document.title = "Laporan Histori Pelanggan";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameProduct",
                by: "ASC",
                pelanggan: "",
                idpelanggan: "",
                all: "ya",
                d1: saiki(),
                d2: saiki(),
                location: localStorage.getItem("location"),
                total: 0,
                ct: 0
            };
        }

        componentDidMount() {
            if (sessionStorage.getItem("d1")) this.setState({ d1: sessionStorage.getItem("d1") });
            if (sessionStorage.getItem("d2")) this.setState({ d2: sessionStorage.getItem("d2") });
            if (sessionStorage.getItem("bln")) this.setState({ bln: sessionStorage.getItem("bln") });
            setTimeout(() => {
                this.main();
                cekStatus();
                document.getElementById('edtD1').value = tanggalIndo(this.state.d1);
                document.getElementById('edtD2').value = tanggalIndo(this.state.d2);
                loading();
            }, 200);
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            sessionStorage.setItem([ssts], e.target.value);
            setTimeout(() => {
                this.main();
            }, 200);
        }

        handleChk(e) {
            this.setState({ all: e.target.checked == true ? "tidak" : "ya" });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        async rendDetail(ini) {
            alert(JSON.stringify(ini.target.id));
        }

        modalPelanggan() {
            let dom = (<Fragment>
                <input type="search" class="form-control" placeholder="Cari Nama / Telp Pelanggan" onChange={(e) => this.cariData(e.target.value)} autoComplete="off" />
                <p></p>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Nama</th>
                            <th>Alamat</th>
                            <th>Telp</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody id="tbodyPelanggan"></tbody>
                </table>
            </Fragment>);

            rendModal({
                title: "Cari Pelanggan",
                body: dom,
                cls: "modal-lg",
                id: "cariPelanggan"
            });

            this.cariData('');
        }

        async cariData(ini) {
            let sql = await api("customer_browse", { index: 1, search: ini, searchcount: 25, sort: "NameCustomer", by: "ASC" });
            let tr = sql.data.map(elm =>
                <tr onClick={(e) => this.pilihPelanggan(elm.NameCustomer, elm.ID)} key={elm.ID}>
                    <td>{elm.NameCustomer}</td>
                    <td>{elm.Address}</td>
                    <td>{elm.Telp}</td>
                    <td>{elm.Email}</td>
                </tr>
            )
            ReactDOM.render(tr, document.getElementById('tbodyPelanggan'));
        }

        pilihPelanggan(ini, id) {
            this.setState({ pelanggan: ini, idpelanggan: id });
            document.getElementById('btnCloseModalcariPelanggan').click();
            setTimeout(() => {
                this.main();
            }, 200);
        }

        async main() {
            if (this.state.idpelanggan > 0) {
                let param = {
                    idlocation: this.state.location,
                    d1: this.state.d1,
                    d2: this.state.d2,
                    searchcount: this.state.searchcount,
                    all: this.state.all,
                    index: this.state.index,
                    sort: this.state.sort,
                    by: this.state.by,
                    search: this.state.idpelanggan
                };
                let sql = await api("customer_report", param);
                this.setState({ total: sql.sum.Total, ct: sql.sum.CT });

                rendTable({
                    dataset: sql,
                    div: "divGrid",
                    table: { cls: "table table-striped" },
                    thead: { cls: "thead-dark" },
                    tbody: { id: "tbodyData" }
                });
            } else {
                pesan("Info", "Silahkan pilih pelanggan terlebih dahulu", "warning");
            }
        }

        setType(e, on = "focus", ssts) {
            if (on == "focus") {
                e.target.value = this.state[ssts];
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state[ssts]);
            }
        }

        async handleDownload(e) {
            if (this.state.idpelanggan > 0) {
                e.target.innerHTML = `<span class="material-icons">hourglass_bottom</span>`;
                e.target.disabled = true;
                let param = {
                    idlocation: this.state.location,
                    d1: this.state.d1,
                    d2: this.state.d2,
                    searchcount: 99999,
                    all: this.state.all,
                    index: this.state.index,
                    sort: this.state.sort,
                    by: this.state.by,
                    search: this.state.idpelanggan
                };
                let sql = await api("customer_report", param);
                exportData(sql, `Data Laporan Histori Pelanggan ${tanggalIndo(this.state.d1)} - ${tanggalIndo(this.state.d2)}`);
                setTimeout(() => {
                    e.target.innerHTML = `<span class="material-icons">file_download</span>`;
                    e.target.disabled = false;
                }, 2000);
            } else {
                pesan("Info", "Silahkan pilih pelanggan terlebih dahulu", "warning");
            }
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="input-group gap-2">
                                        <input type="search" id="edtNamaPelanggan" className="form-control form-control-sm" value={this.state.pelanggan} onClick={() => this.modalPelanggan()} placeholder="Nama / Telp Pelanggan" readonly />
                                        <button className="btn btn-sm btn-default" onClick={() => this.modalPelanggan()}> <i className="material-icons">search</i> </button>
                                        <button className="btn btn-sm btn-default" id="btnExport" onClick={(e) => this.handleDownload(e)}><i className="material-icons">file_download</i></button>
                                    </div>
                                </div>
                                <p></p>
                                <div className="d-flex justify-content-start align-items-center">
                                    <div className="input-group gap-2">
                                        <input type="text" id="edtD1" className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "d1")} onMouseOver={(e) => this.setType(e, 'focus', 'd1')} onBlur={(e) => this.setType(e, "blur", 'd1')} />
                                        <input type="text" id="edtD2" className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "d2")} onMouseOver={(e) => this.setType(e, 'focus', 'd1')} onBlur={(e) => this.setType(e, "blur", 'd1')} />
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="chkAll" onChange={(e) => this.handleChk(e)} />
                                            <label className="form-check-label" for="chkAll">Semua Periode</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                        <p></p>
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <div className="card">
                                    <div className="card-header">
                                        <h6>Total Nota</h6>
                                    </div>
                                    <div className="card-body" style={{ minHeight: "50px" }} id="divCT">{this.state.ct}</div>
                                </div>
                            </div>
                            <div className="col-md-6 mb-2">
                                <div className="card">
                                    <div className="card-header">
                                        <h6>Total Penjualan</h6>
                                    </div>
                                    <div className="card-body" style={{ minHeight: "50px" }} id="divGT">{this.state.total}</div>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divGrid">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Tanggal</th>
                                        <th>No. Order</th>
                                        <th>Cabang</th>
                                        <th>Barberman</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colspan="4">Tidak ada data</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <nav id="paggination"></nav>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

export default App;