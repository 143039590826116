import React from 'react';
import ReactDOM from 'react-dom';
import { saiki, api, loading, rendModal, submitForm } from "../modul";

function App() {
    document.getElementById("lblHeader").innerHTML = "Barberman Outlet";
    document.title = "Barberman Outlet";

    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameKasir",
                by: "DESC",
                datestart: saiki(),
                dateend: saiki(),
                idlocation: localStorage.getItem("location"),
                pilihAll: true,
                dataOperator: []
            };
        }

        async modalPilih(id) {
            if (this.state.idlocation != "0") {
                await api("operatorschedule_insert", { id: id, lokasi: this.state.idlocation });
                this.main();
            } else {
                alert("silahkan pilih lokasi terlebih dahulu");
            }
        }

        async main() {
            const opr = await api("operator_browse_web_2", {
                search: this.state.search,
                index: this.state.index,
                searchcount: this.state.searchcount,
                idlocation: this.state.idlocation
            });
            let td = [];
            if (this.state.idlocation == "0") {
                this.setState({ pilihAll: true });
            } else {
                this.setState({ pilihAll: false });
            }
            let arrData = [];
            for (let TD of opr.data) {
                let badge = TD.IDLocation == localStorage.getItem("location") ? <span className="badge bg-primary">Barberman Cabang</span> : "";
                let cls = TD.IDLocation == localStorage.getItem("location") ? "opr-cabang" : "";
                arrData.push({ ID: TD.ID, Loc: cls });
                this.setState({ dataOperator: arrData });
                td.push(<tr><td>{TD.NameOperator} {badge}</td><td><i className={"material-icons icon-color" + cls} onClick={(e) => this.modalPilih(TD.ID)}>check_circle</i></td></tr>);
            }
            ReactDOM.render(td, document.getElementById('tbodyOperator'));
            document.getElementById('bLocation').innerHTML = document.getElementById('ddLocation').value;

            let td2 = [];
            for (let TD2 of opr.all) td2.push(<tr><td>{TD2.NameOperator}</td><td><i className="material-icons icon-color" onClick={(e) => this.modalDelete(TD2.ID)}>delete</i></td></tr>);
            ReactDOM.render(td2, document.getElementById('tbodyOperatorLocation'));
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        async modalDelete(id) {
            const dom = (
                <>
                    <input type="hidden" name="iddata" value={id} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "operatorschedule_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async handleClickBtn() {
            for (let opt of this.state.dataOperator) if (opt.Loc == "opr-cabang") await api("operatorschedule_insert", { id: opt.ID, lokasi: this.state.idlocation });
            this.main();
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <input type="hidden" name="q" id="edtQ" />
                        <div id="divView">
                            <div className="row">
                                <div className="col-md-4" id="divAll">
                                    <div className="card shadow-lg rounded">
                                        <div className="card-header">
                                            <h6>Data Barberman</h6>
                                        </div>
                                        <div className="card-body">
                                            <input type="search" name="q" id="edtQOperator" className="form-control form-control-sm" value={this.state.search} onChange={(e) => this.handleChange(e, "search")} placeholder="Cari Nama Operator" />
                                            <p></p>
                                            <div class="d-grid">
                                                <button class="btn btn-sm btn-default" id="btnPilihAll" disabled={this.state.pilihAll} onClick={() => this.handleClickBtn()}>Pilih Semua Barberman Cabang ini</button>
                                            </div>
                                            <p></p>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Nama Barberman</th>
                                                        <th>Pilih</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="tbodyOperator"></tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8" id="divLocation">
                                    <div className="card shadow-lg rounded">
                                        <div className="card-header">
                                            <h6>Data Barberman di lokasi <b id="bLocation"></b></h6>
                                        </div>
                                        <div className="card-body">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Nama Barberman</th>
                                                        <th>Pilih</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="tbodyOperatorLocation"></tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;