import React, { Fragment } from 'react';
import { rendTable, rendModal, api, submitForm, saiki, numberFormat } from "../modul";
import ReactDOM from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "List Voucher";
    document.title = "List Voucher";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "ID",
                by: "DESC",
                Status: "all"
            };
        }

        main() {
            rendTable({
                url: "voucher_trans_browse",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by, Status: this.state.Status },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: {
                    cls: "table table-striped"
                },
                thead: {
                    cls: "color-bg-biru"
                },
                tbody: {
                    id: "tbodyData"
                }
            });
        }

        componentDidMount() {
            this.main();
            document.getElementById('loading').style.display = "none";
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            setTimeout(() => {
                this.main();
            }, 599);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleFilter(e) {
            this.setState({ Status: e.target.value });
            setTimeout(() => {
                this.main();
            }, 500);
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <select className='form-select' value={this.state.Status} onChange={(e) => this.handleFilter(e)}>
                                    <option value="all">Semua Status</option>
                                    <option value="Sudah Diklaim">Sudah Diklaim</option>
                                    <option value="Belum Diklaim">Belum Diklaim</option>
                                </select>
                            </div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                        </div>
                    </div>
                    <nav id="paggination"></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

export default App;