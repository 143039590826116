import React, { Fragment } from 'react';
import { rendTable, rendModal, api, submitForm, loading, getCookie } from "../modul";
import ReactDOM from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "Formula Produk";
    document.title = "Formula Produk";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameProduct",
                by: "DESC"
            };
        }

        main() {
            rendTable({
                url: "productauto_browse_web",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi:
                        [
                            { type: "edit", event: (e) => rendForm(e) },
                            { type: "delete", event: (e) => this.modalDelete(e) }
                        ],
                    id: "tbodyData"
                }
            });
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "productauto_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Formula  Produk</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                        </div>
                    </div>
                    <nav id="paggination"></nav>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                idproduct: "",
                product: "",
                qty: "",
                detail: []
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("productauto_detail", { iddata: this.state.id });
                let detail = []
                for (let td of sql.detail) detail.push(td);
                this.setState({
                    idproduct: sql.data[0].IDProduct,
                    product: sql.data[0].NameProduct,
                    qty: sql.data[0].Qty,
                    detail: detail
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        modalProduk() {
            const dom =
                <>
                    <input type="search" class="form-control form-control-sm" onChange={(e) => this.dataProduk(e)} />
                    <p></p>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Kode</th>
                                <th>Nama</th>
                                <th>Qty</th>
                            </tr>
                        </thead>
                        <tbody id="tbodyProduk"></tbody>
                    </table>
                </>;
            rendModal({
                title: "Cari Barang",
                body: dom,
                id: "modalProduct"
            });
        }

        async dataProduk(e) {
            const sql = await api("product_browse_web", { search: e.target.value, index: 1, searchcount: 10, sort: "NameProduct", by: "ASC" });
            const dom = sql.data.map(opt =>
                <tr onClick={(e) => this.pilihProductDetail(opt.NameProduct, opt.ID)}>
                    <td>{opt.CodeProduct}</td>
                    <td>{opt.NameProduct}</td>
                    <td>{opt.CategoryName}</td>
                </tr>
            );

            ReactDOM.render(dom, document.getElementById('tbodyProduk'));
        }

        pilihProductDetail(name, id) {
            let old = this.state.detail;
            old.push({ NameProduct: name, Qty: 0, IDProduct: id });
            this.setState({ detail: old });
            document.getElementById('btnCloseModalmodalProduct').click();
        }

        deleteDetail(id) {
            const myjsonobj = this.state.detail;
            Object.keys(myjsonobj).forEach(function (key) {
                if (myjsonobj[key].ID == id) delete myjsonobj[key];
            });
            this.setState({ detail: myjsonobj });
        }

        rendJson(id, e) {
            const myjsonobj = this.state.detail;
            Object.keys(myjsonobj).forEach(function (key) {
                if (myjsonobj[key].ID == id) myjsonobj[key].Qty = e.target.value;
            });
            this.setState({ detail: myjsonobj });
            console.log(this.state.detail);
        }

        modalGetProduct() {
            let dom = (
                <>
                    <input type="search" className="form-control" onChange={(e) => this.scProduct(e)} />
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Nama</th>
                                <th>Kategori</th>
                            </tr>
                        </thead>
                        <tbody id="tbodyDataProduct"></tbody>
                    </table>
                </>
            );

            rendModal({
                title: "Cari Data Produk",
                body: dom,
                id: "modalCari"
            });
        }

        async scProduct(e) {
            this.setState({ namaproduct: e.target.value });
            const sql = await api("product_browse_web", { search: e.target.value, searchcount: 100, index: 1, sort: "NameProduct", by: "ASC" });
            const dom = sql.data.map((opt) =>
                <tr onClick={(e) => this.setProduct(opt.NameProduct, opt.ID)}>
                    <td>{opt.NameProduct}</td>
                    <td>{opt.NameCategory}</td>
                </tr>)
            ReactDOM.render(dom, document.getElementById('tbodyDataProduct'));
        }

        setProduct(nama, id) {
            this.setState({ product: nama, idproduct: id });
            document.getElementById('btnCloseModalmodalCari').click()
        }

        render() {
            return (
                <Fragment>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Product</label>
                                <input type="hidden" id="edtProductID" name="idproduct" value={this.state.idproduct} />
                                <div className="dropdown">
                                    <input type="text" name="product" className="form-control" id="edtProduct" value={this.state.product} onFocus={(e) => this.modalGetProduct(e)} autocomplete="off" required />
                                    <div className="invalid-feedback">Silahkan pilih Produk</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <button type="button" className="btn btn-sm btn-default" onClick={(e) => this.modalProduk(e)}>Tambah Produk Detail</button>
                            <p></p>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th width="60%">Nama</th>
                                        <th width="30%">Qty</th>
                                        <th width="10%"></th>
                                    </tr>
                                </thead>
                                <tbody id="tbodyDataProduk">
                                    {
                                        this.state.detail.map(td =>
                                            <tr>
                                                <td className="td-nama" data-id={td.ID}>{td.NameProduct}</td>
                                                <td><input type="number" className="form-control form-control-sm td-qty" value={td.Qty} onChange={(e) => this.rendJson(td.ID, e)} required /></td>
                                                <td><i className="material-icons icon-color" onClick={(e) => this.deleteDetail(td.ID)}>delete</i></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <textarea style={{ display: "none" }} id="edtJson" name="datadetail" value={JSON.stringify(this.state.detail)}></textarea>
                </Fragment>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "productauto_edit" : "productauto_insert",
        title = id ? "Edit Produk" : "Tambah Produk";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form",
        cls: "modal-lg"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;