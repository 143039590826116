import React, { Fragment } from 'react';
import { rendTable, rendModal, api, submitForm, loading, getCookie, numberFormat, saiki, tanggalIndo, pesan } from "../modul";
import ReactDOM from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "Customer Compliment";
    document.title = "Customer Compliment";
    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameCustomer",
                by: "ASC",
                ID: "",
                IDCustomer: "",
                NameCustomer: "",
                detailProduct: [],
                detailLokasi: []
            };
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 200);
        }

        modalDelete(ini) {
            let dom = (
                <Fragment>
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </Fragment>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "customer_compliment_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async handleForms(e) {
            let id = e.target.dataset.id;
            rendModal({
                id: "modalForm",
                body: <Forms id={id} />,
                title: id == "" ? "Tambah Compliment" : "Edit Compliment",
                form: true,
                cls: "modal-lg",
                footer: `<button class="btn btn-primary" type="submit"><i class="material-icons">save</i> Simpan</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: id == "" ? "customer_compliment_insert" : "customer_compliment_edit", reload: true, modal: "btnCloseModalmodalForm", form: "#iniForm" });
            });
        }

        main() {
            rendTable({
                url: "customer_compliment_browse",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi:
                        [
                            { type: "edit", event: (e) => this.handleForms(e) },
                            { type: "delete", event: (e) => this.modalDelete(e) }
                        ],
                    id: "tbodyData"
                }
            });
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" data-id="" onClick={(e) => this.handleForms(e)}>Tambah Data</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={() => this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination"></nav>
                </Fragment>
            )
        }
    }

    class Forms extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameCustomer",
                by: "ASC",
                ID: this.props.id,
                IDCustomer: "",
                NameCustomer: "",
                detailProduct: [],
                detailLokasi: [],
                tempLocation: []
            };
        }

        async componentDidMount() {
            let sql = await api("customer_compliment_detail", { iddata: this.state.ID });
            console.log(sql);
            if (sql.status == "true") {
                this.setState({
                    IDCustomer: sql.data.IDCustomer,
                    NameCustomer: sql.data.NameCustomer,
                    detailProduct: sql.detailProduct,
                    detailLokasi: sql.detailLokasi
                });
            }
        }

        modalCariPelanggan() {
            let dom = <Fragment>
                <input className='form-control' placeholder='Cari Nama Pelanggan' onChange={(e) => this.handleCariPelanggan(e.target.value)} />
                <br></br>
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Nama</th>
                                <th>Telp</th>
                                <th>Email</th>
                                <th>Alamat</th>
                            </tr>
                        </thead>
                        <tbody id='tbodyPelanggan'></tbody>
                    </table>
                </div>
            </Fragment>
            rendModal({
                id: "modalPelanggan",
                body: dom,
                title: "Cari Pelanggan",
            });

            this.handleCariPelanggan("");
        }

        async handleCariPelanggan(val) {
            let sql = await api("customer_browse", { search: val, index: 1, searchcount: 100, sort: "NameCustomer", by: "asc" });
            let tr = [];
            sql.data.map(td => tr.push(
                <tr onClick={(e) => this.pilihPelanggan(e, td.NameCustomer, td.ID)}>
                    <td>{td.NameCustomer}</td>
                    <td>{td.Telp}</td>
                    <td>{td.Email}</td>
                    <td>{td.Address}</td>
                </tr>
            ));
            ReactDOM.render(tr, document.getElementById('tbodyPelanggan'));
        }

        pilihPelanggan(e, NameCustomer, IDCustomer) {
            this.setState({ NameCustomer: NameCustomer, IDCustomer: IDCustomer });
            document.getElementById('btnCloseModalmodalPelanggan').click();
        }


        modalProduct() {
            const dom = (
                <Fragment>
                    <input type="search" className="form-control form-control-sm" onChange={(e) => this.cariProduct(e.target.value)} placecholder="Cari Nama Produk" />
                    <p></p>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Nama Produk</th>
                                    <th>Harga</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id="tbodyCariProduk"></tbody>
                        </table>
                    </div>
                </Fragment>
            );

            rendModal({
                title: "Cari Produk",
                cls: "modal-lg",
                id: "modalCari",
                body: dom
            });

            this.cariProduct("");
        }

        async cariProduct(val) {
            const sql = await api("product_browse",
                {
                    search: val,
                    index: 1,
                    searchcount: 1000
                }
            );
            const dom = sql.data.map(opt =>
                <tr style={{ cursor: "pointer" }} onClick={() => this.pilihProduct({ IDProduct: opt.ID, NameProduct: opt.NameProduct })}>
                    <td> {opt.NameProduct}</td>
                    <td>{numberFormat(opt.Price)}</td>
                    <td></td>
                </tr>
            )
            ReactDOM.render(dom, document.getElementById('tbodyCariProduk'));
        }

        pilihProduct(opt) {
            let arr = this.state.detailProduct;
            if (this.isJsonInArray(opt, arr) == false) {
                arr.push(opt);
                this.setState({ detailProduct: arr });
                document.getElementById('btnCloseModalmodalCari').click();
            } else {
                alert("Produk Sudah Dipilih");
            }
        }

        async modalLokasi(IDProduct) {
            let sql = await api("location_browse", { search: "", searchcount: 100, index: 1 });
            let detail = [];
            for (let dd of sql.data) detail.push({ IDProduct: IDProduct, IDLocation: dd.ID, NameLocation: dd.NameLocation });
            const dom = (
                <Fragment>
                    <div className="table-responsive">
                        <ul className="list-group" id='ulCabang'>
                            {
                                detail.map((item, i) => {
                                    return (
                                        <li className="list-group-item">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" id={"flexSwitchCheckDefault" + item.IDLocation} value={item.IDLocation} onChange={(e) => this.handlePilihLokasi(e, IDProduct, item.NameLocation)} />
                                                <label className="form-check-label" htmlFor={"flexSwitchCheckDefault" + item.IDLocation}>{item.NameLocation}</label>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </Fragment>
            );

            rendModal({
                title: "Data Lokasi",
                id: "Lokasi",
                body: dom,
                footer: "<button class='btn btn-default' id='btnPilihLokasi'>Pilih</button/>"
            });

            let ini = this;
            document.getElementById('btnPilihLokasi').addEventListener("click", function () {
                let temp = ini.state.detailLokasi;
                for (let dd of ini.state.tempLocation) {
                    if (ini.isJsonInArray(dd, temp) == false) temp.push(dd);
                }
                ini.setState({ detailLokasi: temp });
                document.getElementById('btnCloseModalLokasi').click();
            });
        }

        isJsonInArray(jsonObj, jsonArray) {
            for (let i = 0; i < jsonArray.length; i++) {
                if (JSON.stringify(jsonArray[i]) === JSON.stringify(jsonObj)) {
                    return true;
                }
            }
            return false;
        }

        hapusLocation(i) {
            let data = this.state.detailLokasi;
            delete data[i];
            this.setState({ detailLokasi: data });
        }

        hapusProduct(i, IDProduct) {
            let data = this.state.detailProduct;
            delete data[i];
            let lokasi = this.state.detailLokasi;
            let tmp = [];
            for (let dd of lokasi) {
                if (dd.IDProduct != IDProduct) tmp.push(dd);
            }
            this.setState({
                detailLokasi: tmp,
                detailProduct: data
            });
        }

        handlePilihLokasi(e, IDProduct, NameLocation) {
            let temp = this.state.tempLocation;
            if (e.target.checked == true) {
                temp.push({ IDProduct: IDProduct, IDLocation: e.target.value, NameLocation: NameLocation });
                this.setState({ tempLocation: temp });
            } else {
                let tmm = [];
                for (let dd of temp) {
                    if (dd.IDLocation != e.target.value) tmm.push(dd);
                }
                this.setState({ tempLocation: tmm });
            }
        }

        render() {
            return (
                <Fragment>
                    <div className='form-group'>
                        <label>Nama Pelanggan</label>
                        <input name="IDCustomer" type="hidden" value={this.state.IDCustomer} />
                        <input name="idadmin" type="hidden" value={getCookie("ID")} />
                        <div className='input-group'>
                            <input name="NameCustomer" type="text" value={this.state.NameCustomer} className="form-control" placeholder='Pilih Nama pelanggan' onFocus={() => this.modalCariPelanggan()} readOnly={true} />
                            <buttom className="btn btn-default material-icons" type="button" onClick={() => this.modalCariPelanggan()}>search</buttom>
                        </div>
                    </div>
                    <p></p>
                    <button className='btn btn-sm btn-default' type="button" onClick={() => this.modalProduct()}>Tambah Produk</button>
                    <p></p>
                    <div className='table-responsive' style={{ height: "60vh", overflowY: "scroll" }}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Produk</th>
                                    <th>Cabang</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.detailProduct.map((item, i) => {
                                        return (
                                            <Fragment>
                                                <tr>
                                                    <td>{item.NameProduct}</td>
                                                    <td>
                                                        {
                                                            this.state.detailLokasi.map((lok, ii) => {
                                                                if (item.IDProduct == lok.IDProduct) {
                                                                    return (
                                                                        <div className='btn btn-primary btn-sm' style={{ borderRadius: "20px", marginRight: "10px", marginBottom: "10px" }}>
                                                                            <div className='d-flex justify-content-between align-items-center'>
                                                                                <span>{lok.NameLocation}</span>
                                                                                <span className='material-icons' onClick={() => this.hapusLocation(ii)}>delete</span>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </td>
                                                    <td>
                                                        <i className='material-icons icon-color' type="button" onClick={() => this.modalLokasi(item.IDProduct)}>add</i>
                                                        <i className='material-icons icon-color' type="button" onClick={() => this.hapusProduct(i, item.IDProduct)}>delete</i>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <input type="hidden" value={JSON.stringify(this.state.detailLokasi)} name="data" />
                        <input type="hidden" value={this.state.ID} name="iddata" />
                        <input type="hidden" value={getCookie("ID")} name="UpdateBy" />
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

export default App;