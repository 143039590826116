import React from 'react';
import ReactDOM from 'react-dom';
import { rendTable, rendModal, api, submitForm, loading, getCookie } from "../modul";

function App() {
    document.getElementById("lblHeader").innerHTML = "Master Kursi";
    document.title = "Master Kursi";

    class Main extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "CodeCounter",
                by: "ASC",
                idlocation: "0"
            };
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "counter_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        main() {
            rendTable({
                url: "counter_browse_web",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by, idlocation: this.state.idlocation },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi:
                        [
                            { type: "edit", event: (e) => rendForm(e) },
                            { type: "delete", event: (e) => this.modalDelete(e) }
                        ],
                    id: "tbodyData"
                }
            });
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Kursi</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                        </div>
                    </div>
                    <nav id="paggination"></nav>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                name: "",
                code: "",
                lokasiname: "",
                lokasiid: ""
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("counter_detail", { iddata: this.state.id });
                this.setState({
                    name: sql.data[0].NameCounter,
                    code: sql.data[0].CodeCounter,
                    lokasiid: sql.data[0].IDLocation,
                    lokasiname: sql.data[0].NameLocation
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleFocus(e) {
            const dom = (
                <>
                    <input className="form-control" onChange={(e) => this.listCari(e)} />
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Kode</th>
                                <th>Nama</th>
                            </tr>
                        </thead>
                        <tbody id="listLokasi"></tbody>
                    </table>
                </>
            );
            rendModal({
                id: "modalLokasi",
                body: dom,
                title: "Cari Data Lokasi / Cabang",
            });
        }

        async listCari(val) {
            let data = new FormData();
            data.append("search", val);
            data.append("index", 1);
            data.append("searchcount", 10);
            let sql = await api("location_browse", { search: val.target.value, index: 1, searchcount: 10 });
            let dom = sql.data.map((opt) =>
                <tr><td style={{ cursor: "pointer" }} onClick={(e) => this.pilihLokasi(opt.NameLocation, opt.ID)}>{opt.NameLocation}</td></tr>
            );
            ReactDOM.render(dom, document.getElementById('listLokasi'));
        }

        pilihLokasi(nama, id) {
            document.getElementById('edtLokasiID').value = id;
            document.getElementById('edtLokasi').value = nama;
            document.getElementById('btnCloseModalmodalLokasi').click();
        }

        render() {
            return (
                <>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <div class="form-group">
                        <label>Kode Kursi</label>
                        <input type="text" name="code" class="form-control" id="edtCode" value={this.state.code} onChange={(e) => this.handleChange(e, "code")} required />
                        <div class="invalid-feedback">Silahkan Isi code kursi</div>
                    </div>
                    <div class="form-group">
                        <label>Nama Kursi</label>
                        <input type="text" name="name" class="form-control" id="edtName" value={this.state.name} onChange={(e) => this.handleChange(e, "name")} required />
                        <div class="invalid-feedback">Silahkan Isi nama kursi</div>
                    </div>
                    <div class="form-group">
                        <label>Lokasi Kursi</label>
                        <input type="hidden" name="idlocation" id="edtLokasiID" value={this.state.lokasiid} />
                        <input type="text" name="namelokasi" class="form-control" id="edtLokasi" value={this.state.lokasiname} onFocus={(e) => this.handleFocus(e)} required />
                        <div class="invalid-feedback">Silahkan Isi lokasi kursi</div>
                    </div>
                </>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "counter_edit" : "counter_insert",
        title = id ? "Edit Kursi" : "Tambah Kursi";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;